import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueI18n from 'vue-i18n'
import './assets/font/font.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'lib-flexible'


import axios from 'axios'

Vue.prototype.$axios = axios

//Vue.prototype.baseURL = 'https://guanwang2023.fsygroup.com:8002/'
Vue.prototype.baseURL = 'http://guanwang2023.fsygroup.com:8000/'

// axios.defaults.baseURL = '/api'

Vue.use(ElementUI);

Vue.use(vueI18n)
const locale=localStorage.getItem('lang')
const i18n=new vueI18n({
	locale:locale||'zh',
	messages:{
		'zh':require('@/assets/lang/zh'),
		'en':require('@/assets/lang/en'),
		'tra':require('@/assets/lang/traditional'),
	}
})

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)


// Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
