<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-22")}} > </span> <span class="top-menu-left-span2" > {{$t("head.head-23")}}</span> 
        </div>
      </div>
      <div class="securities-box">
          <p class="securities-title">{{$t("home.home-33")}}：<span style="color: #004593;font-weight: normal;">{{$t("home.home-34")}} 01448</span> </p>
          <div class="securities-tab">
            <p class="securities-tab-inner" :class="{'securitiesActive': tab == 1}" @click="tab = 1">{{$t("home.home-35")}}</p>
            <p class="securities-tab-inner" :class="{'securitiesActive': tab == 2}" @click="tab = 2">{{$t("home.home-36")}}</p>
            <p class="securities-tab-inner" :class="{'securitiesActive': tab == 3}" @click="tab = 3">{{$t("home.home-37")}}</p>
            <p class="securities-tab-inner" :class="{'securitiesActive': tab == 4}" @click="tab = 4">{{$t("home.home-38")}}</p>
          </div>
          <div class="securities-content">
            <div v-if="tab == 1">
              <img class="securities-img" src="http://image.sinajs.cn/newchart/hk_stock/min/01448.gif" alt="">
            </div>
            <div v-if="tab == 2">
              <img class="securities-img" src="http://image.sinajs.cn/newchart/hk_stock/daily/01448.gif" alt="">
            </div>
            <div v-if="tab == 3">
              <img class="securities-img" src="http://image.sinajs.cn/newchart/hk_stock/weekly/01448.gif" alt="">
            </div>
            <div v-if="tab == 4">
              <img class="securities-img" src="http://image.sinajs.cn/newchart/hk_stock/monthly/01448.gif" alt="">
            </div>
          </div>
      </div> 
    </div>
      <div class="grop-news1" v-if="terminal == 'Mobile'">
      <div class="mobile-securities-box">
          <p class="mobilesecurities-title">{{$t("home.home-33")}}：<span style="color: #004593;font-weight: normal;">{{$t("home.home-34")}} 01448</span> </p>
          <div class="mobile-securities-tab">
            <p class="mobile-securities-tab-inner" :class="{'securitiesActive': tab == 1}" @click="tab = 1">{{$t("home.home-35")}}</p>
            <p class="mobile-securities-tab-inner" :class="{'securitiesActive': tab == 2}" @click="tab = 2">{{$t("home.home-36")}}</p>
            <p class="mobile-securities-tab-inner" :class="{'securitiesActive': tab == 3}" @click="tab = 3">{{$t("home.home-37")}}</p>
            <p class="mobile-securities-tab-inner" :class="{'securitiesActive': tab == 4}" @click="tab = 4">{{$t("home.home-38")}}</p>
          </div>
          <div class="mobile-securities-content">
            <div v-if="tab == 1">
              <img class="mobile-securities-img" src="http://image.sinajs.cn/newchart/hk_stock/min/01448.gif" alt="">
            </div>
            <div v-if="tab == 2">
              <img class="mobile-securities-img" src="http://image.sinajs.cn/newchart/hk_stock/daily/01448.gif" alt="">
            </div>
            <div v-if="tab == 3">
              <img class="mobile-securities-img" src="http://image.sinajs.cn/newchart/hk_stock/weekly/01448.gif" alt="">
            </div>
            <div v-if="tab == 4">
              <img class="mobile-securities-img" src="http://image.sinajs.cn/newchart/hk_stock/monthly/01448.gif" alt="">
            </div>
          </div>
      </div> 
    </div>
       <div style="display:none">
       <img  src="http://image.sinajs.cn/newchart/hk_stock/daily/01448.gif" alt="">
       <img  src="http://image.sinajs.cn/newchart/hk_stock/weekly/01448.gif" alt="">
       <img  src="http://image.sinajs.cn/newchart/hk_stock/monthly/01448.gif" alt="">
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'noticePage',
  data(){
    return{
      type: 'list',
      list: [],
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      tab: 1,
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getList()
  },
  methods:{
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    getList(){
      let that = this
      let params = {
        page: this.currentPage,
        rows: 10,
        para: '|',
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_InvestorInquiries/GetPageDataV1",params).then(
        (res) => {
          that.list = res.data.rows
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 950px;
}
.grop-news1{
  min-height: 400px;
 
}
</style>
</style>