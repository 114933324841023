<template>
  <div>
    <Header></Header>
    <div v-if="terminal == 'PC'">
      <div class="news-banner">
        <p class="model-name">{{$t("home.home-18")}}</p>
      </div>
      <div class="news-detail" style="padding-top: 0" v-if="noData == false">
        <div v-for="(item,index) in list" :key="index" style="padding-bottom: 50px;">
          <p class="detail-title" >{{item.Positions}}</p>
          <p class="detail-desc" style="text-align: left;">{{$t("home.home-23")}}： {{ item.WorkLocation }} </p>
          <p class="detail-desc" style="text-align: left;">{{$t("home.home-24")}}：{{ item.YearlySalary }}</p>
          <div class="detail-content" v-html="item.DetailedInformation"></div>
        </div>
      </div>
      <div class="news-detail" style="padding-top: 0" v-if="noData">
        <img class="respecting-icon" src="../assets/img/respecting.png" alt="">
        <p class="respecting-p">{{$t("home.home-22")}}...</p>
      </div>
    </div>
    
    <div v-if="terminal == 'Mobile'">
      <div class="mobile-news-banner" v-if="terminal == 'Mobile'">
        <p class="mobile-model-name2" style="padding-top: 130px;">{{$t("home.home-18")}}</p>
      </div>
      <p class="mobile-go-back" @click="goBack">  《 {{$t("home.home-19")}}</p>
      <div class="mobile-news-detail" style="padding-top: 0" v-if="noData == false">
        <div v-for="(item,index) in list" :key="index" style="padding-bottom: 50px;">
          <p class="mobile-detail-title" >{{item.Positions}}</p>
          <p class="mobile-detail-desc" style="text-align: left;">{{$t("home.home-23")}}： {{ item.WorkLocation }} </p>
          <p class="mobile-detail-desc" style="text-align: left;">{{$t("home.home-24")}}：{{ item.YearlySalary }}</p>
          <div class="mobile-detail-content" v-html="item.DetailedInformation"></div>
        </div>
      </div>

      <div class="mobile-news-detail" style="padding-top: 0" v-if="noData">
        <img class="mobile-respecting-icon" src="../assets/img/respecting.png" alt="">
        <p class="mobile-respecting-p">{{$t("home.home-22")}}...</p>
      </div>
    </div>
    <Footer></Footer>
  </div>
  
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  },
  props:{
    // plateId:{
    //       type: Number,
    //       default:"这是默认值"
    //   }
  },
  data(){
    return{
      list: [],
      imgBaseUrl: '',
      noData: false,
      terminal: 'PC',
    }
  },
  mounted(){
    window.scrollTo(0,0);
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getInfo()
  },
  methods:{
    getInfo(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_EmploymentOpportunities/GetPageDataV1",{Language: localStorage.getItem('Language') || 1 }).then(
        (res) => {
          that.list = res.data.rows
          if(that.list.length > 0){
            that.noData = false
          }else{
            that.noData = true
          }
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

