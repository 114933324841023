<template>
  <div>
    <Header></Header>
    <div v-if="terminal == 'PC'">
      <div class="news-banner">
        <p class="model-name">{{$t("foot.foot-6")}}</p>
      </div>
      <div class="news-detail" style="padding-top: 0">
        <p class="detail-title" style="text-align: center;">{{detail.Title}}</p>
        <p class="detail-desc">{{ detail.Remarks }}</p>
        <div class="detail-content" v-html="detail.Content"></div>
      </div>
    </div>
    <div v-if="terminal == 'Mobile'">
      <div class="mobile-news-banner" v-if="terminal == 'Mobile'">
        <p class="mobile-model-name2" style="padding-top: 130px;">{{$t("foot.foot-6")}}</p>
      </div>
      <p class="mobile-go-back" @click="goBack">  《 {{$t("home.home-19")}}</p>
      <div class="mobile-news-detail" style="padding-top: 0">
        <p class="mobile-detail-title" style="text-align: center;">{{detail.Title}}</p>
        <p class="mobile-detail-desc">{{ detail.Remarks }}</p>
        <div class="mobile-detail-content" v-html="detail.Content"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  },
  props:{
  },
  data(){
    return{
      detail: {},
      imgBaseUrl: '',
      terminal: 'PC',
    }
  },
  mounted(){
    window.scrollTo(0,0);
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getInfo()
  },
  methods:{
    getInfo(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV2",{Language: localStorage.getItem('Language') || 1}).then(
        (res) => {
          console.log(res)
          that.detail = res.data.rows[0]
          console.log(that.detail)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

