<template>
  <div class="home">
    <Header></Header>
    <div class="news-banner">
      <p class="model-name">人文活动</p>
    </div>
    <div class="grop-news">
      <div class="top-menu" style="border-bottom: none">
        <div class="top-menu-left">
          <span class="top-menu-left-span1" @click="type = 'list'" style="color: rgba(51, 51, 51, 1);font-weight: bold;font-size: 14px;cursor: pointer;">人文活动</span> 
        </div>
        <div class="top-menu-right">
          <div class="year-select">
            <el-select v-model="year" :placeholder='$t("home.home-1")' @change="toSearch" clearable>
              <el-option
                v-for="item in yearList"
                :key="item.year"
                :label="item.year"
                :value="item.year"
                >
              </el-option>
            </el-select>
          </div>
          <div class="search-input">
            <el-input
              :placeholder='$t("home.home-2")'
              v-model="value" @keyup.enter.native="toSearch" clearable>
              <i slot="suffix" @click="toSearch" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="activity grop-news" v-if="type == 'list' && list1.length > 0"> 
      <div class="activity-swiper">
        <el-carousel indicator-position="outside" height="480px">
          <el-carousel-item v-for="(item,index) in list1" :key="index">
            <div class="swiper-inner" @click="toDetail(item)">
              <img class="swiper-img" :src="imgBaseUrl + item.ImgUrl" alt="">
              <div class="inner-bottom">
                <div class="bottom-left">
                  <p class="swiper-p1">{{item.Title}}</p>
                  <p class="swiper-p2">{{ item.briefIntroduction }}</p>
                </div>
                <p class="swiper-btn">立即查看</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="news-line" @click="toDetail(item)" v-for="item,index in list2" :key="index">
        <div class="news-line-left">
          <p class="date-title">活动时间</p>
          <p class="date1" v-if="item.StartTime && item.EndTime">{{ item.StartTime.substring(5,7) }}.{{ item.StartTime.substring(8,10) }}-{{ item.EndTime.substring(5,7) }}.{{ item.EndTime.substring(8,10) }}</p>
          <p class="date2" v-if="item.StartTime">{{item.StartTime.substring(0,4)}}</p>
        </div>
        <div class="news-line-center">
          <p class="news-title">
            <span class="news-tag" v-if="index < 2">NEW</span>
            {{item.Title}}
          </p>
          <p class="news-desc">{{ item.briefIntroduction }}</p>
          <p class="news-tips">
            <span class="news-from">{{item.NewsSources}}</span>
            <span>{{item.Readingvolume}}</span>
          </p>
        </div>
        <div class="news-line-right">
          <img class="news-img" :src="imgBaseUrl + item.ImgUrl" alt="">
        </div>
      </div>
    </div>
    <activityDetail v-if="type == 'detail'" :id="id"></activityDetail>
    <Footer></Footer>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import activityDetail from './activityDetail.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    activityDetail
  },
  
  data(){
    return{
      list1: [],
      list2: [],
      imgBaseUrl: '',
      type: 'list',
      yearList: [],
      year: '',
      value: ''
        
    }
  },
  mounted(){
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.yearList = JSON.parse(localStorage.getItem('yearList'))
    this.getList()
  },
  methods:{
    toSearch(){
      this.type = 'list'
      this.getList()
    },
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    getList(){
      let that = this
      let params = {
        page: this.currentPage,
        rows: 10,
        para: `${that.year}|${that.value}`,
        Language: localStorage.getItem('Language') || 1
      }
      that.list1 = []
      that.list2 = []
      that.$axios.post(this.baseURL + "api/PX_Activity/GetPageDataV3",params).then(
        (res) => {
          res.data.rows.forEach((item,index) => {
            if(index < 3){
              that.list1.push(item)
            }else{
              that.list2.push(item)
            }
          });
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>
<style lang="scss">
.news-banner{
  background: url('../../assets/img/news/news-bg1.png') no-repeat center center;
  background-size: 100% 100%;
}
.mobile-news-banner{
  background: url('../../assets/img/news/news-appbg1.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
</style>