<template>
  <div >
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-22")}} > </span> <span class="top-menu-left-span2" > {{$t("head.head-29")}}</span> 
        </div>
      </div>
      <div class="notice-box">
        <div class="detail-content" v-html="listDetail.Content"></div>
      </div> 
    </div>
    <div v-if="terminal == 'Mobile'">
      <div class="mobile-notice-box">
        <div class="mobile-detail-content" v-html="listDetail.Content"></div>
      </div> 
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'noticePage',
  data(){
    return{
      type: 'list',
      list: [],
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      listDetail: {},
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getList()
  },
  methods:{
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    getList(){
      let that = this
      let params = {
        page: this.currentPage,
        rows: 10,
        para: '|',
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_InvestorInquiries/GetPageDataV1",params).then(
        (res) => {
          that.listDetail = res.data.rows[0]
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
.notice-box{
   background: url('../../../assets/img/Maskgroup.png') no-repeat center center;
   height: 450px;
   background-size: 100% 100%;
   padding: 20px;
}
.mobile-notice-box{
   background: url('../../../assets/img/Maskgroup.png') no-repeat center center;
   height: 460px;
   background-size: 100% 100%;
   padding: 20px;
   margin-bottom: 20px;
}
</style>