module.exports = {
    head: {
        'head-1' : '首页',
        'head-2' : '集团概览',
        'head-3' : '集团简介',
        'head-4' : '文化理念',
        'head-5' : '品牌商标',
        'head-6' : '管理团队',
        'head-7' : '发展历程',
        'head-8' : '荣誉资质',
        'head-9' : '宣传视频',
        'head-10' : '新闻中心',
        'head-11' : '集团要闻',
        'head-12' : '各地动态',
        'head-13' : '媒体聚焦',
        'head-14' : '企业刊物阅读',
        'head-15' : '集团板块',
        'head-16' : '社会责任',
        'head-17' : '集团公益',
        'head-18' : '可持续发展报告',
        'head-19' : '业务合作',
        'head-20' : '合作模式',
        'head-21' : '联系我们',
        'head-22' : '投资者关系',
        'head-23' : '证券信息',
        'head-24' : '公司公告',
        'head-25' : '公司治理',
        'head-26' : '股东大会',
        'head-27' : '定期报告',
        'head-28' : '公司演示',
        'head-29' : '投资者查询',
        'head-30' : '简体',
        'head-31' : '繁体',
        'head-32' : '英文',
    },
    foot:{
        'foot-1' : '友情链接',
        'foot-2' : '集团官方咨询热线',
        'foot-3' : '沪ICP备05016662号-5',
        'foot-4' : '沪公网安备 31010402008532号',
        'foot-5' : '就业机会',
        'foot-6' : '法律声明',
    },
    home:{
        'home-1' : '年度',
        'home-2' : '请输入内容',
        'home-3' : '《福寿园》报',
        'home-4' : '《福寿人文》杂志',
        'home-5' : '返回集团要闻列表',
        'see-more' : '查看更多',
        'no-data' : '没有数据',
        'download' : '查看下载',
        'home-6' : '返回各地动态列表',
        'home-7' : '返回媒体聚焦列表',
        'home-8' : '返回集团公益列表',
        'home-9' : '请输入关键词查询',
        'home-10' : '搜索结果',
        'home-11' : '很抱歉，没有查询到相关结果，请换个关键词查询',
        'see-detail' : '查看详情',
        'home-12' : '共找到 关于',
        'home-13' : '的结果 共',
        'home-14' : '条',
        'home-15' : '发布者',
        'home-16' : '电话',
        'home-17' : '地址',
        'home-18' : '就业机会',
        'home-19' : '返回',
        'home-20' : '列表',
        'home-21' : '返回搜索列表',
        'home-22' : '敬请期待',
        'home-23' : '工作地点',
        'home-24' : '年薪',
        'home-25' : '城市布局',
        'home-26' : '实体运营',
        'home-27' : '公墓运营-产业地图',
        'home-28' : '殡仪服务-产业地图',  
        'home-29' : '截至香港时间',  
        'home-30' : '港交所上市企业',  
        'home-31' : '集团荣誉',  
        'home-32' : '集团资质',  
        'home-33' : '股票代码',  
        'home-34' : '香港联合交易所',  
        'home-35' : '分时K线',  
        'home-36' : '日K线图',  
        'home-37' : '周K线图',  
        'home-38' : '月K线图', 
        'home-39' : '年', 
        'home-40' : '寻找温情与力量', 
        'home-41' : '以人为本 值得托付', 
        'home-42' : '研发设计 自主生产', 
        'home-43' : '突破 创新 特色', 
        'home-44' : '让爱永久传承', 
        'home-45' : '领先的互联网', 
        'home-46' : '生命科学服务平台', 
        'home-47' : '孕育 激发 改变', 
        'home-48' : '福寿园官网-生命服务平台', 
        'home-49' : '董事委员会', 
        'home-50' : '感知生命 绽放人生', 

        'home-51' : '生前契约简介',  
        'home-52' : '“生前契约”是当事人生前就自己或亲人逝世后如何开展殡葬礼仪服务，而与殡葬服务公司订立的合约。它的概念源自英国的遗嘱信托，20世纪初引入美国，发展成为以消费者为导向的专业服务行业。二战后日本将美国成熟的“生前契约”制度导入日本并迅速普及，在欧美，生前契约已有60多年的历史，日本生前契约的普及率早已超过70%，在美国更高达90%以上。',  
        'home-53' : '其服务内容包括：临终关怀、遗体接送、灵堂搭建、告别仪式及法律咨询等相关后续服务。',  
        'home-54' : '2015年，福寿园国际集团正式将“生前契约”引入中国殡葬行业，将传统文化与现代服务理念相结合，旨在打造更加规范的行业准则及更完善的服务系统，引领中国现代殡葬行业的发展与变革。自服务推出以来，每年签订契约的人数都在以近倍的速度增长，上海、江苏、重庆、安徽、山东、江西、辽宁、河南、福建等地，越来越多的人开始接受并认同提前规划的概念，目前累计签订人数超万人。',  
        'home-55' : '生前契约特色',  
        'home-56' : '抵御通胀',  
        'home-57' : '安心省心',  
        'home-58' : '上市公司', 
        'home-59' : '品质保证', 
        'home-60' : '提前规划', 
        'home-61' : '满足个人意愿', 
        'home-62' : '个性化方案', 
        'home-63' : '服务贴心', 
        'home-64' : '价格透明', 
        'home-65' : '避免非理性消费', 
        'home-66' : '亲人朋友', 
        'home-67' : '减轻负担', 
        'home-68' : '生前契约包含哪些内容', 
        'home-69' : '24小时白事服务热线', 
        'home-70' : '任何时间，任何地点，拨打电话白事管家立刻来到身边', 

        'home-71' : '全程引导', 
        'home-72' : '白事管家全程引导治丧，安排接运，告知手续办理流程及材料。', 
        'home-73' : '灵堂布置', 
        'home-74' : '根据逝者要求，安排灵堂布置，引导家属守灵，提供哀伤抚慰支持。', 
        'home-75' : '告别仪式策划', 
        'home-76' : '安排告别仪式，引导亲属好友送别，全程专业服务。', 
        'home-77' : '法律咨询', 
        'home-78' : '提供遗产继承相关法律咨询服务', 
        'home-79' : '落葬服务', 
        'home-80' : '提供落葬指导服务', 

        'home-81' : '福寿康宁 生前契约', 
        'home-82' : '用自己的方式告别', 
        'home-83' : '这一刻的选择是你余生的从容',
        'home-84' : '以后',
        'home-85' : '敬请期待',
        'home-86' : '姓名',
        'home-87' : '年龄',
        'home-88' : '职位',
        'home-89' : '董事委任日期',
        'home-90' : '职务及职责',
        'home-91' : '公墓名称',
        'home-92' : '公司名称',
        
        'home-100' : '公墓运营',
        'home-101' : '设计规划',
        'home-102' : '致敬生命，赋予一个行业生命的温度',
        'home-103' : '效法自然，打造诗意盎然的栖居',
        'home-104' : '殡仪服务',
        'home-105' : '生前事业',
        'home-106' : '专属定制，让告别变得美丽',
        'home-107' : '福寿康宁，余生的从容',
        'home-108' : '设备制造',
        'home-109' : '殡葬互联网+',
        'home-110' : '创新环保，呵护未来绿色之舟',
        'home-111' : '云端互联，拓展生命服务新维度',
        'home-112' : '追思用品',
        'home-113' : '生命教育',
        'home-114' : '思念何所寄，福寿长相忆',
        'home-115' : '文化创智，用教育链接历史与未来',
        'home-116' : '独资开发项目',
        'home-117' : '成立以来，通过自建方式，利用自有资金不断拓展生命服务事业。',
        'home-118' : '港股',
        'home-119' : '股票实时',
        'home-120' : '当前',
        'home-121' : '最高',
        'home-122' : '最低',
        'home-123' : '今开',
        'home-124' : '昨收',

    }
}