import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import search from '../views/searchResult.vue'
import about from '../views/about/index.vue'
import news from '../views/news/index.vue'
import duty from '../views/duty/index.vue'
import activity from '../views/activity/index.vue'
import IR from '../views/IR/index.vue'
import introduction from '../views/introduction/index.vue'
import cooperation from '../views/cooperation/index.vue'
import plate from '../views/plate/index.vue'
import map from '../views/map/map.vue'
import map1 from '../views/map/map1.vue'
import mapDetail from '../views/map/mapDetail.vue'
import jobDetail from '../views/jobDetail.vue'
import lawDetail from '../views/lawDetail.vue'
import searchDetail from '../views/searchDetail.vue'
import photo from '../views/photo.vue'
import detail from '../views/detail.vue'
import empty from '../views/empty.vue'

import { bvi } from '@/assets/lang/zh'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/map',
    name: 'map',
    component: map
  },
  {
    path: '/map1',
    name: 'map1',
    component: map1
  },
  {
    path: '/mapDetail',
    name: 'mapDetail',
    component: mapDetail
  },
  {
    path: '/jobDetail',
    name: 'jobDetail',
    component: jobDetail
  },
  {
    path: '/lawDetail',
    name: 'lawDetail',
    component: lawDetail
  },
  {
    path: '/search',
    name: 'search',
    component: search
  },
  {
    path: '/photo',
    name: 'photo',
    component: photo
  },
  {
    path: '/articleDetail',
    name: 'searchDetail',
    component: searchDetail
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/duty',
    name: 'duty',
    component: duty
  },
  {
    path: '/activity',
    name: 'activity',
    component: activity
  },
  {
    path: '/IR',
    name: 'IR',
    component: IR
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: introduction
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: cooperation
  },
  {
    path: '/plate',
    name: 'plate',
    component: plate
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail
  },
  {
    path: '/empty',
    name: 'empty',
    component: empty
  },
  // {
  //   path: '/:catchAll(.*)',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '*',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path:'*',
    redirect:'/'
   }
]

const router = new VueRouter({
  routes
})


// // 全局导航守卫
// router.beforeEach((to, from, next) => {
//   console.log("to",to)
//   console.log("from",from)
//   if (to.name === from.name && to.query.menu !== from.query.menu) {
//     console.log(to.fullPath)
//       next({ name: 'empty', query: { toPath: to.fullPath } })
//   }else{
//       next()
//   }
// })
// // 中间过渡路由
// let toPath = this.$route.query.toPath
// if (toPath) {
//  this.$router.push({ path: this.toPath })
// }

export default router
