<template>
  <div class="news-detail">
   <p class="detail-title">{{detail.Title}}</p>
   <p class="detail-info">
    <span class="detail-time" v-if="detail.Releasetime">{{detail.Releasetime.substring(0,11)}}</span>
    <span class="detail-from">{{detail.NewsSources}}</span>
    <!-- <span class="detail-num"><img class="see-icon" src="../../../assets/img/see.png" alt="">{{detail.Readingvolume}}</span> -->
   </p>
   <div class="detail-content" v-html="detail.Content"></div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  props:{
      id:{
          type: Number,
          default:"这是默认值"
      }
  },
  data(){
    return{
      detail: {}
    }
  },
  mounted(){
    console.log(this.id)
    this.getInfo()
  },
  methods:{
    getInfo(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_MediaFocus/GetPageDataV2",{para: that.id }).then(
        (res) => {
          console.log(res)
          that.detail = res.data.rows.rows[0]
          console.log(that.detail)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

