<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-2")}} > </span> <span class="top-menu-left-span2" > {{$t("head.head-9")}}</span> 
        </div>
      </div>
      <div class="introduce-box">
          <div class="introduce-left" >
            <div class="introduce-img-box">
              <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
            </div>
            <div class="left-bottom">
              <p class="introduce-left-p1">01448.HK</p>
              <p class="introduce-left-p2">{{ codeNumber }}</p>
              <p class="introduce-left-p3">HKD</p>
              <p class="introduce-left-p4">{{$t("home.home-29")}}  {{ timeInfo }}</p>
              <p class="introduce-left-p4">{{$t("home.home-30")}}</p>
            </div>
          </div>
          <div class="introduce-right">
            <div class="video-box">
              <div class="video-inner" v-for="(item,index) in list" :key="index">
                <div class="video-play-box">
                  <img class="video-img"  :src="imgBaseUrl + item.Coverimage" alt="">
                  <div class="hover-box">
                    <img class="video-play" @click="videoPlay(item,index)" src="../../../assets/img/play1.png" alt="">
                  </div>
                </div>
                <div class="video-bottom">
                  <p class="video-title">{{ item.Title }}</p>
                  <img class="video-play1" @click="videoPlay(item,index)" src="../../../assets/img/play2.png" alt="">
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- 视频播放 -->
      <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
          width="1100px" 
          :before-close="handleClose">
          <video v-if="dialogVisible" class="video-style" style=" height: 700px;" :src="videoUrl" controls autoplay></video>
        </el-dialog>
    </div>

    <div  v-if="terminal == 'Mobile'" style="background: #F5F5F5;padding-top: 30px;">
      <div class="mobile-video-box">
        <div class="mobile-video-inner" v-for="(item,index) in list" :key="index">
          <div class="mobile-video-play-box">
            <img class="mobile-video-img"  :src="imgBaseUrl + item.Coverimage" alt="">
            <div class="mobile-hover-box">
              <img class="mobile-video-play" @click="videoPlay(item,index)" src="../../../assets/img/play1.png" alt="">
            </div>
          </div>
          <div class="mobile-video-bottom">
            <p class="mobile-video-title">{{ item.Title }}</p>
          </div>
        </div>
      </div>

      <div class="mobile-introduce-box" style="width: 345px;margin: 0 auto;">
        <div class="mobile-introduce-left" >
          <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
        </div>
        <div class="mobile-introduce-right">
          <p class="introduce-left-p1">01448.HK</p>
          <p class="introduce-left-p2">{{ codeNumber }}</p>
          <p class="introduce-left-p3">HKD</p>
          <p class="introduce-left-p4">{{$t("home.home-29")}}  {{ timeInfo }}</p>
          <p class="introduce-left-p4">{{$t("home.home-30")}}</p>
        </div>
      </div>
      <!-- 视频播放 -->
      <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
          width="80%"
          :before-close="handleClose">
          <div style="padding: 0 0 20px 0">
            <video v-if="dialogVisible" class="video-style" style="width: 90%;" :src="videoUrl" controls autoplay></video>
          </div>
        </el-dialog>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'noticePage',
  props:{
    codeNumber:{
      type: String,
      default: '0'
    },
    timeInfo: {
      type: String,
      default: '0'
    }
  },
  data(){
    return{
      type: 'list',
      list: [],
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      isPlay: false,
      fullscreen: false,
      videoUrl: '',
      dialogVisible: false,
      title:'',
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getList()
  },
  methods:{
    handleClose(){
      this.dialogVisible = false
    },
    videoPlay(item,index){
      this.videoUrl = this.imgBaseUrl + item.ImgUrl
      this.dialogVisible = true
      this.title=item.Title
      // this.isPlay = true
      // console.log(this.$refs.videoElement)
      // this.$refs.videoElement.play()
    },
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    getList(){
      let that = this
      let params = {
        page: this.currentPage,
        rows: 100,
        para: '',
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_PublicityVideo/GetPageDataV1",params).then(
        (res) => {
          that.list = res.data.rows
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
.video-style{
  width: 1100px;
}
.el-dialog__header
{
 padding: 15px !important;
}
</style>