<template>
  <div class="home">
    <Header></Header>
    <div class="news-banner" v-if="terminal == 'PC'">
      <p class="model-name">{{$t("head.head-22")}}</p>
      <div class="tab-box">
        <p class="tab-box-p" style="text-align: left;" :class="{'tabActive': tab == 1}" @click="tab = 1">{{$t("head.head-23")}}</p>
        <p class="tab-box-p" :class="{'tabActive': tab == 2}" @click="tab = 2">{{$t("head.head-24")}}</p>
        <p class="tab-box-p" :class="{'tabActive': tab == 3}" @click="tab = 3">{{$t("head.head-25")}}</p>
        <p class="tab-box-p" :class="{'tabActive': tab == 4}" @click="tab = 4">{{$t("head.head-26")}}</p>
        <p class="tab-box-p" :class="{'tabActive': tab == 5}" @click="tab = 5">{{$t("head.head-27")}}</p>
        <p class="tab-box-p" :class="{'tabActive': tab == 6}" @click="tab = 6">{{$t("head.head-28")}}</p>
        <p class="tab-box-p" style="border-right: none" :class="{'tabActive': tab == 7}" @click="tab = 7">{{$t("head.head-29")}}</p>
      </div>
    </div>
    <div class="mobile-news-banner" v-if="terminal == 'Mobile'">
      <p class="mobile-model-name">{{$t("head.head-22")}}</p>
      <p class="mobile-model-name2" v-if="tab == 1">{{$t("head.head-23")}}</p>
      <p class="mobile-model-name2" v-if="tab == 2">{{$t("head.head-24")}}</p>
      <p class="mobile-model-name2" v-if="tab == 3">{{$t("head.head-25")}}</p>
      <p class="mobile-model-name2" v-if="tab == 4">{{$t("head.head-26")}}</p>
      <p class="mobile-model-name2" v-if="tab == 5">{{$t("head.head-27")}}</p>
      <p class="mobile-model-name2" v-if="tab == 6">{{$t("head.head-28")}}</p>
      <p class="mobile-model-name2" v-if="tab == 7">{{$t("head.head-29")}}</p>
    </div>
    <!-- 证券信息 -->
    <securities v-if="tab == 1"></securities>
    <!-- 公司公告 -->
    <noticePage v-if="tab == 2"></noticePage>
    <!-- 公司治理 -->
    <governance v-if="tab == 3"></governance>
    <!-- 股东大会 -->
    <meeting v-if="tab == 4"></meeting>
    <!-- 定期报告 -->
    <report v-if="tab == 5"></report>
    <!-- 业绩发布 -->
    <publish v-if="tab == 6"></publish>
    <!-- 投资者查询 -->
    <Investor v-if="tab == 7"></Investor>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import noticePage from './components/noticePage.vue'
import governance from './components/governance.vue'
import meeting from './components/meeting.vue'
import report from './components/report.vue'
import publish from './components/publish.vue'
import Investor from './components/Investor.vue'
import securities from './components/securities.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    securities,
    noticePage,
    governance,
    meeting,
    report,
    publish,
    Investor
  },
  
  data(){
    return{
      list: [],
      notice: '',
      internationalTab: 1,
      ktab: 1,
      customerNum: 0,
      Dialog: false,
      tabBag: 'tabBag1',
      status:false,
      menu: 1,
      tab: 1,
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.menu = this.$route.query.menu
    this.tab = this.menu
  },
  methods:{
    
  }
}
</script>
<style lang="scss">
.news-banner{
  
  background: url('../../assets/img/news/news-bg1.png') no-repeat center center;
  background-size: 100% 100%;
}
.mobile-news-banner{
  background: url('../../assets/img/news/news-appbg1.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>

