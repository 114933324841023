<template>
  <div id="app">
    <!-- <Header></Header> -->
    <!-- <div style="width: 100%;height: 100px"></div> -->
    <div class="to-top" v-if="terminal == 'Mobile'"  @click="toTop">
      <img class="to-top-img" src="../src/assets/img/mobile/top.png" alt="">
    </div>
    <div class="to-back" v-if="terminal == 'Mobile'"  @click="goBack">
      <img class="to-back-img" src="../src/assets/img/mobile/back.png" alt="">
    </div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<style lang="scss">
@import './assets/css/style.scss';
@import './assets/css/style1.scss';
#app {
  // font-family: '阿里巴巴惠普体标准';
}
</style>
<script>
// import Header from '@/components/Header.vue'
export default {
  name: "App",
  // components: {
  //   Header
  // },
  data () {
    return {
      terminal: 'PC'
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    if(!localStorage.getItem('lang')){
      localStorage.setItem('lang','zh')
    }
    // if (this._isMobile()) {
    //   localStorage.setItem("terminal",'Mobile')
    // } else {
    //   localStorage.setItem("terminal",'PC')
    // }
    this.getUrl()
    this.getYear()
  },
  methods: {
    reload () {
      this.showRouterView = false
      this.$nextTick(() => (this.showRouterView = true))
    },
    toTop(){
      window.scrollTo(0,0);
    },
    goBack(){
      this.$router.go(-1)
      
      // this.showRouterView = false
      // this.$nextTick(() => {
      //   this.showRouterView = true
      //   this.$router.go(-1)
      // })
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    getUrl(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_ContentConfiguration/GetPageDataV5",{}).then(
        (res) => {
          localStorage.setItem("imgBaseUrl",res.data.rows[0].JumpLink + '/')
        },
        (error) => {
          console.log("请求失败了", error.message);
        }
      );
    },
    getYear(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_ContentConfiguration/GetYearList",{}).then(
        (res) => {
          localStorage.setItem("yearList",JSON.stringify(res.data.rows))
        },
        (error) => {
          console.log("请求失败了", error.message);
        }
      );
    }
  }
}
</script>
<style>
  .to-top{
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 100px;
    right: 10px;
    cursor: pointer;
    z-index: 1000000000;
  }
  .to-top-img{
    width: 32px;
    height: 32px;
  }
  .to-back{
    width: 43px;
    height: 43px;
    position: fixed;
    bottom: 140px;
    right: 8px;
    cursor: pointer;
    z-index: 1000000000;
  }
  .to-back-img{
    width: 32px;
    height: 32px;
  }
</style>

