<template>
  <div class="photopage"   >
    <div class="down-photo" @click="toDownLoad" v-if="terminal == 'PC'">
      <img class="down-photo-img" src="../assets/img/downLoad1.png" alt="">
    </div>
     <div class="down-photo1" @click="share_weibo" v-if="terminal == 'PC'">
      <img class="down-photo-img" src="../assets/img/weibo.png" alt="" title="">
    </div>
     <div class="down-photo2" @mouseover="mouseovercode" @mouseout="mouseoutcode" v-if="terminal == 'PC'">
      <img class="down-photo-img" src="../assets/img/shouji.png" alt="">
    </div>
     <div class="down-photo3" @click="quanping" v-if="terminal == 'PC'">
      <img class="down-photo-img" src="../assets/img/quanping.png" alt="" title="">
    </div>
    <!-- <iframe src="/static/photo/photoPage.html" class="photobox"></iframe> -->
      <iframe src="" class="photobox" id="photoNew"></iframe>

  <div id="qrcode" ref="qrcode" class="divqrcode" style="display:none"></div>

  </div>

  
</template>

<script>
// import QRcode from 'qrcodejs2';
export default {
  name: 'HomeView',
  data(){
    return{
      PdfUrl: '',
      title:'',
      terminal: '',
      isFull:false,
    }
  },
  mounted(){
    
    let url = window.location.href;
    // 获取参数部分
    let type = url.split('=')[1].split('&')[0];
    let id = url.split('&')[1].split('=')[1];
    let that = this
     if(type==1){
      that.$axios.post(this.baseURL + "api/PX_FuShouBao/GetPageDataV2",{para:id}).then(
        (res) => {
          let photo = []
          that.PdfUrl=this.baseURL+res.data.rows[0].PdfUrl;
          that.title=res.data.rows[0].Title;
          for(var z=0;z<res.data.rows[0].PageNumber;z++)
          {
              photo.push(that.baseURL + 'Upload/Tables/Book/'+id+'/'+(z+1)+".png");
          }
          localStorage.setItem('photoList',JSON.stringify(photo));
           localStorage.setItem('phototype',"1");

          document.getElementById("photoNew").src='/static/photoNew/index.html';
        },
        (error) => {
          console.log("请求失败了", error.message);
      }); 
   }else{

     that.$axios.post(this.baseURL + "api/PX_FuShouHumanity/GetPageDataV2",{para:id}).then(
        (res) => {
          let photo = []
          that.PdfUrl=this.baseURL+res.data.rows[0].PdfUrl;
          for(var z=0;z<res.data.rows[0].PageNumber;z++)
          {
              photo.push(that.baseURL + 'Upload/Tables/BookH/'+id+'/'+(z+1)+".png");
          }
          localStorage.setItem('photoList',JSON.stringify(photo));
          localStorage.setItem('phototype',"2");

          document.getElementById("photoNew").src='/static/photoNew/index.html';
        },
        (error) => {
          console.log("请求失败了", error.message);
      });

   }

     let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|huawei|isHonor|Windows Phone)/i)
        
      if (flag) {
       
      } else {
        this.terminal='PC';
      }
       new QRCode(document.getElementById("qrcode"), window.location.href);

  },
  
  methods:{
    toDownLoad(){
      window.open(this.PdfUrl,'_blank')
    },
    // 分享到新浪微博
    share_weibo () {
    // shareUrl是微博的分享地址，（有资料说需要真实的appkey，必选参数，这里我没用appkey也可以正常分享）
      var _shareUrl = 'http://service.weibo.com/share/share.php?' 
      _shareUrl += 'url=' + encodeURIComponent(window.location.href) //参数url设置分享的内容链接
      _shareUrl += '&title=' + encodeURIComponent(this.title) //参数title设置分享的标题
      // 保留当前页面,打开一个非tab页面（按需求来，可以新开标签页，也可以在当前页新开页面）
      window.open(
        _shareUrl,
        '_blank',
        'height=100, width=400',
        'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes'
      )
    },
    mouseovercode() {
       document.getElementById("qrcode").style.display="";
    },
    mouseoutcode() {
          document.getElementById("qrcode").style.display="none";
    },
     requestFullScreen () {
      let de = document.documentElement
      if (de.requestFullscreen) {
        de.requestFullscreen()
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen()
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen()
      }
    },
    exitFullscreen () {
      let de = document
      if (de.exitFullscreen) {
        de.exitFullscreen()
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen()
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen()
      }
    },
    quanping () {
      this.isFull = !this.isFull
      if (this.isFull) {
        this.requestFullScreen()
      } else {
        this.exitFullscreen()
      }
    }
  }
}
</script>
