<template>
  <div class="home">
    <Header></Header>
    <div class="news-banner" v-if="terminal == 'PC'">
      <p class="model-name">{{$t("head.head-19")}}</p>
      <div class="tab-box">
        <p class="tab-box-p" style="text-align: left;" :class="{'tabActive': tab == 1}" @click="tab = 1">{{$t("head.head-20")}}</p>
        <p class="tab-box-p" style="border-right: none" :class="{'tabActive': tab == 2}" @click="tab = 2">{{$t("head.head-21")}}</p>
      </div>
    </div>
    <div class="mobile-news-banner" v-if="terminal == 'Mobile'">
      <p class="mobile-model-name">{{$t("head.head-19")}}</p>
      <p class="mobile-model-name2" v-if="tab == 1">{{$t("head.head-20")}}</p>
      <p class="mobile-model-name2" v-if="tab == 2">{{$t("head.head-21")}}</p>
    </div>
    <!-- 政府间合作 -->
    <government v-if="tab == 1"></government>
    <!-- 联系我们 -->
    <contact v-if="tab == 2"></contact>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import government from './components/government.vue'
import contact from './components/contact.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    government,
    // company,
    contact
  },
  
  data(){
    return{
      list: [],
      notice: '',
      internationalTab: 1,
      ktab: 1,
      customerNum: 0,
      Dialog: false,
      tabBag: 'tabBag1',
      status:false,
      menu: 1,
      tab: 1,
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.menu = this.$route.query.menu
    this.tab = this.menu
  },
  methods:{
  }
}
</script>
<style lang="scss">
.news-banner{
  background: url('../../assets/img/news/news-bg1.png') no-repeat center center;
  background-size: 100% 100%;
  background-position:0 50px!important;
  
}
.mobile-news-banner{
  background: url('../../assets/img/news/news-appbg1.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>

