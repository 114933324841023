<template>
  <div class="home">
    <Header></Header>
    <div class="news-banner" v-if="terminal == 'PC'">
      <p class="model-name">{{$t("head.head-16")}}</p>
      <div class="tab-box">
        <p class="tab-box-p" style="text-align: left;" :class="{'tabActive': tab == 1}" @click="changeTab(1)">{{$t("head.head-17")}}</p>
        <p class="tab-box-p" style="border-right: none;width: 150px;" :class="{'tabActive': tab == 2}" @click="changeTab(2)">{{$t("head.head-18")}}</p>
      </div>
    </div>
    <div class="mobile-news-banner" v-if="terminal == 'Mobile'">
      <p class="mobile-model-name">{{$t("head.head-16")}}</p>
      <p class="mobile-model-name2" v-if="tab == 1">{{$t("head.head-17")}}</p>
      <p class="mobile-model-name2" v-if="tab == 2">{{$t("head.head-18")}}</p>
    </div>
    <!-- 公益合作 -->
    <cooperation v-if="tab == 1"></cooperation>
    <!-- 社会责任特刊 -->
    <periodical v-if="tab == 2"></periodical>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import cooperation from './components/cooperation.vue'
import periodical from './components/periodical.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    cooperation,
    periodical
  },
  
  data(){
    return{
      list: [],
      notice: '',
      internationalTab: 1,
      ktab: 1,
      customerNum: 0,
      Dialog: false,
      tabBag: 'tabBag1',
      status:false,
      menu: 1,
      tab: 1,
      terminal: 'PC',
         indextz:0,
        
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.menu = this.$route.query.menu
    this.tab = this.menu
     this.indextz=this.menu
  },
  methods:{
    changeTab(index){
      this.$router.push({name: 'duty',query:{ menu: index }})
      if(index == 1){
        if(this.indextz==index){
          //location.reload();
         }else{
         this.indextz=index;
        }
      }
      //location.reload();
    }
  }
}
</script>
<style lang="scss">
.news-banner{
  background: url('../../assets/img/news/news-bg1.png') no-repeat center center;
  background-size: 100% 100%;
}
.mobile-news-banner{
  background: url('../../assets/img/news/news-appbg1.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>

