<template>
  <div>
    <div class="news-detail" style="padding-top: 0" v-if="terminal == 'PC'">
      <!-- <p class="detail-title" style="text-align: center;">{{detail.Title}}</p> -->
      <div class="detail-content" style="margin-top: 0px"  v-html="detail.Content"></div>
    </div>
    <div class="mobile-news-detail" v-if="terminal == 'Mobile'">
      <!-- <p class="mobile-detail-title" style="text-align: center;">{{detail.Title}}</p> -->
      <div class="mobile-detail-content" style="margin-top: 20px" v-html="detail.Content"></div>
    </div>
  </div>
  
</template>

<script>

export default {
  name: 'HomeView',
  props:{
    plateId:{
          type: Number,
          default:"这是默认值"
      }
  },
  data(){
    return{
      detail: {},
      terminal: 'PC'
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.getInfo()
  },
  methods:{
    getInfo(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_GroupSegment/GetPageDataV2",{para: that.plateId }).then(
        (res) => {
          console.log(res)
          that.detail = res.data.rows[0]
          console.log(that.detail)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

