<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-22")}} > </span> <span class="top-menu-left-span2" > {{$t("head.head-28")}}</span> 
        </div>
        <div class="top-menu-right" v-if="dialog">
          <div class="year-select">
            <el-select v-model="year" :placeholder='$t("home.home-1")' @change="toSearch" clearable>
              <el-option
                v-for="item in yearList"
                :key="item.year"
                :label="item.year"
                :value="item.year"
                >
              </el-option>
            </el-select>
          </div>
          <div class="search-input">
            <el-input
            :placeholder='$t("home.home-2")'
                v-model="value" @keyup.enter.native="toSearch" clearable @clear="toSearch">
              <i slot="suffix" @click="toSearch" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
      </div>
      <div v-if="type == 'list'">
          <div v-if="!dialog">
        <div class="journal-content">
          <div class="journal-content-icon-box">
            <img @click="switchBtnPre" class="journal-content-icon" src="../../../assets/img/left.png" alt="">
          </div>
          <div class="journal-content-swiper">
            <el-carousel ref="carouselRef" :interval="3000" indicator-position="outside" arrow="never" height="236px" :autoplay="false" >
              <el-carousel-item v-for="(item,index) in imgList" :key="index" >
                <div class="journal-content-inner">
                  <div class="journal-content-box"  style="height:236px" v-for="(innerItem,innerIndex) in item" :key="innerIndex">
                    <img class="journal-content-img" style="height:236px" @click="toDownload(innerItem)"  :src="imgBaseUrl + innerItem.ImgUrl" alt="">
                  </div>
                  <div class="journal-content-box" style="height:236px" @click="dialog = true" v-if="index == 1 && list.length >= 5">
                    <img class="journal-content-img" style="height:236px" src="../../../assets/img/more-jounery.png" alt="">
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>  
          </div>
          <div class="journal-content-icon-box">
            <img @click="switchBtnNext" class="journal-content-icon" src="../../../assets/img/right.png" alt="">
          </div>
        </div>
      </div>
      <div v-if="dialog" >
          <div class="journal-box">
            <div class="journal-inner" @click="toDownload(item)" v-for="(item,index) in list" :key="index">
              <img class="journal-img" :src="imgBaseUrl + item.ImgUrl" alt="">
              <p class="journal-p" style="height:40px;line-height:20px">{{ item.Title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="mobile-news" v-if="terminal == 'Mobile'" >
       <div v-if="!dialog">
        <div class="journal-content" style="width: 100%">
          <div class="journal-content-swiper" style="width: 100vw;">
            <el-carousel ref="carouselRef" :interval="3000" indicator-position="none" arrow="never" height="120px" :autoplay="false" >
              <el-carousel-item v-for="(item,index) in imgList" :key="index" >
                <div class="journal-content-inner" style="width: 100vw;">
                  <div class="journal-content-box" style="width: 32.3%;margin-right: 1%;border:none" v-for="(innerItem,innerIndex) in item" :key="innerIndex">
                    <img class="journal-content-imgM" style="width: 100%;" @click="toDownload(innerItem)"  :src="imgBaseUrl + innerItem.ImgUrl" alt="">
                  </div>
                  <div class="journal-content-box" style="width: 32.3%;margin-right: 1%;border:none;" @click=" dialog = true" v-if="index == 1 && list.length >= 5">
                    <img class="journal-content-imgM" style="width: 100%;height:83px" src="../../../assets/img/more-jounery.png" alt="">
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>  
          </div>
        </div>
        <div class="change-box">
          <div class="journal-content-icon-box">
            <img style="width: 30px;height: 30px;" @click="switchBtnPre" class="journal-content-icon" src="../../../assets/img/left.png" alt="">
          </div>
          <div class="journal-content-icon-box">
            <img style="width: 30px;height: 30px;" @click="switchBtnNext" class="journal-content-icon" src="../../../assets/img/right.png" alt="">
          </div>
        </div>
      </div>
      <div v-if="dialog" >
          <div class="mobile-journal-box">
            <p class="mobile-go-back" style="margin-top: 10px;margin-bottom: 10px;" @click="Goback()">  《 {{$t("home.home-19")}}</p>
            <div class="mobile-journal-inner" @click="toDownload(item)" v-for="(item,index) in list" :key="index">
              <img class="mobile-journal-img" :src="imgBaseUrl + item.ImgUrl" alt="">
              <p class="mobile-journal-p" style="height:40px;line-height:20px">{{ item.Title }}</p>
            </div>
          </div>
        </div>
        
      </div>

     
  </div>
  
</template>

<script>
export default {
  name: 'HomeView',
  data(){
    return{
      type: 'list',
      list: [],
      typeList: [],
      tabType: 0,
      currentType: '',
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      yearList: [],
      year: '',
      value: '',
      isMore: false,
      noData:false,
      terminal: 'PC',
      dialog: false,
      imgList: [],
      list1: [],
      currentIndex: 0,      
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.yearList = JSON.parse(localStorage.getItem('yearList'))
    this.getList()
  },
  methods:{
    Goback(){
      this.dialog = false;
      this.switchBtnPre();
    },
  switchBtnPre(){
      if(this.currentIndex == 0){
        // this.$message({
        //   showClose: true,
        //   type: 'warning',
        //   message: '已是第一页'
        // });
        this.currentIndex = this.imgList.length - 1
        this.$refs.carouselRef.setActiveItem(this.currentIndex)
      }else{
        this.currentIndex--
        this.$refs.carouselRef.setActiveItem(this.currentIndex)
      }
    },
    switchBtnNext(){
      if(this.currentIndex == this.imgList.length - 1){
        this.currentIndex = 0
        this.$refs.carouselRef.setActiveItem(this.currentIndex)
      }else{
        this.currentIndex++
        this.$refs.carouselRef.setActiveItem(this.currentIndex)
      }
    },
    toSearch(){
      this.type = 'list'
      this.list=[]
      this.currentPage = 1
      this.isMore = false
      this.noData=false;
      this.getList()
    },
    toDownload(item){
      let url = this.imgBaseUrl + item.Content
      window.open(url,'_blank')
    },
    getList(){
      let that = this
      let params = {
        page: this.currentPage,
        rows: 200,
        para: `${that.year}|${that.value}`,
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_PerformanceRelease/GetPageDataV1",params).then(
        (res) => {
          that.list = res.data.rows
          let list1 = [...that.list]
          if(list1.length > 5){
            list1 = list1.splice(0,5)
          }
          for(let i = 0, len = list1.length ; i < len; i += 3) {
            that.imgList.push(list1.slice(i, i + 3))
          }
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 400px;
 
}
</style>