<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-2")}}> </span> <span class="top-menu-left-span2" > {{$t("head.head-5")}}</span> 
        </div>
      </div>
      <div class="introduce-box">
          <div class="introduce-left">
            <div class="introduce-img-box">
              <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
            </div>
            <div class="left-bottom">
              <p class="introduce-left-p1">01448.HK</p>
              <p class="introduce-left-p2">{{ codeNumber }}</p>
              <p class="introduce-left-p3">HKD</p>
              <p class="introduce-left-p4">{{$t("home.home-29")}} {{ timeInfo }}</p>
              <p class="introduce-left-p4">{{$t("home.home-30")}}</p>
            </div>
          </div>
          <div class="introduce-right" v-if="isshow==true">
            <img class="brand-img" :src="imgBaseUrl + detail.ImgUrl" alt="">
          </div>
      </div>
    </div>

    <div class="mobile-news-detail" v-if="terminal == 'Mobile'">
      <img class="mobile-brand-img" :src="imgBaseUrl + detail.ImgUrl" alt="">
      <div class="mobile-introduce-box">
        <div class="mobile-introduce-left" >
          <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
        </div>
        <div class="mobile-introduce-right">
          <p class="introduce-left-p1">01448.HK</p>
          <p class="introduce-left-p2">{{ codeNumber }}</p>
          <p class="introduce-left-p3">HKD</p>
          <p class="introduce-left-p4">{{$t("home.home-29")}}  {{ timeInfo }}</p>
          <p class="introduce-left-p4">{{$t("home.home-30")}} </p>
        </div>
      </div>
    </div>

  </div>
 
</template>

<script>
export default {
  name: 'noticePage',
  props:{
    codeNumber:{
      type: String,
      default: '0'
    },
    timeInfo: {
      type: String,
      default: '0'
    }
  },
  data(){
    return{
      type: 'list',
      list: [],
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      detail: {},
      isshow:false,
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getList()
  },
  methods:{
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    getList(){
      let that = this
      let params = {
        page: this.currentPage,
        rows: 10,
        para: '',
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_EnterpriseTrademark/GetPageDataV1",params).then(
        (res) => {
          if(res.data.rows.length>0){
           that.detail = res.data.rows[0]
           that.isshow=true;
          }
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
</style>