<template>
  <div class="home">
    <Header></Header>
    <div class="news-banner" v-if="terminal == 'PC'">
      <p class="model-name">{{$t("head.head-15")}}</p>
      <div class="tab-box">
        <p class="tab-box-p" v-for="(item,index) in list" :key="index" :class="{'tabActive': tab == index}" @click="tab = index;type='list'">
          <span >{{ item.Title }}</span> 
        </p>
      </div>
    </div>

    <div class="mobile-news-banner" v-if="terminal == 'Mobile'">
      <p class="mobile-model-name">{{$t("head.head-15")}}</p>
      <div v-for="(item,index) in list" :key="index">
        <p class="mobile-model-name2"  v-if="tab == index">{{ item.Title }}</p>
      </div>
    </div>

    <div class="grop-news" v-if="terminal == 'PC'">
        <div class="top-menu" style="border-bottom:0px">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-15")}} ></span> 
          <span class="top-menu-left-span2" v-if="type == 'list' && list.length > 0" @click="type = 'list'">{{list[tab].Title}}</span> 
          <span class="top-menu-left-span2" v-if="type != 'list' && list.length > 0" @click="type = 'list'">{{$t("home.home-19")}}{{list[tab].Title}}{{$t("home.home-20")}}</span>
        </div>
      </div>
      <div class="plate-content" v-if="type == 'list'">
        <div v-for="(item,index) in list" :key="index" >
          <div class="plate-inner" v-if="item.Remarks != '600' && tab == index">
            <div class="plate-left" :class="isBottombg(item)">
              <p class="plate-p1">{{ item.Title }}</p>
              <p class="plate-p2">{{ item.briefIntroduction }}</p>
              <p class="plate-p3" @click="toDetail(item)" >{{$t("home.see-detail")}}</p>
            </div>
            <div class="plate-right-img" >
              <img class="plate-right-img-inner" :src="imgBaseUrl + item.ImgUrl" >
            <!--   <div class="plate-right-p" v-if="item.Remarks == '10' || item.Remarks == '20' || item.Remarks == '30' || item.Remarks == '40'">
                <p class="plate-right-p1">{{ item.Title }}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='10'">{{$t("home.home-40")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='20'">{{$t("home.home-41")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='30'">{{$t("home.home-42")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='50'">{{$t("home.home-43")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='40'">{{$t("home.home-44")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='70'">{{$t("home.home-45")}}<br />{{$t("home.home-46")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='80'">{{$t("home.home-47")}}</p>
              </div>
              <div class="plate-right-p-box" v-if="item.Remarks == '50' || item.Remarks == '70' || item.Remarks == '80'">
                <p class="plate-right-p1">{{ item.Title }}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='10'">{{$t("home.home-40")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='20'">{{$t("home.home-41")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='30'">{{$t("home.home-42")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='50'">{{$t("home.home-43")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='40'">{{$t("home.home-44")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='70'">{{$t("home.home-45")}}<br />{{$t("home.home-46")}}</p>
                <p class="plate-right-p2" v-if="item.Remarks =='80'">{{$t("home.home-47")}}</p>
              </div> -->
            </div>
            
          </div>
          <div v-if="item.Remarks == '600' && tab == index">
            <div class="cause-box" style="height:334px">
              <img @click="toDetail1(item)" v-for="(item,index) in causeList" :key="index" class="cause-img" :src="imgBaseUrl + item.ImgUrl" alt="">
            </div>
          </div>
        </div>
        <div class="plate-arrow">
          <img class="plate-arrow-icon" v-if="tab == 0" src="../../assets/img/plate-arrow1.png" alt="">
          <img class="plate-arrow-icon" v-if="tab != 0" @click="tab --" src="../../assets/img/plate-arrow2.png" alt="">
          <img class="plate-arrow-icon" v-if="tab != list.length -1" @click="tab ++" src="../../assets/img/plate-arrow3.png" alt="">
          <img class="plate-arrow-icon" v-if="tab == list.length -1" src="../../assets/img/plate-arrow4.png" alt="">
        </div>
      </div>
      <!-- 详情 -->
    <plateDetail v-if="type == 'detail'" :plateId="plateId"></plateDetail>
        <!-- 事业详情 -->
    <causeDetail v-if="type == 'causeDetail'" :plateId="plateId"></causeDetail>
    
      <!-- 生前契约界面 -->
    <PrePlanDetail v-if="type == 'PrePlanDetail'" :plateId="plateId"></PrePlanDetail>
    </div>

    <div v-if="terminal == 'Mobile'">
     <p class="mobile-go-back" v-if="type != 'list'&&list.length>0" @click="type = 'list'">  《 {{$t("home.home-19")}}{{list[tab].Title}}{{$t("home.home-20")}}</p>
      <div class="mobile-plate-content" v-if="type == 'list'">
        <div v-for="(item,index) in list" :key="index" >
          <div class="mobile-plate-inner" v-if="item.Remarks != '600' && tab == index">
            <p class="mobile-plate-p1"> {{ item.Title }}</p>
            <!-- <div class="mobile-plate-img">
              <img class="mobile-plate-img-inner" :src="imgBaseUrl + item.ImgUrl" alt="">
             <div class="mobile-plate-img-p">
                <p class="mobile-plate-img-p1">{{ item.Title }}</p>
                <p class="mobile-plate-img-p2" v-if="item.Remarks =='10'">{{$t("home.home-40")}}</p>
                <p class="mobile-plate-img-p2" v-if="item.Remarks =='20'">{{$t("home.home-41")}}</p>
                <p class="mobile-plate-img-p2" v-if="item.Remarks =='30'">{{$t("home.home-42")}}</p>
                <p class="mobile-plate-img-p2" v-if="item.Remarks =='50'">{{$t("home.home-43")}}</p>
                <p class="mobile-plate-img-p2" v-if="item.Remarks =='40'">{{$t("home.home-44")}}</p>
                <p class="mobile-plate-img-p2" v-if="item.Remarks =='70'">{{$t("home.home-45")}} {{$t("home.home-46")}}</p>
                <p class="mobile-plate-img-p2" v-if="item.Remarks =='80'">{{$t("home.home-47")}}</p>
              </div>
                
            </div> -->
            <div class="mobile-plate-bottom" :class="isBottombg(item)">
              <p class="mobile-plate-p2">{{ item.briefIntroduction }}</p>
              <p class="mobile-plate-p3" @click="toDetail(item)" >{{$t("home.see-detail")}} >></p>
            </div>
            
          </div>
          <div v-if="item.Remarks == '600' && tab == index">
            <div class="mobile-cause-box">
              <img @click="toDetail1(item)" v-for="(item,index) in causeList" :key="index" class="mobile-cause-img" :src="imgBaseUrl + item.ImgUrl" alt="">
            </div>
          </div>
        </div>
        <div class="mobile-plate-arrow">
          <img class="plate-arrow-icon" v-if="tab == 0" src="../../assets/img/plate-arrow1.png" alt="">
          <img class="plate-arrow-icon" v-if="tab != 0" @click="tab --" src="../../assets/img/plate-arrow2.png" alt="">
          <img class="plate-arrow-icon" v-if="tab != list.length -1" @click="tab ++" src="../../assets/img/plate-arrow3.png" alt="">
          <img class="plate-arrow-icon" v-if="tab == list.length -1" src="../../assets/img/plate-arrow4.png" alt="">
        </div>
      </div>
      <!-- 详情 -->
    <plateDetail v-if="type == 'detail'" :plateId="plateId"></plateDetail>
        <!-- 事业详情 -->
    <causeDetail v-if="type == 'causeDetail'" :plateId="plateId"></causeDetail>

      <!-- 生前契约界面 -->
    <PrePlanDetail v-if="type == 'PrePlanDetail'" :plateId="plateId"></PrePlanDetail>

    </div>
    

    <Footer></Footer>

    <el-dialog
      v-if="terminal == 'PC'"
      :title="title"
      :visible.sync="dialogVisible"
      width="1280px"  style="padding:0px"
      :before-close="handleClose">
      <map1Vue v-if="mapType == 2"></map1Vue>
      <mapVue v-if="mapType == 1"></mapVue>
    </el-dialog>
    <el-dialog 
      class="mobile-dialog"
      v-if="terminal == 'Mobile'"
      :title="title"
      :visible.sync="dialogVisible"
      width="90%" 
      style="padding:0px;"
      :before-close="handleClose">
      <map1Vue v-if="mapType == 2"></map1Vue>
      <mapVue v-if="mapType == 1"></mapVue>
    </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import plateDetail from './plateDetail.vue'
import causeDetail from './causeDetail.vue'
import PrePlanDetail from './PrePlanDetail.vue'
import map1Vue from '../map/map1.vue'
import mapVue from '../map/map.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    plateDetail,
    causeDetail,
     PrePlanDetail,
    map1Vue,
    mapVue
  },
  
  data(){
    return{
      list: [],
      tab: 0,
      imgBaseUrl: '',
      type: 'list',
      plateId: '',
      causeId: '',
      causeList: [],
      menu: 0,
      dialogVisible: false,
      mapType: '',
      title:'',
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.menu = this.$route.query.menu
    this.tab = this.menu
    this.getList()
  },
  methods:{
    isBottombg(item){
     return 'bottom-bg'+item.Remarks
    },
    handleClose(){
      this.dialogVisible = false
    },
    getList(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_GroupSegment/GetPageDataV1",params).then(
        (res) => {
          that.list = res.data.rows
          that.list.forEach(item =>{

         
            if(item.Remarks == '60'){
             
              //that.causeId = item.ID
              //this.getCause()
            }
          })
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    toDetail(item){
      if(item.Remarks == '10'){
        // if(this.terminal == 'PC'){
          this.mapType = 1
          this.dialogVisible = true
          this.title= this.$t("home.home-27") 
        // }else if(this.terminal == 'Mobile'){
        //   this.$router.push({name: 'map'})
        // }
      }else if(item.Remarks == '20'){
        this.mapType = 2
        this.dialogVisible = true
        this.title= this.$t("home.home-28") 
      }else if(item.Remarks == '60'){
         this.type = 'PrePlanDetail'
      }else{
        if(item.Jumpaddress){
          if(item.Jumpaddress.indexOf('http') != -1){ //跳转第三方
            window.open(item.Jumpaddress,'_blank');
          }else{
            let routerName = item.Jumpaddress
            this.$router.push({name: routerName,query:{ menu: 1 }})
          }
        }else{
          this.type = 'detail'
          this.plateId = item.ID
        }
      }
    },
    toDetail1(item){

     if(item.Jumpaddress=='PrePlanDetail'){
       this.type = 'PrePlanDetail'
     }else{
      this.type = 'causeDetail'
     }
     
     
     this.plateId = item.ID
    },
    getCause(){
      let that = this
      let params = {
        Para: that.causeId
      }
      that.$axios.post(this.baseURL + "api/Web_GroupSegmentSet/GetPageDataV1",params).then(
        (res) => {
          that.causeList = res.data.rows
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>
<style lang="scss">
.el-dialog__body{

  padding: 0px !important;
}
.el-dialog__header {
  
  padding: 12px !important;
}
.el-dialog__title{

  font-size: 14px !important;
}

// .bottom-bg10{
//       background: url('../../assets/img/plate-bg.png') no-repeat center center;
//       background-size: 100% 100%;
// }
// .bottom-bg20{
//        background: url('../../assets/img/plate-bg1.png') no-repeat center center;
//       background-size: 100% 100%;
// }
// .bottom-bg30{
//           background: url('../../assets/img/plate-bg2.png') no-repeat center center;
//       background-size: 100% 100%;
// }
// .bottom-bg40{
//           background: url('../../assets/img/plate-bg6.png') no-repeat center center;
//       background-size: 100% 100%;
// }
// .bottom-bg50{
//          background: url('../../assets/img/plate-bg3.png') no-repeat center center;
//       background-size: 100% 100%;
// }
// .bottom-bg60{
//          background: url('../../assets/img/plate-bg.png') no-repeat center center;
//       background-size: 100% 100%;
// }
// .bottom-bg70{
//          background: url('../../assets/img/plate-bg4.png') no-repeat center center;
//       background-size: 100% 100%;
// }
// .bottom-bg80{
//          background: url('../../assets/img/plate-bg5.png') no-repeat center center;
//       background-size: 100% 100%;
// }

 .bottom-bg10{
            background: linear-gradient(127.3deg, #E9F9FA 5.98%, #D4E2CF 100%); background-size: 100% 100%;
        }
        .bottom-bg20{
            background: linear-gradient(127.3deg, #FAFAF7 5.98%, #D3D4D2 100%); background-size: 100% 100%;
        }
        .bottom-bg30{
            background: linear-gradient(127.3deg, #EFFAFF 5.98%, #D4EAF8 100%); background-size: 100% 100%;
        }
        .bottom-bg40{
            background: linear-gradient(121.77deg, #EEFBF2 19.25%, #D5E6DB 100%); background-size: 100% 100%;
        }
        .bottom-bg50{
            background: linear-gradient(127.3deg, #FFF8F3 5.98%, #F7E3DC 100%); background-size: 100% 100%;
        }
        .bottom-bg60{
            background: linear-gradient(127.3deg, #E9F9FA 5.98%, #D4E2CF 100%); background-size: 100% 100%;
        }
        .bottom-bg70{
            background: linear-gradient(127.3deg, #F4F9FF 5.98%, #D2DFF2 100%); background-size: 100% 100%;
        }
        .bottom-bg80{
            background: linear-gradient(126.7deg, #FCF7ED 5.98%, #D8DBC7 109.48%); background-size: 100% 100%;
        }
</style>


