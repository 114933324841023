<template>
  <div class="home">
    <Header></Header>
    <div class="news-banner" v-if="terminal == 'PC'">
      <p class="model-name">{{$t("head.head-10")}}</p>
      <div class="tab-box">
        <p class="tab-box-p" style="text-align: left;" :class="{'tabActive': tab == 1}" @click="changeTab(1)">{{$t("head.head-11")}}</p>
        <p class="tab-box-p" :class="{'tabActive': tab == 2}" @click="changeTab(2)">{{$t("head.head-12")}}</p>
        <p class="tab-box-p" :class="{'tabActive': tab == 3}" @click="changeTab(3)">{{$t("head.head-13")}}</p>
        <p class="tab-box-p" style="border-right: none" :class="{'tabActive': tab == 4}" @click="changeTab(4)">{{$t("head.head-14")}}</p>
      </div>
    </div>
    <div class="mobile-news-banner" v-if="terminal == 'Mobile'">
      <p class="mobile-model-name">{{$t("head.head-10")}}</p>
      <p class="mobile-model-name2" v-if="tab == 1">{{$t("head.head-11")}}</p>
      <p class="mobile-model-name2" v-if="tab == 2">{{$t("head.head-12")}}</p>
      <p class="mobile-model-name2" v-if="tab == 3">{{$t("head.head-13")}}</p>
      <p class="mobile-model-name2" v-if="tab == 4">{{$t("head.head-14")}}</p>
    </div>
    <!-- 集团要闻 -->
    <groupNews v-if="tab == 1" :listtype="type"></groupNews>
     <!-- 各地动态 -->
    <localDynamics v-if="tab == 2" :listtype="type"></localDynamics>
    <!-- 媒体聚焦 -->
    <mediaFocus v-if="tab == 3" :listtype="type"></mediaFocus>
    <!-- 企业刊物 -->
    <companyJournal v-if="tab == 4" :listtype="type"></companyJournal>
    <!-- 名人介绍 -->
    <!-- <celebrity v-if="tab == 5"></celebrity> -->
    
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import groupNews from './components/groupNews.vue'
import mediaFocus from './components/mediaFocus.vue'
import companyJournal from './components/companyJournal.vue'
// import celebrity from './components/celebrity.vue'
import localDynamics from './components/localDynamics.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    groupNews,
    localDynamics,
    mediaFocus,
    companyJournal,
    // celebrity
  },
  
  data(){
    return{
      list: [],
      notice: '',
      internationalTab: 1,
      ktab: 1,
      customerNum: 0,
      Dialog: false,
      tabBag: 'tabBag1',
      status:false,
      menu: 1,
      tab: 0,
      terminal: 'PC',
      type: 'list',
      indextz:0,
    }
  },
  mounted(){
    window.scrollTo(0,0);
    // alert("tab:"+this.tab);
    this.terminal = localStorage.getItem('terminal')
    this.menu = this.$route.query.menu
    this.tab = this.menu
    this.indextz=this.menu
  },
  methods:{
    changeTab(index){
      console.log(index)
      // this.type = 'list'
      this.$router.push({name: 'news',query:{ menu: index,listtype: 'list' }})
     if(this.indextz==index){
      //location.reload();
     }else{
      this.indextz=index;
     }
    }
  }
}
</script>
<style lang="scss">
.news-banner{
  background: url('../../assets/img/news/news-bg1.png') no-repeat center center;
  background-size: 100% 100%;
}
.mobile-news-banner{
  background: url('../../assets/img/news/news-appbg1.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>

