<template>
  <div>
    <div class="news-detail" v-if="terminal == 'PC'">
      <p class="detail-title">{{detail.Title}}</p>
      <p class="detail-info">
        <span class="detail-time" v-if="detail.Releasetime">{{detail.Releasetime.substring(0,11)}}</span>
        <span class="detail-from">{{detail.NewsSources}}</span>
        <!-- <span class="detail-num"><img class="see-icon" src="../../../assets/img/see.png" alt="">{{detail.Readingvolume}}</span> -->
      </p>
      <div class="detail-content" v-html="detail.Content"></div>
    </div>

    <div class="mobile-news-detail" v-if="terminal == 'Mobile'">
      <p class="mobile-detail-title">{{detail.Title}}</p>
      <div class="mobile-detail-info">
        <p class="mobile-detail-time" v-if="detail.Releasetime">{{detail.Releasetime.substring(0,11)}}</p>
        <p>
          <!-- <span class="mobile-detail-num"><img class="see-icon" src="../../../assets/img/see.png" alt="">{{detail.Readingvolume}}</span> -->
          <span class="mobile-detail-from">{{detail.NewsSources}}</span>
        </p>
      </div>
        <div class="mobile-detail-content" v-html="detail.Content"></div>
    </div>
  </div>
  
</template>

<script>

export default {
  name: 'HomeView',
  props:{
      id:{
          type: Number,
          default:"这是默认值"
      }
  },
  data(){
    return{
      detail: {},
      terminal: 'PC'
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    console.log(this.id)
    this.getInfo()
  },
  methods:{
    getInfo(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_MediaFocus/GetPageDataV2",{para: that.id }).then(
        (res) => {
          console.log(res)
          that.detail = res.data.rows.rows[0]
          console.log(that.detail)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

