<template>
  <div class="home">
    <Header></Header>
    <div v-if="terminal == 'PC'">
      <div class="search-banner">
        <!-- <img class="banner-img" src="../assets/img/news/news-bg1.png" alt=""> -->
      </div>
      <div class="search-head">
        <p class="header-left">{{$t("home.home-10")}} Search Results</p>
        <p class="header-right">{{$t("home.home-12")}} <span class="color-style">“ {{ content }} ”</span>  {{$t("home.home-13")}}<span class="color-style"> {{ list.length }} </span>{{$t("home.home-14")}}</p>
      </div>
      <div class="search-list" v-if="noData == false">
        <div class="search-inner" v-for="(item,index) in list" :key="index" >
          <img class="search-img" :src="imgBaseUrl + item.ImgUrl" alt="">
          <div class="list-right">
            <p class="search-p1">{{item.Title}}</p>
            <p class="search-p2">{{$t("home.home-15")}}：{{ item.Publisher }}</p>
            <p class="search-p3">{{ item.briefIntroduction }}</p>
            <div class="see-box">
              <p class="see-p1">{{item.Releasetime}} <span>{{item.Readingvolume}}</span></p>
              <p class="see-p2" @click="toDetail(item)">{{$t("home.see-detail")}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="search-list" v-if="noData">
        <img class="no-data-icon" src="../assets/img/no-data.png" alt="">
        <p class="no-data-p">{{$t("home.home-11")}}</p>
      </div>
    </div>
    

    <div v-if="terminal == 'Mobile'">
      <div class="mobile-search-banner">
        <img class="banner-img" src="../assets/img/news/news-appbg1.png" alt="">
      </div>
      <div class="mobile-search-head">
        <p class="mobile-header-left">{{$t("home.home-10")}} Search Results</p>
        <p class="mobile-header-right">{{$t("home.home-12")}}<span class="color-style">“ {{ content }} ”</span> {{$t("home.home-13")}}<span class="color-style"> {{ list.length }} </span>{{$t("home.home-14")}}</p>
      </div>
      <div class="mobile-news-content" v-if="noData == false">
          <div class="mobile-news-inner" @click="toDetail(item)" v-for="item,index in list" :key="index">
            <img class="mobile-news-img" :src="imgBaseUrl + item.ImgUrl" alt="">
            <p class="mobile-news-p">{{item.Title}}</p>
            <p class="mobile-news-p2">{{ item.briefIntroduction }}</p>
            <div class="mobile-news-bottom">
              <p class="mobile-news-time">
                <!-- <span class="see-people"> <img class="see-icon" src="../assets/img/see.png" alt=""> {{item.Readingvolume}}</span> -->
                <span class="news-from">{{item.NewsSources}}</span>
              </p>
              <p class="mobile-news-time" style="color: rgba(208, 157, 63, 1)">{{$t("home.see-detail")}} 》</p>
            </div>
          </div>
        </div>
      <div class="search-list" v-if="noData">
        <img class="no-data-icon" src="../assets/img/no-data.png" alt="">
        <p class="no-data-p">{{$t("home.home-11")}}</p>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  },
  
  data(){
    return{
      list: [],
      imgBaseUrl: '',
      noData: false,
      notice: '',
      internationalTab: 1,
      ktab: 1,
      customerNum: 0,
      Dialog: false,
      tabBag: 'tabBag1',
      status:false,
      content: '',
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.content = this.$route.query.content
    this.getList()
  },
  methods:{
    toDetail(item){
      //  localStorage.setItem('newsid',JSON.stringify(item))
      //  this.$router.push({name: "news",query:{ menu: item.NewsClassification }})
      let routeUrl = this.$router.resolve({
          name: "searchDetail",
          query: {id:item.ID,type: item.NewsClassification}
     });
      window.open(routeUrl .href, '_blank');
    },
    getList(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1,
        para: that.content
      }
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV8",params).then(
        (res) => {
          that.list = res.data.rows
          if(that.list.length == 0){
            that.noData = true
          }else{
            that.noData = false
          }
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>
<style lang="scss">
.banner{
    width: 100%;
    height: 672px;
    position: relative;
    top: -100px;
  }
.search-banner{
  background: url('../assets/img/news/news-bg1.png') no-repeat center center;
  background-size: 100% 100%;
  background-position:0 50px!important;

     width:1903px;/*图片宽度*/ 
    left:50%; 
    margin-left:-952px;/*图片宽度的一半*/
  
}

</style>
