<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-2")}}> </span> <span class="top-menu-left-span2" > {{$t("head.head-8")}}</span> 
        </div>
      </div>
      <div class="introduce-box">
          <div class="introduce-left" >
            <div class="introduce-tab">
              <p class="tab-p" :class="{'activeTab': tab == 1}" @click="changeTab(1)">{{$t("home.home-31")}}</p>
              <p class="tab-p" :class="{'activeTab': tab == 2}" @click="changeTab(2)">{{$t("home.home-32")}}</p>
            </div>
            <div class="introduce-img-box">
              <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
            </div>
            <div class="left-bottom">
              <p class="introduce-left-p1">01448.HK</p>
              <p class="introduce-left-p2">{{ codeNumber }}</p>
              <p class="introduce-left-p3">HKD</p>
              <p class="introduce-left-p4">{{$t("home.home-29")}}  {{ timeInfo }}</p>
              <p class="introduce-left-p4">{{$t("home.home-30")}}</p>
            </div>
          </div>
          <div class="introduce-right">
            <div class="honor-box" v-if="tab == 1">
              <div class="honer-inner" v-for="(item,index) in honerList" :key="index">
                <img class="honer-img" :src="imgBaseUrl + item.ImgUrl" alt="">
                <div style="height: 100%;height: 60px;display: flex;flex-wrap: wrap;justify-content: center;flex-direction: center;align-items: center;">
                  <p class="honer-p1" style="width: 100%;text-align: center;">{{ item.Title }}<br />{{ item.briefIntroduction }}</p>
                  <!-- <p class="honer-p2" style="width: 100%;text-align: center;"></p> -->
                </div>
                
              </div>
            </div>
            <div class="qualification" v-if="tab == 2">
              <div class="qualification-inner" v-for="(item,index) in qualificationList" :key="index">
                <p class="qualification-p p-left">{{item.Name}}</p>
                <p class="qualification-p" v-if="item.Time">{{item.Time.substring(0,4)}}{{$t("home.home-39")}}</p>                              
                <p class="qualification-p p-right">{{item.Mechanism}}</p>
              </div>
            </div>
          </div>
      </div>
    </div>



    <div style="background: rgba(254,250,245);padding-top: 30px;" v-if="terminal == 'Mobile'">
      <div class="mobile-introduce-tab" style="justify-content: center;margin-bottom: 30px;">
        <p class="mobile-tab-p" :class="{'activeTab': tab == 1}" style="margin-right: 30px;" @click="changeTab(1)">{{$t("home.home-31")}}</p>
        <p class="mobile-tab-p" style="width:120px"  :class="{'activeTab': tab == 2}" @click="changeTab(2)">{{$t("home.home-32")}}</p>
      </div>
      <div class="mobile-honor-box" v-if="tab == 1">
        <div class="mobile-honer-inner" v-for="(item,index) in honerList" :key="index">
          <img class="mobile-honer-img" :src="imgBaseUrl + item.ImgUrl" alt="">
          <p class="mobile-honer-p1">{{ item.Title }}</p>
          <p class="mobile-honer-p1">{{ item.briefIntroduction }}</p>
        </div>
      </div>
      <div class="mobile-qualification" v-if="tab == 2">
        <div class="mobile-qualification-inner" v-for="(item,index) in qualificationList" :key="index">
          <p class="mobile-qualification-p p-left">{{item.Name}}</p>
          <p class="mobile-qualification-p" v-if="item.Time">{{item.Time.substring(0,4)}}{{$t("home.home-39")}}</p>                              
          <p class="mobile-qualification-p p-right">{{item.Mechanism}}</p>
        </div>
      </div>
      <div class="mobile-introduce-box" style="width: 345px;margin: 0 auto;">
        <div class="mobile-introduce-left" >
          <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
        </div>
        <div class="mobile-introduce-right">
          <p class="introduce-left-p1">01448.HK</p>
          <p class="introduce-left-p2">{{ codeNumber }}</p>
          <p class="introduce-left-p3">HKD</p>
          <p class="introduce-left-p4">{{$t("home.home-29")}}  {{ timeInfo }}</p>
          <p class="introduce-left-p4">{{$t("home.home-30")}}</p>
        </div>
      </div>
    </div>


  </div>
  
</template>

<script>
export default {
  name: 'noticePage',
  props:{
    codeNumber:{
      type: String,
      default: '0'
    },
    timeInfo: {
      type: String,
      default: '0'
    }
  },
  data(){
    return{
      type: 'list',
      list: [],
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      tab: 1,
      introduceInfo: {},
      honerList: [],
      qualificationList: [],
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getList()
    this.getList1()
    this.getList2()
  },
  methods:{
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    changeTab(index){
      this.tab = index
    },
    getList(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_GroupIntroduction/GetPageDataV1",params).then(
        (res) => {
          that.introduceInfo = res.data.rows[0]
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    getList1(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1,
        page: 1,
        rows: 200,
      }
      that.$axios.post(this.baseURL + "api/Web_EnterpriseHonor/GetPageDataV1",params).then(
        (res) => {
          that.honerList = res.data.rows
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    getList2(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1,
        page: 1,
        rows: 12,
      }
      that.$axios.post(this.baseURL + "api/Web_EnterpriseQualification/GetPageDataV1",params).then(
        (res) => {
          that.qualificationList = res.data.rows
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>
<style >
.introduce-tab{
  background: url('../../../assets/img/introduce-tab-bg.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
</style>