<template>
  <div class="home">
    <Header></Header>
    <div class="news-banner" v-if="terminal == 'PC'">
      <p class="model-name">{{$t("head.head-2")}}</p>
      <div class="tab-box">
        <p class="tab-box-p" style="text-align: left;" :class="{'tabActive': tab == 1}" @click="tab = 1">{{$t("head.head-3")}}</p>
          <p class="tab-box-p" :class="{'tabActive': tab == 4}" @click="tab = 4">{{$t("head.head-6")}}</p>
        <p class="tab-box-p" :class="{'tabActive': tab == 2}" @click="tab = 2">{{$t("head.head-4")}}</p>
        <p class="tab-box-p" :class="{'tabActive': tab == 5}" @click="tab = 5">{{$t("head.head-7")}}</p>

      
        <p class="tab-box-p" :class="{'tabActive': tab == 6}" @click="tab = 6">{{$t("head.head-8")}}</p>
        <p class="tab-box-p"  :class="{'tabActive': tab == 7}" @click="tab = 7">{{$t("head.head-9")}}</p>
        <p class="tab-box-p" style="border-right: none" :class="{'tabActive': tab == 3}" @click="tab = 3">{{$t("head.head-5")}}</p>

      </div>
    </div>
    <div class="mobile-news-banner" v-if="terminal == 'Mobile'">
      <p class="mobile-model-name">{{$t("head.head-2")}}</p>
      <p class="mobile-model-name2" v-if="tab == 1">{{$t("head.head-3")}}</p>
         <p class="mobile-model-name2" v-if="tab == 4">{{$t("head.head-6")}}</p>
      <p class="mobile-model-name2" v-if="tab == 2">{{$t("head.head-4")}}</p>
      <p class="mobile-model-name2" v-if="tab == 5">{{$t("head.head-7")}}</p>

   
      <p class="mobile-model-name2" v-if="tab == 6">{{$t("head.head-8")}}</p>
      <p class="mobile-model-name2" v-if="tab == 7">{{$t("head.head-9")}}</p>
      <p class="mobile-model-name2" v-if="tab == 3">{{$t("head.head-5")}}</p>

    </div>
    <!-- 集团概况 -->
    <profile v-if="tab == 1" :codeNumber="codeNumber" :timeInfo="timeInfo"></profile>
    <!-- 使命愿景 -->
    <Mission v-if="tab == 2" :codeNumber="codeNumber" :timeInfo="timeInfo"></Mission>
    <!-- 品牌商标 -->
    <brand v-if="tab == 3" :codeNumber="codeNumber" :timeInfo="timeInfo"></brand>
    <!-- 管理团队 -->
    <team v-if="tab == 4" :codeNumber="codeNumber" :timeInfo="timeInfo"></team>
    <!-- 发展历程 -->
    <development v-if="tab == 5" :codeNumber="codeNumber" :timeInfo="timeInfo"></development>
    <!-- 荣誉资质 -->
    <honer v-if="tab == 6" :codeNumber="codeNumber" :timeInfo="timeInfo"></honer>
    <!-- 宣传视频 -->
    <videoPage v-if="tab == 7" :codeNumber="codeNumber" :timeInfo="timeInfo"></videoPage>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import profile from './components/profile.vue'
import Mission from './components/Mission.vue'
import brand from './components/brand.vue'
import team from './components/team.vue'
import development from './components/development.vue'
import honer from './components/honer.vue'
import videoPage from './components/video.vue'
import axios from 'axios'
// import 
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    profile,
    Mission,
    brand,
    team,
    development,
    honer,
    videoPage
  },
  
  data(){
    return{
      list: [], 
      notice: '',
      internationalTab: 1,
      ktab: 1,
      customerNum: 0,
      Dialog: false,
      tabBag: 'tabBag1',
      status:false,
      tab: 1,
      menu: 1,
      codeNumber: '',
      timeInfo: '',
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.menu = this.$route.query.menu
    this.tab = this.menu
    this.getInfo()
    // this.getTime()
  },
  methods:{
    getInfo(){
      let that = this
      axios({
          url: 'https://web.sqt.gtimg.cn/q=r_hk01448'
      }).then(res => {
          that.codeNumber = res.data.split('~')[3]
          this.timeInfo = res.data.split('~')[30]
      })
    },
    // getTime(){
    //   let time = new Date();
    //   this.timeInfo =(time.getFullYear()+'/'+time.getMonth()+'/'+time.getDate()+' '+time.getHours()+':'+time.getMinutes()+':'+time.getSeconds())
    // }
  }
}
</script>
<style lang="scss">
.news-banner{
  background: url('../../assets/img/news/news-bg1.png') no-repeat center center;
  background-size: 100% 100%;
}
.mobile-news-banner{
  background: url('../../assets/img/news/news-appbg1.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>

