<template>
  <div class="home">
    <Header></Header>
    <p style="margin-top: 100px;margin-left: 100px">{{$t("home.home-18")}}</p>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  },
  
  data(){
    return{
      list: [],
      notice: '',
      internationalTab: 1,
      ktab: 1,
      customerNum: 0,
      Dialog: false,
      tabBag: 'tabBag1',
      status:false,
        
    }
  },
  mounted(){
  },
  methods:{
    changeBanner(e){
      console.log(e)
      if(e == 0){
        this.$refs.videoPlayer.play()
      }
    },
  }
}
</script>

