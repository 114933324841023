<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-10")}} ></span> 
          <span class="top-menu-left-span2" v-if="type == 'list'" @click="type='list'">{{$t("head.head-12")}}</span> 
          <span class="top-menu-left-span2" v-if="type == 'detail'" @click="type='list'">{{$t("home.home-6")}}</span> 
        </div>
        <div class="top-menu-right">
          <div class="year-select">
            <el-select v-model="year" :placeholder='$t("home.home-1")' @change="toSearch" clearable>
              <el-option
                v-for="item in yearList"
                :key="item.year"
                :label="item.year"
                :value="item.year"
                >
              </el-option>
            </el-select>
          </div>
          <div class="search-input">
            <el-input
            :placeholder='$t("home.home-2")'
              v-model="value" @keyup.enter.native="toSearch" clearable @clear="toSearch">
              <i slot="suffix" @click="toSearch" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
      </div>
      <div v-if="type == 'list'">
          <div class="news-line" @click="toDetail(item)" v-for="item,index in list" :key="index">
            <div class="news-line-left">
              <p class="date1" v-if="item.Releasetime">{{ item.Releasetime.substring(5,7) }}.{{ item.Releasetime.substring(8,10) }}</p>
              <span class="see-people"><img class="Vector-icon" src="../../../assets/img/Vector.png" alt="">
                 <span v-if="item.Releasetime" class="date2">{{item.Releasetime.substring(0,4)}} </span>
                 </span>
            </div>
            <div class="news-line-center">
              <p class="news-title">
                <span class="news-tag" v-if="index < 2">NEW</span>
                  {{item.Title.length>150?item.Title.substring(0,149)+".....":item.Title }}
              </p>
              <p class="news-desc">{{ item.briefIntroduction.length>450?item.briefIntroduction.substring(0,449)+".....":item.briefIntroduction }}</p>
              <p class="news-tips">
                <span class="news-from">{{item.NewsSources}}</span>
                <!-- <span>{{item.Readingvolume}}</span> -->
                <!-- <span class="see-people"> <img class="see-icon" src="../../../assets/img/see.png" alt=""> {{item.Readingvolume}}</span> -->
              </p>
            </div>
            <div class="news-line-right">
              <img class="news-img" :src="imgBaseUrl + item.ImgUrl" alt="">
            </div>
        </div>
        <p class="see-more" v-if="isMore" @click="seeMore">{{$t("home.see-more")}}</p>
      </div>
        <newsDetail v-if="type == 'detail'" :id="id"></newsDetail>
          <div class="search-list" v-if="noData" style="margin-top: 150px;">
        <img class="no-data-icon" src="../../../assets/img/no-data.png" alt=""> 
        <p class="no-data-p">{{$t("home.no-data")}}</p>
    </div>
    </div>
    <div class="mobile-news" v-if="terminal == 'Mobile'">
      <p class="mobile-go-back" v-if="type == 'detail'" @click="type = 'list'">  《 {{$t("home.home-6")}}</p>
      <div v-if="type == 'list'">
        <div class="select-box">
          <div class="mobile-year-select">
            <el-select v-model="year" :placeholder='$t("home.home-1")' @change="toSearch" clearable>
              <el-option
                v-for="item in yearList"
                :key="item.year"
                :label="item.year"
                :value="item.year"
                >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="mobile-news-content">
          <div class="mobile-news-inner" @click="toDetail(item)" v-for="item,index in list" :key="index">
            <img class="mobile-news-img" :src="imgBaseUrl + item.ImgUrl" alt="">
            <p class="mobile-news-p">{{item.Title}}</p>
            <p class="mobile-news-p2">{{ item.briefIntroduction }}</p>
            <div class="mobile-news-bottom">
              <p class="mobile-news-time">{{ item.Releasetime }}</p>
              <p class="mobile-news-time">
                <!-- <span class="see-people"> <img class="see-icon" src="../../../assets/img/see.png" alt=""> {{item.Readingvolume}}</span> -->
                <span class="news-from">{{item.NewsSources}}</span>
              </p>
            </div>
          </div>
          <p class="mobile-see-more" v-if="isMore" @click="seeMore">{{$t("home.see-more")}}</p>
        </div>
      </div>
      <newsDetail v-if="type == 'detail'" :id="id"></newsDetail>
    </div>
    
  
  </div>
  
</template>

<script>
import newsDetail from './newsDetail.vue'
export default {
  name: 'HomeView',
  components: {
    newsDetail
  },
  data(){
    return{
      type: 'list',
      list: [],
      typeList: [],
      tabType: 0,
      currentType: '',
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      yearList: [],
      year: '',
      value: '',
      isMore: false,
      noData:false,
      terminal: 'PC'
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.yearList = JSON.parse(localStorage.getItem('yearList'))
     //this.getType()
    this.getList()
    if(localStorage.getItem('newsid'))
    {
      var item=JSON.parse(localStorage.getItem('newsid'));
      this.toDetail(item);
      localStorage.setItem('newsid',"");
    }
  },
  methods:{
    seeMore(){
      this.currentPage ++
      this.getList()
    },
    toSearch(){
      this.type = 'list'
      this.list=[]
      this.currentPage = 1
      this.isMore = false
      this.noData=false;
      this.getList()
    },
    toDetail(item){
      // this.type = 'detail'
      // this.id = item.ID
      let routeUrl = this.$router.resolve({
          name: "searchDetail",
          query: {id:item.ID,type: 1}
     });
      window.open(routeUrl .href, '_blank');
    },
    changeTab(item,index){
      console.log(111)
      this.tabType = index
      this.currentType = item.ID
      this.currentPage = 1
      this.type = 'list'
      this.getList()
    },
    getType(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.typeList = [
        {
          Name: '全部',
          ID: ''
        }
      ]
      that.$axios.post(this.baseURL + "api/Web_NewsCategory/GetPageDataV1",params).then(
        (res) => {
          res.data.rows.forEach(item => {
            that.typeList.push(item)
          });
        },
        (error) => {
          console.log("请求失败了", error.message);
        }
      );
    },
    getList(){
      let that = this
      let params = {
        page: this.currentPage,
        rows: 10,
        para: `2|${that.value}|${that.year}`,
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_News/GetPageDataV1",params).then(
        (res) => {
          if(that.currentPage == 1){
            that.list = res.data.rows
          }else{
            res.data.rows.forEach(item => {
              that.list.push(item)
            });
          }
          if(res.data.total > 10){
            that.isMore = true
          }
         if(res.data.total  == 0 || res.data.rows.length == 0|| res.data.rows.length < 10){
            that.isMore = false
          }
          if(res.data.total  == 0){
            that.noData=true;
          }
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
</style>