<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-2")}}> </span> <span class="top-menu-left-span2" > {{$t("head.head-7")}}</span> 
        </div>
      </div>
      <div class="introduce-box">
          <div class="introduce-left" >
            <div class="introduce-img-box">
              <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
            </div>
            <div class="left-bottom">
              <p class="introduce-left-p1">01448.HK</p>
              <p class="introduce-left-p2">{{ codeNumber }}</p>
              <p class="introduce-left-p3">HKD</p>
              <p class="introduce-left-p4">{{$t("home.home-29")}}  {{ timeInfo }}</p>
              <p class="introduce-left-p4">{{$t("home.home-30")}}</p>
            </div>
          </div>
          <div class="introduce-right">
            <p class="develop-p1">{{ detail.briefIntroduction }}</p>
            <div class="time-line">
              <el-timeline :reverse="reverse" >
                <el-timeline-item
                  v-for="(item, index) in list1"
                  :key="index"
                  :timestamp="item.timestamp"
                  :hide-timestamp="true"
                  color="#E8DABF">
                  <label class="time-line-box">
                    <div class="time-line-left">
                      <p class="time-line-p1">
                        <span> {{ item.timestamp }} </span>
                        <span style="margin-left: 30px;"> {{ item.Title }}</span>
                      </p>
                      <p class="time-line-p2">{{ item.BriefIntroduction }}</p>
                    </div>
                    <img class="time-line-right" :src="imgBaseUrl + item.PictureUrl" alt="">
                  </label>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
      </div>
    </div>

    <div style="background: rgba(254,250,245);padding-top: 30px;" v-if="terminal == 'Mobile'">
      <div class="mobile-time-line">
        <el-timeline :reverse="reverse" >
          <el-timeline-item
            v-for="(item, index) in list1"
            :key="index"
            :timestamp="item.timestamp"
            :hide-timestamp="true"
            color="#E8DABF">
            <label class="mobile-time-line-box">
              <div class="mobile-time-line-left">
                <div class="mobile-time-line-inner">
                  <p class="mobile-time-line-p1"> {{ item.Title }}</p>
                  <p class="mobile-time-line-p2"> {{ item.timestamp }} </p>
                </div>
              </div>
              <div class="mobile-time-line-right">
                <img class="mobile-time-line-img" :src="imgBaseUrl + item.PictureUrl" alt="">
                <p class="mobile-time-line-p3">{{ item.BriefIntroduction }}</p>
              </div>
            </label>
          </el-timeline-item>
        </el-timeline>
      </div>

      <div class="mobile-introduce-box" style="width: 94%;margin: 0 auto;">
        <div class="mobile-introduce-left" >
          <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
        </div>
        <div class="mobile-introduce-right">
          <p class="introduce-left-p1">01448.HK</p>
          <p class="introduce-left-p2">{{ codeNumber }}</p>
          <p class="introduce-left-p3">HKD</p>
          <p class="introduce-left-p4">{{$t("home.home-29")}}  {{ timeInfo }}</p>
          <p class="introduce-left-p4">{{$t("home.home-30")}}</p>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'noticePage',
  props:{
    codeNumber:{
      type: String,
      default: '0'
    },
    timeInfo: {
      type: String,
      default: '0'
    }
  },
  data(){
    return{
      type: 'list',
      list: [],
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      reverse: false,
      detail: {},
      list1: [],
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getList()
    this.getList1()
  },
  methods:{
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    getList(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_Development/GetPageDataV1",params).then(
        (res) => {
          that.detail = res.data.rows[0]
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    getList1(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_DevelopmentHistory/GetPageDataV1",params).then(
        (res) => {
          that.list1 = res.data.rows
          that.list1.forEach((item,index) =>{


            if(that.list1.length==index+1){
              item.timestamp = item.StartTime.substring(0,4) + '~ ' +that.$t("home.home-84")
            }else{
              item.timestamp = item.StartTime.substring(0,4) + '~' + item.EndTime.substring(0,4)

            }
          
          })
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
</style>