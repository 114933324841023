<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-19")}} > </span> <span class="top-menu-left-span2" > {{$t("head.head-21")}}</span> 
        </div>
      </div>
      <div class="hezuo" v-if="isshow==true">
        <div class="h-top-box">
          <div class="hezuo-left">
            <p class="hezuo-left-p1">{{ detail.Title }}</p>
            <p class="hezuo-left-p2">{{ detail.briefIntroduction }}</p>
          </div>
          <div class="hezuo-right">
            <img class="hezuo-right-img" :src="imgBaseUrl + detail.ImgUrl" alt="">
          </div>
        </div>
        <div class="detail-content" v-html="detail.Content"></div>
      </div>
    </div>
    <div v-if="terminal == 'Mobile'">
      <div class="mobile-hezuo" v-if="isshow==true">
        <div class="mobile-hezuo-box">
          <img class="mobile-hezuo-right-img" :src="imgBaseUrl + detail.ImgUrl" alt="">
          <div class="mobile-hezuo-bottom">
            <p class="mobile-hezuo-p1">{{ detail.Title }}</p>
            <p class="mobile-hezuo-p2">{{ detail.briefIntroduction }}</p>
          </div>
        </div>
        <div class="mobile-detail-content" v-html="detail.Content"></div>
      </div>
    </div>


  </div>
  
</template>

<script>
export default {
  name: 'noticePage',
  data(){
    return{
      type: 'list',
      list: [],
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      detail: {},
      isshow:false,
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getList()
  },
  methods:{
    toDownload(item){
      let url = this.imgBaseUrl + item.Content
      window.open(url,'_blank')
    },
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    getList(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_ContactUs/GetPageDataV1",params).then(
        (res) => {
          that.detail = res.data.rows[0]
          that.isshow=true
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
</style>