//初始化地图

export function InitializeMap() {

  var map = new BMap.Map("mapBox");    
  var lat=22.54605355;
  var lng=114.02597366;
  var point = new BMap.Point(lng,lat);

map.enableScrollWheelZoom(true);
   map.addControl(new BMap.MapTypeControl({
     mapTypes: [
       BMAP_NORMAL_MAP,
       BMAP_HYBRID_MAP
     ]
   }));

      // 创建城市选择控件
      var cityControl = new BMap.CityListControl({
       // 控件的停靠位置（可选，默认左上角）
       anchor: BMAP_ANCHOR_TOP_LEFT,
       // 控件基于停靠位置的偏移量（可选）
       offset: new BMap.Size(10, 5)
   });
  //将控件添加到地图上
  //map.addControl(cityControl); 
  // 添加全景控件
//var stCtrl = new BMap.PanoramaControl();
//stCtrl.setOffset(new BMap.Size(0, 0));
//map.addControl(stCtrl);

  map.centerAndZoom("上海市", 6);
  return map;
}