<template>
  <div>
    <div class="prePlan" v-if="terminal == 'PC'">
      <img class="prePlan-video" @click="dialogVisible = true" src="../../assets/img/quette/video-img.png" alt="">
      <div class="prePlan-intro">
        <p class="prePlan-title">{{$t("home.home-51")}}</p>
        <p class="prePlan-p1">INTRODUCTION</p>
        <p class="prePlan-p2">{{$t("home.home-52")}}</p>
        <p class="prePlan-p2">{{$t("home.home-53")}}</p>
        <p class="prePlan-p2">{{$t("home.home-54")}}</p>
        <p class="prePlan-title" style="margin-top: 30px;">{{$t("home.home-55")}}</p>
        <p class="prePlan-p1">FEATURES</p>
      </div>
      <div class="prePlan-list">
        <div class="prePlan-list-inner">
          <div class="inner-img">
            <img class="inner-img-1" src="../../assets/img/quette/b1.png" alt="">
          </div>
          <div class="inner-show">
            <img class="inner-show-icon" src="../../assets/img/quette/a1.png" alt="">
            <p class="inner-show-p1">{{$t("home.home-56")}}</p>
            <p class="inner-show-p2">{{$t("home.home-57")}}</p>
          </div>
        </div>
        <div class="prePlan-list-inner">
          <div class="inner-img">
            <img class="inner-img-1" src="../../assets/img/quette/b2.png" alt="">
          </div>
          <div class="inner-show">
            <img class="inner-show-icon" src="../../assets/img/quette/a2.png" alt="">
            <p class="inner-show-p1">{{$t("home.home-58")}}</p>
            <p class="inner-show-p2">{{$t("home.home-59")}}</p>
          </div>
        </div>
        <div class="prePlan-list-inner">
          <div class="inner-img">
            <img class="inner-img-1" src="../../assets/img/quette/b3.png" alt="">
          </div>
          <div class="inner-show">
            <img class="inner-show-icon" src="../../assets/img/quette/a3.png" alt="">
            <p class="inner-show-p1">{{$t("home.home-60")}}</p>
            <p class="inner-show-p2">{{$t("home.home-61")}}</p>
          </div>
        </div>
        <div class="prePlan-list-inner">
          <div class="inner-img">
            <img class="inner-img-1" src="../../assets/img/quette/b4.png" alt="">
          </div>
          <div class="inner-show">
            <img class="inner-show-icon" src="../../assets/img/quette/a4.png" alt="">
            <p class="inner-show-p1">{{$t("home.home-62")}}</p>
            <p class="inner-show-p2">{{$t("home.home-63")}}</p>
          </div>
        </div>
        <div class="prePlan-list-inner">
          <div class="inner-img">
            <img class="inner-img-1" src="../../assets/img/quette/b5.png" alt="">
          </div>
          <div class="inner-show">
            <img class="inner-show-icon" src="../../assets/img/quette/a5.png" alt="">
            <p class="inner-show-p1">{{$t("home.home-64")}}</p>
            <p class="inner-show-p2">{{$t("home.home-65")}}</p>
          </div>
        </div>
        <div class="prePlan-list-inner">
          <div class="inner-img">
            <img class="inner-img-1" src="../../assets/img/quette/b6.png" alt="">
          </div>
          <div class="inner-show">
            <img class="inner-show-icon" src="../../assets/img/quette/a6.png" alt="">
            <p class="inner-show-p1">{{$t("home.home-66")}}</p>
            <p class="inner-show-p2">{{$t("home.home-67")}}</p>
          </div>
        </div>
      </div>

      <div class="prePlan-list1">
        <p class="prePlan-title" style="margin-top: 42px;">{{$t("home.home-68")}}</p>
        <p class="prePlan-p1">CONTENT</p>
        <div class="prePlan-list1-box">
          <div class="prePlan-list1-inner">
            <p class="list1-inner-left">01</p>
            <div class="list1-inner-right">
              <p class="list1-inner-p1">{{$t("home.home-69")}}</p>
              <p class="list1-inner-p2">{{$t("home.home-70")}}</p>
            </div>
          </div>
          <div class="prePlan-list1-inner">
            <p class="list1-inner-left">02</p>
            <div class="list1-inner-right">
              <p class="list1-inner-p1">{{$t("home.home-71")}}</p>
              <p class="list1-inner-p2">{{$t("home.home-72")}}</p>
            </div>
          </div>
          <div class="prePlan-list1-inner">
            <p class="list1-inner-left">03</p>
            <div class="list1-inner-right">
              <p class="list1-inner-p1">{{$t("home.home-73")}}</p>
              <p class="list1-inner-p2">{{$t("home.home-74")}}</p>
            </div>
          </div>
          <div class="prePlan-list1-inner">
            <p class="list1-inner-left">04</p>
            <div class="list1-inner-right">
              <p class="list1-inner-p1">{{$t("home.home-75")}}</p>
              <p class="list1-inner-p2">{{$t("home.home-76")}}</p>
            </div>
          </div>
          <div class="prePlan-list1-inner">
            <p class="list1-inner-left">05</p>
            <div class="list1-inner-right">
              <p class="list1-inner-p1">{{$t("home.home-77")}}</p>
              <p class="list1-inner-p2">{{$t("home.home-78")}}</p>
            </div>
          </div>
          <div class="prePlan-list1-inner">
            <p class="list1-inner-left">06</p>
            <div class="list1-inner-right">
              <p class="list1-inner-p1">{{$t("home.home-79")}}</p>
              <p class="list1-inner-p2">{{$t("home.home-80")}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="prePlan-box3">
        <img class="prePlan-box3-img" v-if="Language == 1" src="../../assets/img/quette/4.png" alt="">
        <img class="prePlan-box3-img" v-if="Language == 3" src="../../assets/img/quette/7.png" alt="">
        <img class="prePlan-box3-img" v-if="Language == 2" src="../../assets/img/quette/8.png" alt="">
      </div>

      <div class="prePlan-box4">
        <img class="prePlan-box4-img" src="../../assets/img/quette/111.png" alt="">
        <div class="prePlan-box4-inner">
          <p class="prePlan-box4-p1">{{$t("home.home-81")}}</p>
          <p class="prePlan-box4-p2">{{$t("home.home-82")}}<br />{{$t("home.home-83")}}</p>
          <!-- <p class="prePlan-box4-p3">立即咨询</p> -->
        </div>
      </div>

      <!-- 视频播放 -->
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose">
        <video v-if="dialogVisible" class="video-style" style="width: 1000px;" src="../../assets/img/quette/video.mp4" controls autoplay></video>
      </el-dialog>


    </div>

    <div class="mobile-prePlan" v-if="terminal == 'Mobile'">
      <img class="mobile-prePlan-video" @click="dialogVisible = true" src="../../assets/img/quette/video-img.png" alt="">
      <div class="mobile-prePlan-intro">
        <p class="mobile-prePlan-title">{{$t("home.home-51")}}</p>
        <p class="mobile-prePlan-p1">INTRODUCTION</p>
        <p class="mobile-prePlan-p2">{{$t("home.home-52")}}</p>
        <p class="mobile-prePlan-p2">{{$t("home.home-53")}}</p>
        <p class="mobile-prePlan-p2">{{$t("home.home-54")}}</p>
        <p class="mobile-prePlan-title" style="margin-top: 30px;">{{$t("home.home-55")}}</p>
        <p class="mobile-prePlan-p1">FEATURES</p>
      </div>
      <div class="mobile-prePlan-list">
        <div class="mobile-prePlan-list-inner">
          <div class="mobile-inner-img">
            <img class="mobile-inner-img-1" src="../../assets/img/quette/b1.png" alt="">
          </div>
          <div class="mobile-inner-show">
            <img class="mobile-inner-show-icon" src="../../assets/img/quette/a1.png" alt="">
            <p class="mobile-inner-show-p1">{{$t("home.home-56")}}</p>
            <p class="mobile-inner-show-p2">{{$t("home.home-57")}}</p>
          </div>
        </div>
        <div class="mobile-prePlan-list-inner">
          <div class="mobile-inner-img">
            <img class="mobile-inner-img-1" src="../../assets/img/quette/b2.png" alt="">
          </div>
          <div class="mobile-inner-show">
            <img class="mobile-inner-show-icon" src="../../assets/img/quette/a2.png" alt="">
            <p class="mobile-inner-show-p1">{{$t("home.home-58")}}</p>
            <p class="mobile-inner-show-p2">{{$t("home.home-59")}}</p>
          </div>
        </div>
        <div class="mobile-prePlan-list-inner">
          <div class="mobile-inner-img">
            <img class="mobile-inner-img-1" src="../../assets/img/quette/b3.png" alt="">
          </div>
          <div class="mobile-inner-show">
            <img class="mobile-inner-show-icon" src="../../assets/img/quette/a3.png" alt="">
            <p class="mobile-inner-show-p1">{{$t("home.home-60")}}</p>
            <p class="mobile-inner-show-p2">{{$t("home.home-61")}}</p>
          </div>
        </div>
        <div class="mobile-prePlan-list-inner">
          <div class="mobile-inner-img">
            <img class="mobile-inner-img-1" src="../../assets/img/quette/b4.png" alt="">
          </div>
          <div class="mobile-inner-show">
            <img class="mobile-inner-show-icon" src="../../assets/img/quette/a4.png" alt="">
            <p class="mobile-inner-show-p1">{{$t("home.home-62")}}</p>
            <p class="mobile-inner-show-p2">{{$t("home.home-63")}}</p>
          </div>
        </div>
        <div class="mobile-prePlan-list-inner">
          <div class="mobile-inner-img">
            <img class="mobile-inner-img-1" src="../../assets/img/quette/b5.png" alt="">
          </div>
          <div class="mobile-inner-show">
            <img class="mobile-inner-show-icon" src="../../assets/img/quette/a5.png" alt="">
            <p class="mobile-inner-show-p1">{{$t("home.home-64")}}</p>
            <p class="mobile-inner-show-p2">{{$t("home.home-65")}}</p>
          </div>
        </div>
        <div class="mobile-prePlan-list-inner">
          <div class="mobile-inner-img">
            <img class="mobile-inner-img-1" src="../../assets/img/quette/b6.png" alt="">
          </div>
          <div class="mobile-inner-show">
            <img class="mobile-inner-show-icon" src="../../assets/img/quette/a6.png" alt="">
            <p class="mobile-inner-show-p1">{{$t("home.home-66")}}</p>
            <p class="mobile-inner-show-p2">{{$t("home.home-67")}}</p>
          </div>
        </div>
      </div>

      <div class="mobile-prePlan-list1">
        <p class="mobile-prePlan-title" style="margin-top: 42px;">{{$t("home.home-68")}}</p>
        <p class="mobile-prePlan-p1">CONTENT</p>
        <div class="mobile-prePlan-list1-box">
          <div class="mobile-prePlan-list1-inner">
            <p class="mobile-list1-inner-left">01</p>
            <div class="mobile-list1-inner-right">
              <p class="mobile-list1-inner-p1">{{$t("home.home-69")}}</p>
              <p class="mobile-list1-inner-p2">{{$t("home.home-70")}}</p>
            </div>
          </div>
          <div class="mobile-prePlan-list1-inner">
            <p class="mobile-list1-inner-left">02</p>
            <div class="mobile-list1-inner-right">
              <p class="mobile-list1-inner-p1">{{$t("home.home-71")}}</p>
              <p class="mobile-list1-inner-p2">{{$t("home.home-72")}}</p>
            </div>
          </div>
          <div class="mobile-prePlan-list1-inner">
            <p class="mobile-list1-inner-left">03</p>
            <div class="mobile-list1-inner-right">
              <p class="mobile-list1-inner-p1">{{$t("home.home-73")}}</p>
              <p class="mobile-list1-inner-p2">{{$t("home.home-74")}}</p>
            </div>
          </div>
          <div class="mobile-prePlan-list1-inner">
            <p class="mobile-list1-inner-left">04</p>
            <div class="mobile-list1-inner-right">
              <p class="mobile-list1-inner-p1">{{$t("home.home-75")}}</p>
              <p class="mobile-list1-inner-p2">{{$t("home.home-76")}}</p>
            </div>
          </div>
          <div class="mobile-prePlan-list1-inner">
            <p class="mobile-list1-inner-left">05</p>
            <div class="mobile-list1-inner-right">
              <p class="mobile-list1-inner-p1">{{$t("home.home-77")}}</p>
              <p class="mobile-list1-inner-p2">{{$t("home.home-78")}}</p>
            </div>
          </div>
          <div class="mobile-prePlan-list1-inner">
            <p class="mobile-list1-inner-left">06</p>
            <div class="mobile-list1-inner-right">
              <p class="mobile-list1-inner-p1">{{$t("home.home-79")}}</p>
              <p class="mobile-list1-inner-p2">{{$t("home.home-80")}}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-prePlan-box3">
        <img class="mobile-prePlan-box3-img" v-if="Language == 1" src="../../assets/img/quette/4.png" alt="">
        <img class="mobile-prePlan-box3-img" v-if="Language == 3" src="../../assets/img/quette/7.png" alt="">
        <img class="mobile-prePlan-box3-img" v-if="Language == 2" src="../../assets/img/quette/8.png" alt="">
      </div>

      <div class="mobile-prePlan-box4">
        <img class="mobile-prePlan-box4-img" src="../../assets/img/quette/111.png" alt="">
        <div class="mobile-prePlan-box4-inner">
          <p class="mobile-prePlan-box4-p1">{{$t("home.home-81")}}</p>
          <p class="mobile-prePlan-box4-p2">{{$t("home.home-82")}}<br />{{$t("home.home-83")}}</p>
          <!-- <p class="mobile-prePlan-box4-p3">立即咨询</p> -->
        </div>
      </div>

      <!-- 视频播放 -->
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="94%"
        style="padding-bottom: 30px;"
        :before-close="handleClose">
        <video v-if="dialogVisible" class="video-style" style="width: 100%;" src="../../assets/img/quette/video.mp4" controls autoplay></video>
      </el-dialog>


    </div>
  </div>
  
</template>

<script>

export default {
  name: 'HomeView',
  props:{
    plateId:{
          type: Number,
          default:"这是默认值"
      }
  },
  data(){
    return{
      detail: {},
      terminal: 'PC',
      title: '福寿康宁 生前契约',
      dialogVisible: false,
      Language: localStorage.getItem('Language') || 1
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
  },
  methods:{
    handleClose(){
      this.dialogVisible = false
    },
    getInfo(){
      
    }
  }
}
</script>

