<template>
    <div class="home">
    <Header></Header>
    <div v-if="terminal == 'PC'">
      <div class="news-banner"></div>
      <!-- <div class="news-detail" style="padding-top: 0">
        <p class="detail-title" style="text-align: center;">{{detail.Title}}</p>
        <p class="detail-desc">{{ detail.briefIntroduction }}</p>
        <img class="detail-img" :src="imgBaseUrl + detail.ImgUrl" alt="">
        <div class="detail-content" v-html="detail.Content"></div>
      </div> -->
      <div class="news-detail" style="padding-top: 0;position: relative;top: -50px;">
        <p class="detail-title">{{detail.Title}}</p>
        <p class="detail-info">
          <span class="detail-time" v-if="detail.Releasetime">{{detail.Releasetime.substring(0,11)}}</span>
          <span class="detail-from">{{detail.NewsSources}}</span>
          <!-- <span class="detail-num"><img class="see-icon" src="../assets/img/see.png" alt="">{{detail.Readingvolume}}</span> -->
        </p>
        <div class="detail-content" v-html="detail.Content"></div>
      </div>
    </div>
    <div v-if="terminal == 'Mobile'">
      <div class="mobile-search-banner">
        <img class="banner-img" src="../assets/img/news/news-appbg1.png" alt="">
      </div>
      <!-- <p class="mobile-go-back" @click="goBack">  《 {{$t("home.home-21")}}</p> -->
      <div class="mobile-news-detail" style="padding-top: 0">
        <p class="mobile-detail-title" style="text-align: center;">{{detail.Title}}</p>
        <p  style="text-align: center;  padding-top: 10px; padding-bottom: 10px; ">

              <span class="detail-time" v-if="detail.Releasetime">{{detail.Releasetime.substring(0,11)}}</span>
        </p>
      <!--   <p class="mobile-detail-desc">{{ detail.briefIntroduction }}</p>
        <img class="mobile-detail-img" style="width: 100%;margin-top: 20px" :src="imgBaseUrl + detail.ImgUrl" alt=""> -->
        <div class="mobile-detail-content" v-html="detail.Content"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  },
  props:{
    // plateId:{
    //       type: Number,
    //       default:"这是默认值"
    //   }
  },
  data(){
    return{
      detail: {},
      imgBaseUrl: '',
      terminal: 'PC',
      id: this.$route.query.id,
      type: this.$route.query.type
    }
  },
  mounted(){
    window.scrollTo(0,0);
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    if(this.id){
      if(this.type == 1||this.type == 2){
        this.getInfo()
      }else if(this.type == 3){
        this.getInfo2()
      }else if(this.type == 4){
        this.getInfo3()
      }
      
    }
    // this.detail = JSON.parse(localStorage.getItem('searchInfo1')) 
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
    getInfo(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_News/GetPageDataV2",{para: that.id }).then(
        (res) => {
          //console.log(res)
          that.detail = res.data.rows.rows[0]
          console.log(that.detail)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    getInfo2(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_MediaFocus/GetPageDataV2",{para: that.id }).then(
        (res) => {
          console.log(res)
          that.detail = res.data.rows.rows[0]
          console.log(that.detail)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    getInfo3(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_PublicGood/GetPageDataV2",{para: that.id }).then(
        (res) => {
          console.log(res)
          that.detail = res.data.rows.rows[0]
          console.log(that.detail)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

