<template>
    <p class="number-grow-warp">
      <span ref="numberGrow" :data-time="time" class="number-grow" :data-value="value" :data-dot="dot">0</span>
    </p>
    </template>
    <script> 
    export default {
      props: {
        time: {
          type: Number,
          default: 2
        },
        value: {
          type: Number,
          default: 100
        },
        dot: {
            type: Number,
            default: 0
        }
      },
      created(){
        window.addEventListener('scroll', this.windowScrollListener);
      },
      methods: {
        numberGrow (ele) {
          // console.log(111)
          let _this = this
          let step = (_this.value * 10) / (_this.time * 600)
          let current = 0
          let start = 0
          let t = setInterval(function () {
            start += step
            if (start > _this.value) {
              clearInterval(t)
              start = _this.value
              t = null
            }
            if (current === start) {
              return
            }
            current = start
            ele.innerHTML = current.toFixed(_this.dot)
            // ele.innerHTML = current.toString().replace(/(d)(?=(?:d{3}[+]?)+$)/g, '$1,')
          }, 10)
        },
        windowScrollListener() {
          //获取操作元素最顶端到页面顶端的垂直距离
          var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
          if (scrollTop >= 1000 && scrollTop <= 1200) {
            this.numberGrow(this.$refs.numberGrow)
          }
        }
      },
      mounted () {
        
      }
    } 
</script>
<style> 
    
</style>