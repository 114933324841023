<template>
  <div class="home">
    
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data(){
    return{
      
    }
  },
  mounted(){
  },
  methods:{
    
  }
}
</script>

