module.exports = {
    head: {
        'head-1' : '首頁',
        'head-2' : '集團概覽',
        'head-3' : '集團簡介',
        'head-4' : '文化理念',
        'head-5' : '品牌商標',
        'head-6' : '管理團隊',
        'head-7' : '發展歷程',
        'head-8' : '榮譽資質',
        'head-9' : '文宣視頻',
        'head-10' : '新聞中心',
        'head-11' : '集團要聞',
        'head-12' : '各地動態',
        'head-13' : '媒體聚焦',
        'head-14' : '企業刊物閱讀',
        'head-15' : '集團板塊',
        'head-16' : '社會責任',
        'head-17' : '集團公益',
        'head-18' : '可持續發展報告',
        'head-19' : '業務合作',
        'head-20' : '合作模式',
        'head-21' : '聯繫我們',
        'head-22' : '投資者關係',
        'head-23' : '證券資訊',
        'head-24' : '公司公告',
        'head-25' : '公司治理',
        'head-26' : '股東大會',
        'head-27' : '定期報告',
        'head-28' : '公司演示',
        'head-29' : '投資者査詢',
        'head-30' : '簡體',
        'head-31' : '繁體',
        'head-32' : '英文',
    },
    foot:{
        'foot-1' : '友情連結',
        'foot-2' : '集團官方諮詢熱線',
        'foot-3' : '滬ICP備05016662號-5',
        'foot-4' : '滬公網安備31010402008532號',
        'foot-5' : '就業機會',
        'foot-6' : '法律聲明',
    },
    home:{
        'home-1' : '年度',
        'home-2' : '請輸入內容',
        'home-3' : '《福壽園》報',
        'home-4' : '《福壽人文》雜誌',
        'home-5' : '返回集團要聞清單',
        'see-more' : '查看更多',
        'no-data' : '沒有數據',
        'download' : '查看下載',
        'home-6' : '返回各地動態清單',
        'home-7' : '返回媒體聚焦清單',
        'home-8' : '返回集團公益清單',
        'home-9' : '請輸入關鍵字査詢',
        'home-10' : '搜索結果',
        'home-11' : '很抱歉，沒有査詢到相關結果，請換個關鍵字査詢',
        'see-detail' : '查看詳情',
        'home-12' : '共找到關於',
        'home-13' : '的結果共',
        'home-14' : '條',
        'home-15' : '發佈者',
        'home-16' : '電話',
        'home-17' : '地址',
        'home-18' : '就業機會',
        'home-19' : '返回',
        'home-20' : '清單',
        'home-21' : '返回蒐索清單',
        'home-22' : '敬請期待',
        'home-23' : '工作地點',
        'home-24' : '年薪',
        'home-25' : '都市佈局',
        'home-26' : '實體運營',
        'home-27' : '公墓運營-產業地圖',
        'home-28' : '殯儀服務-產業地圖',  
        'home-29' : '截至香港時間',  
        'home-30' : '港交所上市企業',  
        'home-31' : '集團榮譽',  
        'home-32' : '集團資質',  
        'home-33' : '股票代碼',  
        'home-34' : '香港聯合交易所',  
        'home-35' : '分時K線',  
        'home-36' : '日K線圖',  
        'home-37' : '周K線圖',  
        'home-38' : '月K線圖', 
        'home-39' : '年', 
        'home-40' : '尋找溫情與力量', 
        'home-41' : '以人為本 值得託付', 
        'home-42' : '研發設計自主生產', 
        'home-43' : '突破創新特色', 
        'home-44' : '讓愛永久傳承', 
        'home-45' : '領先的互聯網', 
        'home-46' : '生命科學服務平臺', 
        'home-47' : '孕育激發改變', 
        'home-48' : '福壽園官網-生命服務平臺', 
        'home-49' : '董事委員會', 
        'home-50' : '感知生命 綻放人生', 

        'home-51' : '生前契約簡介',  
        'home-52' : '“生前契約”是當事人生前就自己或親人逝世後如何開展殯葬禮儀服務，而與殯葬服務公司訂立的合約。 它的概念源自英國的遺囑信託，20世紀初引入美國，發展成為以消費者為導向的專業服務行業。 二戰後日本將美國成熟的“生前契約”制度導入日本並迅速普及，在歐美，生前契約已有60多年的歷史，日本生前契約的普及率早已超過70%，在美國更高達90%以上。',  
        'home-53' : '其服務內容包括：臨終關懷、遺體接送、靈堂搭建、告別儀式及法律諮詢等相關後續服務。',  
        'home-54' : '2015年，福壽園國際集團正式將“生前契約”引入中國殯葬行業，將傳統文化與現代服務理念相結合，旨在打造更加規範的行業準則及更完善的服務系統，引領中國現代殯葬行業的發展與變革。 自服務推出以來，每年簽訂契約的人數都在以近倍的速度增長，上海、江蘇、重慶、安徽、山東、江西、遼寧、河南、福建等地，越來越多的人開始接受並認同提前規劃的概念，現時累計簽訂人數超萬人。',  
        'home-55' : '生前契約特色',  
        'home-56' : '抵禦通脹',  
        'home-57' : '安心省心',  
        'home-58' : '上市公司', 
        'home-59' : '品質保證', 
        'home-60' : '提前規劃', 
        'home-61' : '滿足個人意願', 
        'home-62' : '個性化方案', 
        'home-63' : '服務貼心', 
        'home-64' : '價格透明', 
        'home-65' : '避免非理性消費', 
        'home-66' : '親人朋友', 
        'home-67' : '減輕負擔', 
        'home-68' : '生前契約包含哪些內容', 
        'home-69' : '24小時白事服務熱線', 
        'home-70' : '任何時間，任何地點，撥打電話白事管家立刻來到身邊', 

        'home-71' : '全程引導', 
        'home-72' : '白事管家全程引導治喪，安排接運，告知手續辦理流程及資料。', 
        'home-73' : '靈堂佈置', 
        'home-74' : '根據逝者要求，安排靈堂佈置，引導家屬守靈，提供哀傷撫慰支持。', 
        'home-75' : '告別儀式策劃', 
        'home-76' : '安排告別儀式，引導親屬好友送別，全程專業服務。', 
        'home-77' : '法律諮詢', 
        'home-78' : '提供遺產繼承相關法律諮詢服務', 
        'home-79' : '落葬服務', 
        'home-80' : '提供落葬指導服務', 

        'home-81' : '福壽康寧 生前契約', 
        'home-82' : '用自己的管道告別', 
        'home-83' : '這一刻的選擇是你餘生的從容',
        'home-84' : '以後',
        'home-85' : '敬請期待',
        'home-86' : '姓名',
        'home-87' : '年齡',
        'home-88' : '職位',
        'home-89' : '董事委任日期',
        'home-90' : '職務及職責',
        'home-91' : '公墓名稱',
        'home-92' : '公司名稱',

        'home-100' : '公墓運營',
        'home-101' : '設計規劃',
        'home-102' : '致敬生命，賦予一個行業生命的溫度',
        'home-103' : '效法自然，打造詩意盎然的棲居',
        'home-104' : '殯儀服務',
        'home-105' : '生前事業',
        'home-106' : '專屬定制，讓告別變得美麗',
        'home-107' : '福壽康寧，餘生的從容',
        'home-108' : '設備製造',
        'home-109' : '殯葬互聯網+',
        'home-110' : '創新環保，呵護未來綠色之舟',
        'home-111' : '雲端互聯，拓展生命服務新維度',
        'home-112' : '追思用品',
        'home-113' : '生命教育',
        'home-114' : '思念何所寄，福壽長相憶',
        'home-115' : '文化創智，用教育連結歷史與未來',
        'home-116' : '獨資開發項目',
        'home-117' : '成立以來，通過自建方式，利用自有資金不斷拓展生命服務事業。',
        'home-118' : '港股',
        'home-119' : '股票實时',
        'home-120' : '當前',
        'home-121' : '最高',
        'home-122' : '最低',
        'home-123' : '今開',
        'home-124' : '昨收',
    }
}