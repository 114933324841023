<template>
  <div>
    <Header></Header>
    <div class="news-banner"></div>
    <div class="news-detail" style="padding-top: 0">
      <p class="detail-title" style="text-align: center;">{{detail.CemeteryName}}</p>
      <p class="detail-desc">{{ detail.BriefIntroduction }}</p>

      <img class="detail-img" :src="imgBaseUrl + detail.ImgUrl" alt="">
      <div class="detail-content" v-html="detail.CemeteryIntroduction"></div>
    </div>
    <Footer></Footer>
  </div>
  
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  },
  props:{
    // plateId:{
    //       type: Number,
    //       default:"这是默认值"
    //   }
  },
  data(){
    return{
      detail: {},
      imgBaseUrl: ''
    }
  },
  mounted(){
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.detail = JSON.parse(localStorage.getItem('detailInfo')) 
    console.log(this.detail)
    // this.getInfo()
  },
  methods:{
    getInfo(){
      let that = this
      that.$axios.post(this.baseURL + "api/Web_GroupSegment/GetPageDataV2",{para: that.plateId }).then(
        (res) => {
          console.log(res)
          that.detail = res.data.rows[0]
          console.log(that.detail)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

