<template>
  <div>
    <div class="h-footer1" v-if="terminal == 'PC'">
      <div class="foot-top">
        <div class="foot-content">
          <div class="top-left">
            <img class="foot-logo" v-if="Language == 1" src="../assets/img/logo1.png" alt="">
            <img class="foot-logo" v-if="Language == 2" src="../assets/img/logo2.png" alt="">
            <img class="foot-logo" v-if="Language == 3" src="../assets/img/logo3.png" alt="">
             <!-- <div class="foot-logowz">{{$t("home.home-50")}} </div> -->
            <div class="link-box">
              <p class="link-title">{{$t("foot.foot-1")}}</p>
              <div class="link-inner">
                <p class="link-p"  v-for="(item,index) in linkList" :key="index" @click="toLink(item)">{{ item.Title }}</p>
              </div>
            </div>
          </div>
          <div class="top-center">
            <div class="center-inner" v-for="(item,index) in EWMList" :key="index">
              <img class="img-img" :src="imgBaseUrl + item.ImgUrl" alt="">
              <p class="img-p">{{item.Title}}</p>
            </div>
          </div>
          <div class="top-right">
            <p class="phone-title">
              <img class="phone-icon" src="../assets/img/foot/phone.png" alt="">
              <span>{{$t("foot.foot-2")}}</span>
            </p>
            <p class="phone-number">{{ phone }}</p>
          </div>
        </div>
      </div> 
      <div class="foot-bottom">
        <div class="foot-content">
          <div class="bottom-left">
            <p class="foot-copy">CopyRight @ 2021 Fu Shou Yuan International Group Limited,All Rights Reserved.</p>
            <p class="foot-copy" @click="toBeian(1)">{{$t("foot.foot-3")}}</p>
            <p class="foot-copy" @click="toBeian(2)">{{$t("foot.foot-4")}}</p>
          </div>
          <div class="bottom-right">
            <p class="link-p" @click="toUrl('jobDetail')">{{$t("foot.foot-5")}}</p>
            <p class="link-p" @click="toUrl('lawDetail')">{{$t("foot.foot-6")}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-footer" v-if="terminal == 'Mobile'">
      <div class="mobile-footer-box1">
           <img class="mobile-footer-box1-logo" v-if="Language == 1" src="../assets/img/logo1.png" alt="">
            <img class="mobile-footer-box1-logo" v-if="Language == 2" src="../assets/img/logo2.png" alt="">
            <img class="mobile-footer-box1-logo" v-if="Language == 3" src="../assets/img/logo3.png" alt="">
        <p class="mobile-footer-box1-p">
          <img class="mobile-footer-box1-icon" src="../assets/img/foot/phone.png" alt="">
          <span class="mobile-footer-box1-phone">{{ phone }}</span>
        </p>
      </div>
      <div class="mobile-ewm-box">
        <div class="mobile-ewm-box-inner" v-for="(item,index) in EWMList" :key="index">
          <img class="mobile-ewm-box-img" :src="imgBaseUrl + item.ImgUrl" alt="">
          <p class="mobile-ewm-box-p">{{item.Title}}</p>
        </div>
      </div>
      <div class="mobile-foot-link">
        <p class="link-title">{{$t("foot.foot-1")}}</p>
        <p class="foot-br"></p>
        <div class="mobile-foot-link-box">
          <p class="mobile-foot-link-inner" v-for="(item,index) in linkList" :key="index" @click="toLink(item)">{{ item.Title }}</p>
        </div>
      </div>
      <div class="mobile-copy">
        <div class="mobile-copy-box1">
          <p class="mobile-copy-p1" @click="toUrl('jobDetail')">{{$t("foot.foot-5")}}</p>
          <p class="mobile-copy-p1" @click="toUrl('lawDetail')">{{$t("foot.foot-6")}}</p>
        </div>
        <p class="mobile-copy-p2" @click="toBeian(1)">{{$t("foot.foot-3")}}</p>
        <p class="mobile-copy-p2" @click="toBeian(2)">{{$t("foot.foot-4")}}</p>
        <p class="mobile-copy-p2">CopyRight @ 2021 Fu Shou Yuan International Group Limited,All Rights Reserved.</p>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'Footer-page',
  props: {
    msg: String
  },
  data(){
    return{
      linkList: [],
      EWMList: [],
      imgBaseUrl: '',
      phone: '',
      terminal: 'PC',
      Language: 1
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    // this.getEWM()
    this.getLink()
    // this.getPhone()

 this.Language= localStorage.getItem('Language')
if(!localStorage.getItem('Language')){

         this.Language=1;
}
  },
  methods: {
    toUrl(url){
      this.$router.push({name: url})
    },
    // 友情链接
    getLink(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV1",params).then(
        (res) => {
          // that.linkList = res.data.rows
          // console.log(that.linkList)
          that.phone = res.data.msg
          that.linkList = res.data.rowsv1
          that.EWMList = res.data.rows
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    // 微信二维码
    getEWM(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV4",params).then(
        (res) => {
          that.EWMList = res.data.rows
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    // 电话
    getPhone(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV1",params).then(
        (res) => {
          that.phone = res.data.rows[0].ValueContent
          console.log(that.phone)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    toLink(item){
      window.open(item.ValueContent,'_blank');
      // location.href = item.ValueContent
    },
    toBeian(index){
      if(index == 1){
        window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank');
      }else if(index == 2){
        window.open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402008532','_blank');
      }
    }
  }
}
</script>
<style scoped>
.h-footer1{
  /* background: url('../assets/img/foot/footer.png') no-repeat center center;
  background-size: 100% 100%; */
  background: rgba(244, 244, 244, 0.75);
 }
 .mobile-footer{
  /* background: url('../assets/img/mobile/foot-bg.png') no-repeat center center;
  background-size: 100% 100%; */
  background: rgba(244, 244, 244, 0.75);
 }
</style>
