<template>
  <div>
    <Header></Header>
    <div v-if="terminal == 'PC'">
      <div class="news-banner"></div>
      <div class="news-detail" style="padding-top: 0">
        <p class="detail-title" style="text-align: center;">{{detail.Title}}</p>
        <p class="detail-desc">{{ detail.briefIntroduction }}</p>

        <img class="detail-img" :src="imgBaseUrl + detail.ImgUrl" alt="">
        <div class="detail-content" v-html="detail.Content"></div>
      </div>
    </div>
    <div v-if="terminal == 'Mobile'">
      <div class="mobile-news-banner" ></div>
      <p class="mobile-go-back" @click="goBack">  《 {{$t("home.home-19")}}</p>
      <div class="mobile-news-detail" style="padding-top: 0">
        <p class="mobile-detail-title" style="text-align: center;">{{detail.Title}}</p>
        <p class="mobile-detail-desc">{{ detail.briefIntroduction }}</p>
        <img class="mobile-detail-img" style="width: 100%;margin-top: 20px;" :src="imgBaseUrl + detail.ImgUrl" alt="">
        <div class="mobile-detail-content" v-html="detail.Content"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  },
  props:{
    // plateId:{
    //       type: Number,
    //       default:"这是默认值"
    //   }
  },
  data(){
    return{
      detail: {},
      imgBaseUrl: '',
      terminal: 'PC',
    }
  },
  mounted(){
    window.scrollTo(0,0);
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.detail = JSON.parse(localStorage.getItem('allDetails')) 
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

