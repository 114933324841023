<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-2")}} > </span> <span class="top-menu-left-span2" > {{$t("head.head-6")}}</span> 
        </div>
      </div>
      <div class="introduce-box">
        <div class="introduce-left" >
          <div class="introduce-tab">
            <p class="tab-p" v-for="(item,index) in typeList" :key="index" :class="{'activeTab': tab == index}" @click="changeTab(index,item)">{{item.Name}}</p>
          </div>
          <div class="introduce-img-box">
              <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
            </div>
            <div class="left-bottom">
              <p class="introduce-left-p1">01448.HK</p>
              <p class="introduce-left-p2">{{ codeNumber }}</p>
              <p class="introduce-left-p3">HKD</p>
              <p class="introduce-left-p4">{{$t("home.home-29")}}  {{ timeInfo }}</p>
              <p class="introduce-left-p4">{{$t("home.home-30")}}</p>
            </div>
        </div>
        <div class="introduce-right" v-if="tabtype == 'others'">
          <div class="table-style">
            <el-table
              v-if="teamId == '53'||teamId == '72'||teamId == '77'"
              :data="list"
              :header-cell-style="{background: '#efefef'}"
              style="width: 100%">
              <el-table-column prop="Name" align="center" :label="$t('home.home-86')"> </el-table-column>
              <el-table-column prop="age" align="center"  :label="$t('home.home-87')" width="100"> </el-table-column>
              <el-table-column prop="Position" align="center" :label="$t('home.home-88')" > </el-table-column>
              <el-table-column prop="Dateofappointment" align="center" :label="$t('home.home-89')" > 
                <template slot-scope="scope">
                  <span v-if="scope.row.Dateofappointment">{{ scope.row.Dateofappointment.slice(0,11) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="Responsibilities" align="center" :label="$t('home.home-90')"> </el-table-column>
            </el-table>
            <el-table
                v-if="teamId == '54'||teamId == '70'||teamId == '73'"
              :data="list"
              :header-cell-style="{background: '#efefef'}"
              style="width: 100%">
              <el-table-column prop="Name" align="center" :label="$t('home.home-86')" width="150"> </el-table-column>
              <el-table-column prop="age" align="center" :label="$t('home.home-87')" width="100"> </el-table-column>
              <el-table-column prop="Position" align="center" :label="$t('home.home-88')"> </el-table-column>
            </el-table>
          </div>
          <div class="team-list" v-for="(item,index) in list" :key="index" >
            <div class="team-list-inner" >
              <div class="team-left">
                <img class="team-left-img" :src="imgBaseUrl + item.Photo" alt="">
              </div>
              <div class="team-right">
                <p class="team-p1">{{ item.Name }}</p>
                <p class="team-p2">{{ item.Position }}</p>
                <p class="team-p3">{{ item.PersonalProfile }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="introduce-right" v-if="tabtype == 'weiyuan'">
          <div class="team-list" v-for="(item,index) in list1" :key="index" style="padding: 20px 0">
            <div class="team-list-inner" >
              <div class="team-right">
                <p class="team-p1">{{ item.Name }}</p>
                <p class="team-p3" v-html="item.Briefintroduction"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-home" style="padding-top: 30px" v-if="terminal == 'Mobile'">
      <div class="mobile-introduce-tab">
        <p class="mobile-tab-p" v-for="(item,index) in typeList" :key="index" :class="{'activeTab': tab == index}" @click="changeTab(index,item)">{{item.Name}}</p>
      </div>
      <div class="mobile-introduce-right" v-if="tabtype == 'others'">
        <div class="table-style">
            <el-table
               v-if="teamId == '53'||teamId == '72'||teamId == '77'"
              :data="list"
              :header-cell-style="{background: '#efefef'}"
              style="width: 100%">
              <el-table-column prop="Name" align="center" :label="$t('home.home-86')" width="100" > </el-table-column>
              <el-table-column prop="age" align="center" :label="$t('home.home-87')" width="50"> </el-table-column>
              <el-table-column prop="Position" align="center" :label="$t('home.home-88')" width="120"> </el-table-column>
              <el-table-column prop="Dateofappointment" align="center" :label="$t('home.home-89')" width="120"> 
                <template slot-scope="scope">
                  <span v-if="scope.row.Dateofappointment">{{ scope.row.Dateofappointment.slice(0,11) }}</span>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="Responsibilities" align="center" :label="$t('home.home-90')"> </el-table-column>
            </el-table>
            <el-table
              v-if="teamId == '54'||teamId == '70'||teamId == '73'"
              :data="list"
              :header-cell-style="{background: '#efefef'}"
              style="width: 100%">
              <el-table-column prop="Name" align="center" :label="$t('home.home-86')" width="80"> </el-table-column>
              <el-table-column prop="age" align="center" :label="$t('home.home-87')" width="80"> </el-table-column>
              <el-table-column prop="Position" align="center" :label="$t('home.home-88')" > </el-table-column>
            </el-table>
          </div>

        <div class="mobile-team-list" v-for="(item,index) in list" :key="index" >
          <div class="team-list-top" >
            <div class="team-left">
              <img class="team-left-img" :src="imgBaseUrl + item.Photo" alt="">
            </div>
            <div class="team-right">
              <p class="team-p1">{{ item.Name }}</p>
              <p class="team-p2">{{ item.Position }}</p>
              <p class="team-p2">{{ item.Responsibilities }}</p>
            </div>
          </div>
          <p class="team-bottom">{{ item.PersonalProfile }}</p>
        </div>
      </div>
      <div class="mobile-introduce-right" v-if="tabtype == 'weiyuan'">
        <div class="team-list" v-for="(item,index) in list1" :key="index" style="background: #fff;padding: 15px;box-sizing: border-box;border-bottom: none;margin-bottom: 15px;">
          <div class="team-list-inner" >
            <div class="team-right">
              <p class="team-p1">{{ item.Name }}</p>
              <p class="team-p3" v-html="item.Briefintroduction"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-introduce-box" style="width: 94%;margin: 0 auto;">
        <div class="mobile-introduce-left" >
          <img class="introduce-img" src="../../../assets/img/in-banner.png" alt="">
        </div>
        <div class="mobile-introduce-right">
          <p class="introduce-left-p1">01448.HK</p>
          <p class="introduce-left-p2">{{ codeNumber }}</p>
          <p class="introduce-left-p3">HKD</p>
          <p class="introduce-left-p4">{{$t("home.home-29")}}  {{ timeInfo }}</p>
          <p class="introduce-left-p4">{{$t("home.home-30")}}</p>
        </div>
      </div>
    </div>

  </div>
  
</template>

<script>
export default {
  name: 'noticePage',
  props:{
    codeNumber:{
      type: String,
      default: '0'
    },
    timeInfo: {
      type: String,
      default: '0'
    }
  },
  data(){
    return{
      type: 'list',
     list: [],
      list1: [],
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      typeList: [],
      tab: 0,
      teamId: '',
      tabtype: 'others',
      terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.getType()
  },
  methods:{
    changeTab(index,item){
      this.tab = index
      //console.log(item)
      if(item.title != 'committee'){
        this.teamId = item.ID
        this.tabtype = 'others'
        this.getList()
      }else{
        this.tabtype = 'weiyuan'
        this.getList1()
      }
    },
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    getType(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_Job/GetPageDataV1",params).then(
        (res) => {
          that.typeList = res.data.rows
          that.typeList.push({
            Name: this.$t("home.home-49"),
            title: 'committee'
          })
          if( that.typeList.length>0){
               that.teamId=that.typeList[0].ID;
          }
          that.getList()
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    getList(){
      let that = this
      let params = {
        page: this.currentPage,
        rows: 100,
        para: this.teamId,
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_Managementteam/GetPageDataV1",params).then(
        (res) => {
          that.list = res.data.rows
          var date = new Date();
          that.list.forEach((item,index) =>{
            if(item.birthday){
             
                var birthdays = new Date(item.birthday.replace(/-/g, "/"));
                item.age = date.getFullYear()-birthdays.getFullYear()-(date.getMonth() < birthdays.getMonth()||(date.getMonth() == birthdays.getMonth() && date.getDate() < birthdays.getDate()) ? 1: 0);

            
            }
          })
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    getList1(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_CommitteeManagement/GetPageDataV1",params).then(
        (res) => {
          that.list1 = res.data.rows
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
.table-style{
  margin-bottom: 30px;
}
</style>