module.exports = {
    head: {
        'head-1' : 'Home page',
        'head-2' : 'Group overview',
        'head-3' : 'Group profile',
        'head-4' : 'Cultural Idea',
        'head-5' : 'Brand mark',
        'head-6' : 'Team',
        'head-7' : 'Development',
        'head-8' : 'Honor',
        'head-9' : 'promotion vedio',
        'head-10' : 'Press center',
        'head-11' : 'Group news',
        'head-12' : 'Local dynamics',
        'head-13' : 'Media focus',
        'head-14' : 'Publication Reading',
        'head-15' : 'Group plate',
        'head-16' : 'responsibility',
        'head-17' : 'Public welfare',
        'head-18' : 'Report',
        'head-19' : 'Cooperation',
        'head-20' : 'Cooperative',
        'head-21' : 'Contact us',
        'head-22' : 'Investor',
        'head-23' : 'Securities',
        'head-24' : 'Announcement',
        'head-25' : 'Governance',
        'head-26' : 'Convention',
        'head-27' : 'Periodic report',
        'head-28' : 'Company Demo',
        'head-29' : 'Investor enquiry',
        'head-30' : 'Chinese',
        'head-31' : 'Traditional',
        'head-32' : 'English',
    },
    foot:{
        'foot-1' : 'Friendship link',
        'foot-2' : 'Group enquiry hotline',
        'foot-3' : 'Shanghai ICP 05016662-5',
        'foot-4' : 'Shanghai Public Network Anbei 31010402008532',
        'foot-5' : 'Recruitment',
        'foot-6' : 'Legal declaration',
    },
    home:{
        'home-1' : 'Annual',
        'home-2' : 'Please enter the content',
        'home-3' : 'Fu Shou Yuan Newspape',
        'home-4' : 'Fushou Humanities',
        'home-5' : 'Returns to the group highlights list',
        'see-more' : 'View more',
        'no-data' : 'No data',
        'download' : 'Download',
        'home-6' : 'Returns a dynamic list of places',
        'home-7' : 'The media focus list is displayed',
        'home-8' : 'Return to the group charity list',
        'home-9' : 'Please enter keyword query',
        'home-10' : 'Search result',
        'home-11' : 'Sorry, there is no relevant result, please change the keyword query',
        'see-detail' : 'View details',
        'home-12' : 'Find out about',
        'home-13' : 'Results of',
        'home-14' : 'Article',
        'home-15' : 'Publisher',
        'home-16' : 'Telephone',
        'home-17' : 'Address',
        'home-18' : 'Employment opportunity',
        'home-19' : 'Return',
        'home-20' : 'List',
        'home-21' : 'Return to search list',
        'home-22' : 'Please look forward to',
        'home-23' : 'Work place',
        'home-24' : 'Annual salary',
        'home-25' : 'City layout',
        'home-26' : 'Operation',
        'home-27' : 'Cemetery Operations - Industry Map',
        'home-28' : 'Funeral Services - Industry Map',  
        'home-29' : 'Hong Kong time',  
        'home-30' : 'Hong Kong Stock exchange listed companies',  
        'home-31' : 'Group honor',  
        'home-32' : 'Group qualification',  
        'home-33' : 'Stock code',  
        'home-34' : 'Stock Exchange of Hong Kong',  
        'home-35' : 'Minutes k',  
        'home-36' : 'Day k',  
        'home-37' : 'Cycle k',  
        'home-38' : 'Monthly k', 
        'home-39' : 'Years', 
        'home-40' : 'Find warmth and strength', 
        'home-41' : 'Putting people first is worth entrusting', 
        'home-42' : 'R & D design independent production', 
        'home-43' : 'Breakthrough and innovation features', 
        'home-44' : 'Let love last forever', 
        'home-45' : 'Leading Internet', 
        'home-46' : 'Life science service platform', 
        'home-47' : 'Gestation excitation change', 
        'home-48' : 'Fushouyuan official website-Life Service Platform', 
        'home-49' : 'committee',
        'home-50' : 'Perceive life  bloom life', 
        
        'home-51' : 'Brief introduction to living contract',  
        'home-52' : "Living contract is a contract between a person and a funeral service company on how to carry out funeral service after his or her relatives' death. Its concept, derived from the English testamentary trust, was introduced to the United States in the early 20th century and developed into a consumer-oriented professional service industry. After World War II, Japan introduced America's mature living contract system into Japan and popularized it rapidly. In Europe and the United States, living contract has a history of more than 60 years, and the penetration rate of living contract has already exceeded 70% in Japan and more than 90% in the United States.",  
        'home-53' : 'Its services include: hospice care, body transport, funeral building, farewell ceremony and legal advice and other related follow-up services.',  
        'home-54' : "In 2015, Fushouyuan International Group officially introduced the living contract into China's funeral industry, combining traditional culture with modern service concepts, aiming to create a more standardized industry guidelines and a more perfect service system, leading the development and reform of China's modern funeral industry. Since the launch of the service, the number of people signing contracts every year has increased by nearly twice the speed. In Shanghai, Jiangsu, Chongqing, Anhui, Shandong, Jiangxi, Liaoning, Henan, Fujian and other places, more and more people begin to accept and agree with the concept of advance planning. At present, the total number of people signing contracts exceeds 10,000.",  
        'home-55' : 'Characteristics of living contract',  
        'home-56' : 'Fight inflation',  
        'home-57' : "Ease one's mind",  
        'home-58' : 'Listed company', 
        'home-59' : 'Quality assurance', 
        'home-60' : 'Planning ahead', 
        'home-61' : "Satisfy one's will", 
        'home-62' : "Personalized scheme", 
        'home-63' : 'Considerate service', 
        'home-64' : 'Price transparency', 
        'home-65' : 'Avoid irrational consumption', 
        'home-66' : 'Relatives and friends', 
        'home-67' : 'Lighten the burden', 
        'home-68' : 'What a living contract contains', 
        'home-69' : '24 hours white matter service hotline', 
        'home-70' : 'Any time,any place,call the white housekeeper immediately to your side', 

        'home-71' : 'Whole-course guidance', 
        'home-72' : 'The white steward guides the funeral, arranges the transport, and informs the procedures and materials.', 
        'home-73' : 'Funeral hall arrangement', 
        'home-74' : 'Arrange the funeral hall according to the request of the deceased, guide the family members to wake and provide grief comfort', 
        'home-75' : 'Farewell ceremony planning', 
        'home-76' : 'Arranging the farewell ceremony and guiding the family send-off,professional service', 
        'home-77' : 'Legal advice', 
        'home-78' : 'To provide inheritance related legal advisory services', 
        'home-79' : 'Burial service', 
        'home-80' : 'Provide burial guidance service', 

        'home-81' : 'A living contract for longevity and corning', 
        'home-82' : 'Say goodbye in your own way', 
        'home-83' : 'Your choice at this moment is the rest of your life',
        'home-84' : 'After',
        'home-85' : 'Stay tuned',
        'home-86' : 'Name',
        'home-87' : 'Age',
        'home-88' : 'Position',
        'home-89' : 'Date of appointment',
        'home-90' : 'Duties and responsibilities',
        'home-91' : 'Name of cemetery',
        'home-92' : 'Company name',

        'home-100' : 'Cemetery Operations',
        'home-101' : 'Design Planning',
        'home-102' : 'Salute life, give an industry life temperature',
        'home-103' : 'Imitate nature to create a poetic dwelling',
        'home-104' : 'Funeral services',
        'home-105' : 'Career before',
        'home-106' : 'Exclusive customization, making farewell beautiful',
        'home-107' : 'Fu Shou Kang Ning,Rest of Life Calm',
        'home-108' : 'Equipment Manufacturing',
        'home-109' : 'Funeral Internet+',
        'home-110' : 'Innovative environmental protection, care for the future of the green boat',
        'home-111' : 'Cloud connected, expanding new dimensions of life services',
        'home-112' : 'Memorial Supplies',
        'home-113' : 'Life education',
        'home-114' : 'Miss what send, longevity looks like memory',
        'home-115' : 'Cultural creativity links history and future with education',
        'home-116' : 'Sole proprietorship development project',
        'home-117' : 'Since its establishment, it has been expanding life service business with its own funds through self-establishment.',
        'home-118' : 'Hong Kong stocks',
        'home-119' : 'Real time stock',
        'home-120' : 'Current',
        'home-121' : 'Highest',
        'home-122' : 'Minimum',
        'home-123' : 'Today Opening',
        'home-124' : 'Received Yesterday',
    }
}