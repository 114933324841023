<template>
  <div class="home">
    <Header></Header>
    <div style="padding-bottom: -100px;" v-if="terminal == 'PC'">
      <div class="home-banner">
        <el-carousel :interval="5000" arrow="always" indicator-position="inside" :autoplay="true" @change="changeBanner">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <div class="banner-inner" style="min-width: 1366px;" v-if="item.ImgUrl.substr(item.ImgUrl.length-3) != 'mp4'">
              <img class="banner-img"   :src="imgBaseUrl + item.ImgUrl" alt="" @click="BannertoModel(item)">
            </div>
            <video ref="videoPlayer"  :controls="false" :autoplay="true" muted v-if="item.ImgUrl.substr(item.ImgUrl.length-3) == 'mp4'">
                <source :src="imgBaseUrl + item.ImgUrl"  type="video/mp4">
                您的浏览器不支持Video标签。
            </video>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="news">
        <img class="flowers" src="../assets/img/flower.png" alt="">
        <div class="news-box">
          <div class="news-left">
            <div v-for="(item,index) in newsList" :key="index" @click="toDetail(item)">
              <div class="news-list" v-if="index == 0">
                <div class="list-left">
                  <p class="date-p1">{{ item.Releasetime.substring(8,10) }}</p>
                  <p class="date-p2">{{item.Releasetime.substring(0,4)}}.{{ item.Releasetime.substring(5,7) }}</p>
                </div>
                <div class="list-right">
                  <p class="desc-p1">{{item.Title}}</p>
                  <p class="desc-p2">{{item.briefIntroduction}}</p>
                </div>
              </div>
              <div class="news-list1" v-if="index > 0">
                <div class="list-left">
                  <p class="date-p1">{{ item.Releasetime.substring(8,10) }}</p>
                   <p class="date-p2">{{item.Releasetime.substring(0,4)}}.{{ item.Releasetime.substring(5,7) }}</p>
                </div>
                <div class="list-center">
                  <p class="desc-p1">{{item.Title}}</p>
                  <p class="desc-p2">{{item.briefIntroduction}}</p>
                </div>
                <div class="list-center-right">
                  <img class="list-arrow" src="../assets/img/news-icon.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="news-right" v-if="isshow == true">
            <div class="news-right-img1-box">
              <img class="news-right-img1" @click="toModel(0)" :src="imgBaseUrl + bg1" alt="">
            </div>
            <div class="news-right-img2-box">
              <div class="news-right-img2-box-inner">
                <img class="news-right-img2" @click="toModel(1)" :src="imgBaseUrl + bg2" alt="">
              </div>
              <div class="news-right-img2-box-inner">
                <img class="news-right-img2" :src="imgBaseUrl + bg3" alt="">
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <!-- 集团板块 -->
      <div class="h-number1">
        <div class="h-number1-inner">
          <p class="title-1">{{$t("head.head-15")}}</p>
          <div class="number-title">
            <p class="number-title-span"><span :class="['span-span',errtipsadd]"  >{{ numberList[0] }}</span>{{$t("home.home-25")}}</p>
            <p class="number-title-span"><span :class="['span-span',errtipsadd]">{{ numberList[1] }}</span>{{$t("home.home-26")}}</p>
          </div>
          <p class="title-2">{{numberList[2]}}</p>
          <div class="h-swiper1-content" style="height:460px">
            <div class="pch-swiper1">
              <p :class="['pch-swiper1-title','pch-swiper1-p1',errtipsadd]"  @click="toUrl('plate',0)">{{$t("home.home-100")}}</p>
              <p :class="['pch-swiper1-title','pch-swiper1-p2',errtipsadd]" @click="toUrl('plate',4)">{{$t("home.home-101")}}</p>
              <p class="pch-swiper1-desc pch-swiper1-p3 " >{{$t("home.home-102")}}</p>
              <p class="pch-swiper1-desc pch-swiper1-p4 ">{{$t("home.home-103")}}</p>

              <p  :class="['pch-swiper1-title','pch-swiper1-p11',errtipsadd]" @click="toUrl('plate',1)">{{$t("home.home-104")}}</p>
              <p :class="['pch-swiper1-title','pch-swiper1-p21',errtipsadd]"  @click="toUrl('plate',5)">{{$t("home.home-105")}}</p>
              <p class="pch-swiper1-desc pch-swiper1-p31 ">{{$t("home.home-106")}}</p>
              <p class="pch-swiper1-desc pch-swiper1-p41 ">{{$t("home.home-107")}}</p>

              <p :class="['pch-swiper1-title','pch-swiper1-p12',errtipsadd]"  @click="toUrl('plate',2)">{{$t("home.home-108")}}</p>
              <p :class="['pch-swiper1-title','pch-swiper1-p22',errtipsadd]" @click="toUrl('plate',6)">{{$t("home.home-109")}}</p>
              <p class="pch-swiper1-desc pch-swiper1-p32 ">{{$t("home.home-110")}}</p>
              <p class="pch-swiper1-desc pch-swiper1-p42 ">{{$t("home.home-111")}}</p>

              <p :class="['pch-swiper1-title','pch-swiper1-p13',errtipsadd]" @click="toUrl('plate',3)">{{$t("home.home-112")}}</p>
              <p :class="['pch-swiper1-title','pch-swiper1-p23',errtipsadd]" @click="toUrl('plate',7)">{{$t("home.home-113")}}</p>
              <p class="pch-swiper1-desc pch-swiper1-p33 ">{{$t("home.home-114")}}</p>
              <p class="pch-swiper1-desc pch-swiper1-p43 ">{{$t("home.home-115")}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-home" v-if="terminal == 'Mobile'">
      <div class="home-banner">
        <el-carousel :interval="5000" arrow="always" indicator-position="inside" :autoplay="true" @change="changeBanner">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <div class="mobile-banner-inner" v-if="item.appImgUrl.substr(item.appImgUrl.length-3) != 'mp4'">
              <img class="banner-img"  :src="imgBaseUrl + item.appImgUrl"  @click="BannertoModel(item)">
            </div>
            <video ref="videoPlayer" :controls="false" :autoplay="true" muted v-if="item.appImgUrl.substr(item.appImgUrl.length-3) == 'mp4'">
                <source :src="imgBaseUrl + item.appImgUrl"  type="video/mp4">
                您的浏览器不支持Video标签。
            </video>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="mobile-news">
        <div class="mobile-news-box">
          <div v-for="(item,index) in newsList" :key="index" @click="toDetail(item)">
            <div class="news-list" v-if="index == 0">
              <div class="list-left">
                  <p class="date-p1">{{ item.Releasetime.substring(8,10) }}</p>
                   <p class="date-p2">{{item.Releasetime.substring(0,4)}}.{{ item.Releasetime.substring(5,7) }}</p>
                </div>
              <div class="list-right">
                <p class="desc-p1">{{item.Title}}</p>
                <p class="desc-p2">{{item.briefIntroduction}}</p>
              </div>
            </div>
            <div class="news-list1" v-if="index > 0">
              <div class="list-left">
                  <p class="date-p1">{{ item.Releasetime.substring(8,10) }}</p>
                   <p class="date-p2">{{item.Releasetime.substring(0,4)}}.{{ item.Releasetime.substring(5,7) }}</p>
                </div>
              <div class="list-center">
                <p class="desc-p1">{{item.Title}}</p>
                <p class="desc-p2">{{item.briefIntroduction}}</p>
              </div>
            </div>
          </div>
          <div class="news-right" v-if="isshow == true">
            <div class="news-right-img1-box">
              <img class="news-right-img1"  @click="toModel(0)" :src="imgBaseUrl + bg1" alt="">
            </div>
            <div class="news-right-img2-box">
              <div class="news-right-img2-box-inner">
                <img class="news-right-img2"   @click="toModel(1)" :src="imgBaseUrl + bg2" alt="">
              </div>
              <div class="news-right-img2-box-inner">
                <img class="news-right-img2"  :src="imgBaseUrl + bg3" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 集团板块 -->
      <div class="h-mobile-number1" style="width:100%">
        <div class="h-number1-inner">
          <p class="title-1">{{$t("head.head-15")}}</p>
          <div class="number-title">
            <p class="number-title-span"><span class="span-span">{{ numberList[0] }}</span>{{$t("home.home-25")}}</p>
            <p class="number-title-span"><span class="span-span">{{ numberList[1] }}</span>{{$t("home.home-26")}}</p>
          </div>
          <p class="title-2">{{numberList[2]}}</p>
          <div class="h-mobile-swiper1-content" style="height: 150px;">
            <div class="h-mobile-swiper1" >
               <div class="mobileh-swiper1" style="width: 360px;height: 150px;">
                  <p class="pch-swiper1-title pch-swiper1-p1" @click="toUrl('plate',0)">{{$t("home.home-100")}}</p>
                  <p class="pch-swiper1-title pch-swiper1-p2" @click="toUrl('plate',4)">{{$t("home.home-101")}}</p>
                  <p class="pch-swiper1-title pch-swiper1-p11" @click="toUrl('plate',1)">{{$t("home.home-104")}}</p>
                  <p class="pch-swiper1-title pch-swiper1-p21" @click="toUrl('plate',5)">{{$t("home.home-105")}}</p>
                  <p class="pch-swiper1-title pch-swiper1-p12" @click="toUrl('plate',2)">{{$t("home.home-108")}}</p>
                  <p class="pch-swiper1-title pch-swiper1-p22" @click="toUrl('plate',6)">{{$t("home.home-109")}}</p>
                  <p class="pch-swiper1-title pch-swiper1-p13" @click="toUrl('plate',3)">{{$t("home.home-112")}}</p>
                  <p class="pch-swiper1-title pch-swiper1-p23" @click="toUrl('plate',7)">{{$t("home.home-113")}}</p>
                </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import NumberGrow from '@/components/numberGrow.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    NumberGrow
  },
  
  data(){
    return{
      imgBaseUrl: '',
      terminal: '',
      currentIndex: 0,
      isshow: false,



      list: [],
      notice: '',
      internationalTab: 1,
      ktab: 1,
      customerNum: 0,
      Dialog: false,
      tabBag: 'tabBag1',
      status:false,
      bannerList: [],
      numberList: [],
      imgList: [],
      lawInfo: '',
      linkList: [],
      EWMList: [],
      contactList: [],
      newsList: [],
      bg1: '',
      bg2: '',
      bg3: '',
      bgList: [],
      errtipsadd:""  
    }
  },
  mounted(){
    window.scrollTo(0,0);
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')

    let that=this;
    if(this.imgBaseUrl){
      this.getNews()
      //this.getImg()
      //this.getBanner()

       setTimeout(function(){
         that.getLaw()
       }, 3000 )
    }
    // this.getContact()
    window.addEventListener('scroll',this.handleScroll)

  },
  activated(){
    // window.scrollTo(0,0);
  },
  methods:{
    toUrl(url,index){
      localStorage.setItem('activeTab', url)
      //this.$router.push({name: url,query:{ menu: index }})

      let routeUrl = this.$router.resolve({
          path: url,
          query: {menu: index }
     });
     window.open(routeUrl.href, '_blank');

    },
    handleScroll(e){

//console.log(window.pageYOffset);
//console.log(this.errtipsadd);
        if(window.pageYOffset>=1200&&this.errtipsadd=="")
        {

           this.errtipsadd="errtips"

        }  else{

           //this.errtipsadd=""

        } 
    },
    switchBtnPre(){
      if(this.currentIndex == 0){
        // this.$message({
        //   showClose: true,
        //   type: 'warning',
        //   message: '已是第一页'
        // });
        this.currentIndex = this.imgList.length - 1
        this.$refs.carouselRef.setActiveItem(this.currentIndex)
      }else{
        this.currentIndex--
        this.$refs.carouselRef.setActiveItem(this.currentIndex)
      }
    },
    switchBtnNext(){
      if(this.currentIndex == this.imgList.length - 1){
        this.currentIndex = 0
        this.$refs.carouselRef.setActiveItem(this.currentIndex)
      }else{
        this.currentIndex++
        this.$refs.carouselRef.setActiveItem(this.currentIndex)
      }
    },
    toDetail(item){
       //集团新闻
      //  localStorage.setItem('newsid',JSON.stringify(item))
      //  this.$router.push({name: "news",query:{ menu: 1 }})
      let routeUrl = this.$router.resolve({
          name: "searchDetail",
          query: {id:item.ID,type: item.NewsClassification}
     });
      window.open(routeUrl .href, '_blank');
    },
    BannertoModel(item){
      if(item.Remarks){
        if(item.Remarks.indexOf('http') != -1){
          window.open(item.Remarks,'_blank');
        }
     }
    },
    toModel(index){
      if(this.bgList[index].ValueContent){
        if(this.bgList[index].ValueContent.indexOf('http') != -1){
          // window.location.href = this.bgList[index].ValueContent
          window.open(this.bgList[index].ValueContent,'_blank');
        }else{
          let routerName = this.bgList[index].ValueContent
          this.$router.push({name: routerName,query:{ menu: 1 }})
        }
      }else{
        //localStorage.setItem('allDetails',JSON.stringify(this.bgList[index]))
        //this.$router.push({name: 'detail'})
      }
    },
    toModel1(item){
      //console.log(item)
      if(item.ValueContent){
        if(item.ValueContent.indexOf('http') != -1){
          // window.location.href = item.ValueContent
          window.open(item.ValueContent,'_blank');
        }else{
          let routerName = item.ValueContent
          this.$router.push({name: routerName,query:{ menu: 1 }})
        }
      }else{
        //localStorage.setItem('allDetails',JSON.stringify(item))
        //this.$router.push({name: 'detail'})
      }
    },
    changeBanner(e){
      // if(e == 0){
      //   this.$refs.videoPlayer.play()
      // }
    },
    getNews(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV10",params).then(
        (res) => {
          that.newsList = res.data.rowsv1

          that.newsList.forEach(x1 => {
             if(x1.briefIntroduction.length>76)
             {
                  x1.briefIntroduction=x1.briefIntroduction.slice(0,75)+".....";
             }
             if(x1.Title.length>80)
             {
                  x1.Title=x1.Title.slice(0,79)+".....";
             }
          });
          that.bgList = res.data.rows
          if(res.data.rows.length>2){
           that.bg1 = res.data.rows[0].ImgUrl
           that.bg2 = res.data.rows[1].ImgUrl
           that.bg3 = res.data.rows[2].ImgUrl
          }
          that.isshow=true
          
          //在这加载首页banner
          this.getBanner();
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    // 联系我们
    getContact(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV5",params).then(
        (res) => {
          that.contactList = res.data.rows
          console.log(that.contactList)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    
    // 法律声明
    getLaw(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV2",params).then(
        (res) => {
          that.lawInfo = res.data.rows[0].Content
        },
        (error) => {
          console.log("请求失败了", error.message);
      });

       //是否有缓存
       localStorage.setItem('isCache',"1");  
    
    },
    
    // 获取轮播图
    getBanner(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_Banner/GetPageDataV1",params).then(
        (res) => {
            
           if(localStorage.getItem("isCache")){
              that.bannerList=res.data.rows;
              //alert(localStorage.getItem("isCache"));
           }else{
            if(res.data.rows.length>0){
              that.bannerList.push(res.data.rows[0]);
              if(res.data.rows.length>1){
                 setTimeout(function(){
                   for(var i=1;i<res.data.rows.length;i++)
                   {
                      that.bannerList.push(res.data.rows[i]);
                   }
              }, 2000)
             }
             
          }
         }

         //在这加载集团板块
          that.getImg();
           
        
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    // 福寿园旗下文字获取--这个可以不用了
    getWords(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV6",params).then(
        (res) => {
          that.numberList = res.data.msg.split('|')
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    // 福寿园旗下文字图片获取
    getImg(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.imgList = []
      that.$axios.post(this.baseURL + "api/Web_HomePage/GetPageDataV6",params).then(
        (res) => {
           that.numberList = res.data.msg.split('|')

             if(localStorage.getItem("isCache")){

              //  for(let i = 0, len = res.data.rows.length; i < len; i += 4) {
              //       that.imgList.push(res.data.rows.slice(i, i + 4))
              //  }
             }else{
              // setTimeout(function(){
              //    for(let i = 0, len = res.data.rows.length; i < len; i += 4) {
              //       that.imgList.push(res.data.rows.slice(i, i + 4))
              //    }
              // }, 1500)
            }
          //console.log(that.imgList)
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },
    

  }
}
</script>
<style lang="scss">
  .banner{
    width: 100%;
    height: 672px;
    position: relative;
    top: -100px;
  }
  .h-number{
    width: 100%;
    height: 500px;
    background: url('../assets/img/number-bg.png') no-repeat center center;
    background-size: 100% 100%;
    margin-top: 60px;
  }
.h-number1{
.el-carousel__indicator--horizontal 
{ 
 padding-top: 40px;
}
.el-carousel__indicators--outside button {
  background-color:#E9B454;
    height: 4px;
}
}
.imgtitle{
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  padding-top: 17px;
  color:#333333;
}
.mobile-imgtitle{
 font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding-top: 6px;
  color:#333333;
}
.pch-swiper1{
     background: url('../assets/img/bankuai.png') no-repeat center center;
     width: 1280px !important;
    height: 488px !important;
}
.mobileh-swiper1{
    background: url('../assets/img/bankuai1.png') no-repeat center center;
    background-size: 100% 100%;
    width: 100%;
    margin: 0 auto;
}


.errtips {
    animation-name: errtips;
    animation-duration: 1.5s;
}

@keyframes errtips {
      10% {
        transform: translateY(2px);
      }
      20% {
        transform: translateY(-2px);
      }
      30% {
        transform: translateY(2px);
      }
      40% {
        transform: translateY(-2px);
      }
      50% {
        transform: translateY(2px);
      }
      60% {
        transform: translateY(-2px);
      }
      70% {
        transform: translateY(2px);
      }
      80% {
        transform: translateY(-2px);
      }
      90% {
        transform: translateY(2px);
      }
      100% {
        transform: translateY(0);
      }
    }

</style>
