<template>
  <div>
    <div class="grop-news" v-if="terminal == 'PC'">
      <div class="top-menu">
        <div class="top-menu-left">
          <span class="top-menu-left-span1">{{$t("head.head-22")}} > </span> <span class="top-menu-left-span2" > {{$t("head.head-25")}}</span> 
        </div>
        <div class="top-menu-right">
          <div class="year-select">
            <el-select v-model="year" :placeholder='$t("home.home-1")' @change="toSearch" clearable>
              <el-option
                v-for="item in yearList"
                :key="item.year"
                :label="item.year"
                :value="item.year"
                >
              </el-option>
            </el-select>
          </div>
          <div class="search-input">
            <el-input
            :placeholder='$t("home.home-2")'
              v-model="value" @keyup.enter.native="toSearch" clearable @clear="toSearch">
              <i slot="suffix" @click="toSearch" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
      </div>
      <div class="notice-box">
        <div class="kan-list" v-for="(item,index) in list" :key="index">
            <div class="news-line-left">
              <p class="date1" v-if="item.Releasetime">{{ item.Releasetime.substring(5,7) }}.{{ item.Releasetime.substring(8,10) }}</p>
             <span class="see-people"><img class="Vector-icon" src="../../../assets/img/Vector.png" alt="">
                 <span v-if="item.Releasetime" class="date2">{{item.Releasetime.substring(0,4)}} </span>
                 </span>
            </div>
          <div class="kan-center">
              <p class="news-title" @click="toDownload(item)">
              <span class="news-tag" v-if="index < 2">NEW</span>
                {{item.Title}}
              </p>
              <p class="news-tips">
                <span class="news-from" v-if="item.Publisher">{{item.Publisher}}</span>
            
                <!-- <span class="see-people"> <img class="see-icon" src="../../../assets/img/see.png" alt=""> {{item.Readingvolume || 0}}</span> -->
              </p>
            </div>
            <div class="kan-right">
              <p class="down-p" @click="toDownload(item)">
                <img class="see-download" src="../../../assets/img/download-btn.png" alt="">
                {{$t("home.download")}}</p>
            </div>
          </div>
          <p class="see-more" v-if="isMore" @click="seeMore">{{$t("home.see-more")}}</p>
        </div>
         <div class="search-list" v-if="noData" style="margin-top: 150px;">
        <img class="no-data-icon" src="../../../assets/img/no-data.png" alt=""> 
        <p class="no-data-p">{{$t("home.no-data")}}</p>
      </div>
    </div>
    <div class="mobile-news" v-if="terminal == 'Mobile'" style="background: rgba(254,250,245);">
      <div class="select-box" style="margin-top: 0;padding-top: 15px;">
        <div class="mobile-year-select">
          <el-select v-model="year" :placeholder='$t("home.home-1")' @change="toSearch" clearable>
            <el-option
              v-for="item in yearList"
              :key="item.year"
              :label="item.year"
              :value="item.year"
              >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="mobile-notice-box" >
        <div class="mobile-kan-list" v-for="(item,index) in list" :key="index">
            <div class="mobile-news-line-left">
              <p class="date1" v-if="item.Releasetime">{{ item.Releasetime.substring(5,7) }}.{{ item.Releasetime.substring(8,10) }}</p>
              <span class="see-people"><img class="Vector-icon" src="../../../assets/img/Vector.png" alt="">
                 <span v-if="item.Releasetime" class="date2">{{item.Releasetime.substring(0,4)}} </span>
                 </span>
            </div>
            <div class="mobile-kan-center">
              <p class="news-title" @click="toDownload(item)">
                {{item.Title}} 
              </p>
              <p class="mobile-news-tips">
                <!-- <span class="see-people"> <img class="see-icon" src="../../../assets/img/see.png" alt=""> {{item.Readingvolume || 0}}</span> -->
                <span class="news-from" v-if="item.Publisher">{{item.Publisher}}</span>
              </p>
            </div>
            <div class="mobile-kan-right">
              <p class="mobile-down-p" @click="toDownload(item)">
                <img class="mobile-see-download" src="../../../assets/img/download-btn.png" alt="">
                {{$t("home.download")}}</p>
            </div>
          </div>
          <p class="mobile-see-more" v-if="isMore" @click="seeMore">{{$t("home.see-more")}}</p>
      </div>
    </div>
     
  </div>
  
</template>

<script>
export default {
  name: 'noticePage',
  data(){
    return{
      type: 'list',
      list: [],
      currentPage: 1,
      imgBaseUrl: '',
      id: '',
      yearList: [],
      year: '',
      value: '',
      isMore: false,
       noData:false,
       terminal: 'PC',
    }
  },
  mounted(){
    this.terminal = localStorage.getItem('terminal')
    this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
    this.yearList = JSON.parse(localStorage.getItem('yearList'))
    this.getList()
  },
  methods:{
    seeMore(){
      this.currentPage ++
      this.getList()
    },
    toSearch(){
      this.type = 'list'
      this.list=[]
      this.currentPage = 1
      this.isMore = false
      this.noData=false;
      this.getList()
    },
    toDownload(item){
      let url = this.imgBaseUrl + item.Content
       //更新阅读数
     this.$axios.post(this.baseURL + "api/Web_CorporateGovernance/GetPageDataV2",{"para":item.ID }).then(
        (res) => {
            
        });
      window.open(url,'_blank')
    },
    toDetail(item){
      this.type = 'detail'
      this.id = item.ID
    },
    getList(){
      let that = this
      let params = {
        page: this.currentPage,
        rows: 10,
        para: `${that.year}|${that.value}`,
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_CorporateGovernance/GetPageDataV1",params).then(
        (res) => {
          if(that.currentPage == 1){
            that.list = res.data.rows
          }else{
            res.data.rows.forEach(item => {
              that.list.push(item)
            });
          }
          if(res.data.total > 10){
            that.isMore = true
          }
          if(res.data.total  == 0 || res.data.rows.length == 0|| res.data.rows.length < 10){
            that.isMore = false
          }
          if(res.data.total  == 0){
            that.noData=true;
          }
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.grop-news{
  min-height: 500px;
 
}
</style>