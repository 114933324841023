<template>
  <div>
    <div class="header" v-if="terminal == 'PC'" :class="{'head-bg': !isTop}">
      <div class="header-inner" >
        <img class="header-logo" v-if="Language == 1" @click="toUrl('home')" src="../assets/img/logo11.png" alt="">
        <img class="header-logo1" v-if="Language == 1" :class="{'isBlock': !isTop}" @click="toUrl('home')" src="../assets/img/logo1.png" alt="">
        <img class="header-logo" v-if="Language == 2" @click="toUrl('home')" src="../assets/img/logo22.png" alt="">
        <img class="header-logo1" v-if="Language == 2" :class="{'isBlock': !isTop}" @click="toUrl('home')" src="../assets/img/logo2.png" alt="">
        <img class="header-logo" v-if="Language == 3" @click="toUrl('home')" src="../assets/img/logo33.png" alt="">
        <img class="header-logo1" v-if="Language == 3" :class="{'isBlock': !isTop}" @click="toUrl('home')" src="../assets/img/logo3.png" alt="">
        <div class="header-tab">
          <div class="tab-inner">
            <p class="tab-inner-title" :class="{'activeTab': activeTab == 'home'}" @click="toUrl('home')">{{$t("head.head-1")}}</p>
          </div>
          <div class="tab-inner">
            <p class="tab-inner-title" :class="{'activeTab': activeTab == 'introduction'}" @click="toUrl('introduction',1)">{{$t("head.head-2")}}</p>
             <div class="hover-box" style="position: relative;left: -180px;top: -120px;">
              <div class="hover-box-inner" style="width: 900px;">
                <p class="hover-p" @click="toUrl('introduction',1)">{{$t("head.head-3")}}</p>
                <p class="hover-p" @click="toUrl('introduction',4)">{{$t("head.head-6")}}</p>
                <p class="hover-p" @click="toUrl('introduction',2)">{{$t("head.head-4")}}</p>
                 <p class="hover-p" @click="toUrl('introduction',5)">{{$t("head.head-7")}}</p>
              
               
                <p class="hover-p" @click="toUrl('introduction',6)">{{$t("head.head-8")}}</p>
                <p class="hover-p" @click="toUrl('introduction',7)">{{$t("head.head-9")}}</p>
                <p class="hover-p" @click="toUrl('introduction',3)">{{$t("head.head-5")}}</p>

              </div>
            </div>
          </div>
          <div class="tab-inner">
            <p class="tab-inner-title" :class="{'activeTab': activeTab == 'news'}" @click="toUrl('news',1)">{{$t("head.head-10")}}</p>
            <div class="hover-box">
              <div class="hover-box-inner">
                <p class="hover-p" @click="toUrl('news',1)">{{$t("head.head-11")}}</p>
                <p class="hover-p" @click="toUrl('news',2)">{{$t("head.head-12")}}</p>
                <p class="hover-p" @click="toUrl('news',3)">{{$t("head.head-13")}}</p>
                <p class="hover-p" @click="toUrl('news',4)">{{$t("head.head-14")}}</p>
              </div>
            </div>
          </div>
          <div class="tab-inner">
            <p class="tab-inner-title" :class="{'activeTab': activeTab == 'plate'}" @click="toUrl('plate',0)">{{$t("head.head-15")}}</p>
            <div class="hover-box" style="position: relative;left: -600px;top: -120px;">
              <div class="hover-box-inner" style="width: 1190px;">
                <p class="hover-p" style="width: 145px;" v-for="(item,index) in list" :key="index" @click="toUrl('plate',index)">{{item.Title}}</p>
              </div>
            </div>
          </div>
          <div class="tab-inner">
            <p class="tab-inner-title" :class="{'activeTab': activeTab == 'duty'}"  @click="toUrl('duty',1)">{{$t("head.head-16")}}</p>
            <div class="hover-box">
              <div class="hover-box-inner" style="width: 280px;">
                <p class="hover-p" @click="toUrl('duty',1)">{{$t("head.head-17")}}</p>
                <p class="hover-p" @click="toUrl('duty',2)">{{$t("head.head-18")}}</p>
              </div>
            </div>
          </div>
          <div class="tab-inner">
            <p class="tab-inner-title" :class="{'activeTab': activeTab == 'cooperation'}" @click="toUrl('cooperation',1)">{{$t("head.head-19")}}</p>
            <div class="hover-box">
              <div class="hover-box-inner" style="width: 270px;">
                <p class="hover-p" @click="toUrl('cooperation',1)">{{$t("head.head-20")}}</p>
                <p class="hover-p" @click="toUrl('cooperation',2)">{{$t("head.head-21")}}</p>
              </div>
            </div>
          </div>
          <div class="tab-inner">
            <p class="tab-inner-title" :class="{'activeTab': activeTab == 'IR'}" @click="toUrl('IR',1)">{{$t("head.head-22")}}</p>
            <div class="hover-box" style="position: relative;left: -650px;top: -120px;">
              <div class="hover-box-inner" style="width: 900px;">
                <p class="hover-p" @click="toUrl('IR',1)">{{$t("head.head-23")}}</p>
                <p class="hover-p" @click="toUrl('IR',2)">{{$t("head.head-24")}}</p>
                <p class="hover-p" @click="toUrl('IR',3)">{{$t("head.head-25")}}</p>
                <p class="hover-p" @click="toUrl('IR',4)">{{$t("head.head-26")}}</p>
                <p class="hover-p" @click="toUrl('IR',5)">{{$t("head.head-27")}}</p>
                <p class="hover-p" @click="toUrl('IR',6)">{{$t("head.head-28")}}</p>
                <p class="hover-p" @click="toUrl('IR',7)">{{$t("head.head-29")}}</p>
              </div>
            </div>
          </div>

         <div class="tab-inner">
            <p class="tab-inner-title">

               <span @click="changeLanguage('1')">简</span> &nbsp;|&nbsp; <span @click="changeLanguage('3')">繁</span> &nbsp;|&nbsp; <span @click="changeLanguage('2')">EN</span>

            </p>
          </div>
          <div class="tab-inner">
  

            <!-- <el-dropdown v-if="lang == 'zh'">
              <span class="tab-inner-title1">
                {{$t("head.head-30")}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><p @click="changeLanguage('3')">{{$t("head.head-31")}}</p> </el-dropdown-item>
                <el-dropdown-item><p @click="changeLanguage('2')">{{$t("head.head-32")}}</p></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-if="lang == 'en'">
              <span class="tab-inner-title1">
                {{$t("head.head-32")}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><p @click="changeLanguage('1')">{{$t("head.head-30")}}</p> </el-dropdown-item>
                <el-dropdown-item><p @click="changeLanguage('3')">{{$t("head.head-31")}}</p></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-if="lang == 'tra'">
              <span class="tab-inner-title1">
                {{$t("head.head-31")}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item><p @click="changeLanguage('1')">{{$t("head.head-30")}}</p> </el-dropdown-item>
                <el-dropdown-item><p @click="changeLanguage('2')">{{$t("head.head-32")}}</p></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
          <div class="tab-inner">
            <img class="tab-search" src="../assets/img/search.png" alt="">
            <img class="tab-search1" @click="toSearch" src="../assets/img/search-1.png" alt="">
          </div>
        
        
        <div class="div-gupiao" id="div-gupiao_1" >
         {{$t("home.home-118")}}：01448.HK  &nbsp;&nbsp; {{$t("home.home-119")}}&nbsp; {{$t("home.home-120")}}：{{T1}} &nbsp;&nbsp; {{$t("home.home-121")}}：{{T2}}&nbsp;&nbsp; {{$t("home.home-122")}}：{{T3}}  &nbsp;&nbsp; {{$t("home.home-123")}}：{{T4}}  &nbsp;&nbsp; {{$t("home.home-124")}}：{{T5}}
        </div>

        <div class="div-imggonggao">
           <img class="imggonggao" id="imggonggao_1" src="../assets/img/foot/gonggao.png" alt="">
           <img class="imggonggao1" id="imggonggao1_1"  src="../assets/img/foot/gonggao1.png" alt="">
        </div> 
        </div>
      </div>
     

    </div>
    <div class="mobile-head" v-if="terminal == 'Mobile'" >
        <div class="fixed-box" v-if="showMobileFixed">
          <img class="fixed-box-logo" v-if="Language == 1" @click="toUrl('home')" src="../assets/img/foot/logoyd1.png" alt="">
          <img class="fixed-box-logo" v-if="Language == 2" @click="toUrl('home')" src="../assets/img/foot/logoyd2.png" alt="">
          <img class="fixed-box-logo" v-if="Language == 3" @click="toUrl('home')" src="../assets/img/foot/logoyd3.png" alt="">
          <div class="fixed-right">
            <img class="fixed-right-search" @click="toSearch"  src="../assets/img/foot/search.png" alt="">
            <div>
              <el-dropdown v-if="lang == 'zh'">
                <span class="mobile-tab-inner-title1">
                  {{$t("head.head-30")}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><p @click="changeLanguage('3')">{{$t("head.head-31")}}</p> </el-dropdown-item>
                  <el-dropdown-item><p @click="changeLanguage('2')">{{$t("head.head-32")}}</p></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown v-if="lang == 'en'">
                <span class="mobile-tab-inner-title1">
                  {{$t("head.head-32")}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><p @click="changeLanguage('1')">{{$t("head.head-30")}}</p> </el-dropdown-item>
                  <el-dropdown-item><p @click="changeLanguage('3')">{{$t("head.head-31")}}</p></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown v-if="lang == 'tra'">
                <span class="mobile-tab-inner-title1">
                  {{$t("head.head-31")}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><p @click="changeLanguage('1')">{{$t("head.head-30")}}</p> </el-dropdown-item>
                  <el-dropdown-item><p @click="changeLanguage('2')">{{$t("head.head-32")}}</p></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <img class="fixed-right-menu" @click="showMobileFixed = false" src="../assets/img/foot/menu.png" alt="">
          </div>
        </div>
        <div class="menu-box" v-if="!showMobileFixed">
          <div class="shadow"></div>
          <div class="menu-box-inner">
            <div class="menu-content">
              <img class="menu-close" @click="showMobileFixed = true" src="../assets/img/foot/close.png" alt="">
              <div class="menu-box-f">
                <p class="menu-content-title" @click="toUrl('home')">{{$t("head.head-1")}}</p>
                <div class="menu-content-box">
                  <p class="menu-content-title" @click="tag1 = !tag1">
                    <span>{{$t("head.head-2")}}</span>
                    <img v-if="tag1"  class="menu-arrow" src="../assets/img/foot/down.png" alt="">
                    <img v-if="!tag1"  class="menu-arrow" src="../assets/img/foot/up.png" alt="">
                  </p>
                  <div class="menu-inner" v-if="!tag1">
                    <p class="hover-p" @click="toUrl('introduction',1)">{{$t("head.head-3")}}</p>
                    <p class="hover-p" @click="toUrl('introduction',4)">{{$t("head.head-6")}}</p>
                    <p class="hover-p" @click="toUrl('introduction',2)">{{$t("head.head-4")}}</p>
                         <p class="hover-p" @click="toUrl('introduction',5)">{{$t("head.head-7")}}</p>
                   
               
                    <p class="hover-p" @click="toUrl('introduction',6)">{{$t("head.head-8")}}</p>
                    <p class="hover-p" @click="toUrl('introduction',7)">{{$t("head.head-9")}}</p>
                    <p class="hover-p" @click="toUrl('introduction',3)">{{$t("head.head-5")}}</p>

                  </div>
                </div>
                <div class="menu-content-box">
                  <p class="menu-content-title" @click="tag2 = !tag2">
                    <span>{{$t("head.head-10")}}</span>
                    <img v-if="tag2" class="menu-arrow" src="../assets/img/foot/down.png" alt="">
                    <img v-if="!tag2" class="menu-arrow" src="../assets/img/foot/up.png" alt="">
                  </p>
                  <div class="menu-inner" v-if="!tag2">
                    <p class="hover-p" @click="toUrl('news',1)">{{$t("head.head-11")}}</p>
                    <p class="hover-p" @click="toUrl('news',2)">{{$t("head.head-12")}}</p>
                    <p class="hover-p" @click="toUrl('news',3)">{{$t("head.head-13")}}</p>
                    <p class="hover-p" @click="toUrl('news',4)">{{$t("head.head-14")}}</p>
                  </div>
                </div>
                <div class="menu-content-box">
                  <p class="menu-content-title" @click="tag3 = !tag3">
                    <span>{{$t("head.head-15")}}</span>
                    <img v-if="tag3" class="menu-arrow" src="../assets/img/foot/down.png" alt="">
                    <img v-if="!tag3" class="menu-arrow" src="../assets/img/foot/up.png" alt="">
                  </p>
                  <div class="menu-inner" v-if="!tag3">
                    <p class="hover-p" style="width: 110px;" v-for="(item,index) in list" :key="index" @click="toUrl('plate',index)">{{item.Title}}</p>
                  </div>
                </div>
                <div class="menu-content-box">
                  <p class="menu-content-title" @click="tag4 = !tag4">
                    <span>{{$t("head.head-16")}}</span>
                    <img v-if="tag4" class="menu-arrow" src="../assets/img/foot/down.png" alt="">
                    <img v-if="!tag4" class="menu-arrow" src="../assets/img/foot/up.png" alt="">
                  </p>
                  <div class="menu-inner" v-if="!tag4">
                    <p class="hover-p" @click="toUrl('duty',1)">{{$t("head.head-17")}}</p>
                    <p class="hover-p" @click="toUrl('duty',2)">{{$t("head.head-18")}}</p>
                  </div>
                </div>
                <div class="menu-content-box">
                  <p class="menu-content-title" @click="tag5 = !tag5">
                    <span>{{$t("head.head-19")}}</span>
                    <img v-if="tag5" class="menu-arrow" src="../assets/img/foot/down.png" alt="">
                    <img v-if="!tag5" class="menu-arrow" src="../assets/img/foot/up.png" alt="">
                  </p>
                  <div class="menu-inner" v-if="!tag5">
                    <p class="hover-p" @click="toUrl('cooperation',1)">{{$t("head.head-20")}}</p>
                    <p class="hover-p" @click="toUrl('cooperation',2)">{{$t("head.head-21")}}</p>
                  </div>
                </div>
                <div class="menu-content-box">
                  <p class="menu-content-title" @click="tag6 = !tag6">
                    <span>{{$t("head.head-22")}}</span>
                    <img v-if="tag6" class="menu-arrow" src="../assets/img/foot/down.png" alt="">
                    <img v-if="!tag6" class="menu-arrow" src="../assets/img/foot/up.png" alt="">
                  </p>
                  <div class="menu-inner" v-if="!tag6">
                    <p class="hover-p" @click="toUrl('IR',1)">{{$t("head.head-23")}}</p>
                    <p class="hover-p" @click="toUrl('IR',2)">{{$t("head.head-24")}}</p>
                    <p class="hover-p" @click="toUrl('IR',3)">{{$t("head.head-25")}}</p>
                    <p class="hover-p" @click="toUrl('IR',4)">{{$t("head.head-26")}}</p>
                    <p class="hover-p" @click="toUrl('IR',5)">{{$t("head.head-27")}}</p>
                    <p class="hover-p" @click="toUrl('IR',6)">{{$t("head.head-28")}}</p>
                    <p class="hover-p" @click="toUrl('IR',7)">{{$t("head.head-29")}}</p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
    </div>
    <div class="search-box" v-if="isSearch && terminal == 'PC'" >
      <div class="search-head-input">
        <el-input clear=""
        :placeholder='$t("home.home-9")'
          v-model="input"
          @keyup.enter.native="toSearchPage">
          <i @click="toSearchPage" slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div>
    <div class="mobile-search-box" v-if="isSearch && terminal == 'Mobile'" >
      <div class="mobile-search-head-input">
        <el-input clear=""
        :placeholder='$t("home.home-9")'
          v-model="input"
          @keyup.enter.native="toSearchPage">
          <i @click="toSearchPage" slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div>
  </div>
  
</template>

<script>
import axios from 'axios'
export default {
  name: 'Home-page',
  props: {
    msg: String
  },
  data(){
    return{
      tab: 1,
      value: '',
      list: [],
      isSearch: false,
      input: '',
      terminal: 'PC',
      activeTab: '',
      isTop: true,
      isscroll: false,
      showMobileFixed: true,
      tag1: true,
      tag2: true,
      tag3: true,
      tag4: true,
      tag5: true,
      tag6: true,
      lang: 'zh',
      Language: 1,
      indextz:0,
      T1:'--',
      T2:'--',
      T3:'--',
      T4:'--',
      T5:'--',
    }
  },
  watch :{
  '$route': function (to, from) {
       //执行数据更新查询
      this.changePage();
    }
  },
  mounted(){

    document.title=this.$t("home.home-48")

    this.activeTab = localStorage.getItem('activeTab')
    if(localStorage.getItem('lang')){
      this.lang = localStorage.getItem('lang')
    }
    
    this.Language= localStorage.getItem('Language')
if(!localStorage.getItem('Language')){

         this.Language=1;
        this.lang = 'zh'
        localStorage.setItem('lang','zh')
        localStorage.setItem('Language',1)
}

    window.addEventListener('scroll', this.windowScroll)
    this.terminal = localStorage.getItem('terminal')
    this.getList()

     this.indextz= this.$route.query.menu

    this.getInfo();
  },
  methods: {
    changeLanguage(index){
      if(index == 1){
        this.lang = 'zh'
        localStorage.setItem('lang','zh')
        localStorage.setItem('Language',1)
        location.reload()
      }else if(index == 3){
        this.lang = 'tra'
        localStorage.setItem('lang','tra')
        localStorage.setItem('Language',3)
        location.reload()
      }else if(index == 2){
        this.lang = 'en'
        localStorage.setItem('lang','en')
        localStorage.setItem('Language',2)
        location.reload()
      }
    },
    windowScroll() {
     let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop);
      if(scrollTop > 100){
        this.isTop = false
        document.getElementById("div-gupiao_1").style.color="#333333"; 
           document.getElementById("imggonggao_1").style.display="none"; 
           document.getElementById("imggonggao1_1").style.display="block";
      }else{
        document.getElementById("div-gupiao_1").style.color="white";
        document.getElementById("imggonggao_1").style.display="block"; 
        document.getElementById("imggonggao1_1").style.display="none";
        this.isTop = true
       
      }
    },
    toSearch(){
      this.isSearch = !this.isSearch
    },
    toSearchPage(){
      this.$router.push({name: 'search',query: {content: this.input}})
      this.isSearch = false
      //location.reload();
    },
    toUrl(url,index){
      this.activeTab = url
      localStorage.setItem('activeTab', url)
      this.$router.push({name: url,query:{ menu: index }})
      if(url == 'news' || url == 'duty'){
          if(this.indextz==index){
            //location.reload();
         }else{
             this.indextz=index;
           }
      }
    },
    getList(){
      let that = this
      let params = {
        Language: localStorage.getItem('Language') || 1
      }
      that.$axios.post(this.baseURL + "api/Web_GroupSegment/GetPageDataV1",params).then(
        (res) => {
          that.list = res.data.rows
        },
        (error) => {
          console.log("请求失败了", error.message);
      });
    },

     getInfo(){
      let that = this
      axios({
          url: 'https://web.sqt.gtimg.cn/q=r_hk01448'
      }).then(res => {

          that.T1 = res.data.split('~')[3]
          that.T5 = res.data.split('~')[4]
          that.T4 = res.data.split('~')[5]

          that.T2 = res.data.split('~')[33]
          that.T3 = res.data.split('~')[34]
          //this.timeInfo = res.data.split('~')[30]


      })
    },
  },
  beforeDestroy() {
      window.removeEventListener('scroll', this.windowScroll)
  }
}
</script>

<style scoped lang="scss">
.menu-box-inner{
  background: url('../assets/img/mobile/head-menu-bg.png') no-repeat center center;
  background-size: 100% 100%;
}
.head-bg{
  background: #fff !important;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.05);
  .header-inner{
    .header-logo{
      display: none;
    }
        .header-tab{
            .tab-inner{
                .tab-inner-title{
                    color: #333333;
                }
                .activeTab{
                    //color: #D0B57E;
                }
                .tab-inner-title1{
                    color: #333333;
                }
                .tab-inner-title:hover{
                    //color: #D0B57E;
                    font-weight: bold;
                }
                .tab-search{
                    display: none;
                }
                .tab-search1{
                    display: block;
                }
            }
            .tab-inner:hover .tab-inner-title{
                //color: #D0B57E;
                font-weight: bold;
                border-bottom: 5px solid #D0B57E;
                position: relative;
            }
        }
    }
}
.isBlock{
    display: block !important;
}
// .isNone{
//   display: none !important;
// }
  .banner{
    width: 100%;
    height: 800px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/banner1.jpg') center center;
    background-size: 100% 100%;
    .header-p1{
      font-size: 72px;
      font-weight: 700;
      padding-top: 205px;
    }
    .header-p2{
      font-size: 32px;
      padding-top: 30px;
      .span1{
        font-size: 72px;
        font-weight: 700;
        color: rgba(47, 114, 235, 1);
      }
      .span2{
        font-size: 52px;
        font-weight: 700;
        color: rgba(47, 114, 235, 1);
      }
    }
    .header-p3{
      font-size: 32px;
      padding-top: 40px;
    }
    .header-p4{
      width: 170px;
      height: 60px;
      border-radius: 8px;
      background: rgba(47, 114, 235, 1);
      line-height: 60px;
      text-align: center;
      font-size: 28px;
      color: #fff;
      margin-top: 50px;
      cursor: pointer;
    }


  }
  .div-gupiao{
    position:absolute;
    z-index: 999;
    top: 5px;
    right: 175px;
    color: white;
  }
  .div-imggonggao{
   position:absolute;
    z-index: 999;
    top: 2px;
    right: 150px;
  }
.imggonggao,.imggonggao1{
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.imggonggao1{display:none}
</style>
