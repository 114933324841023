<template>
    <div>

    <div class="map-select">
            <el-select v-model="selecttype" filterable   @change="changeSelect" :placeholder="$t('home.home-92')" >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                >
              </el-option>
            </el-select>
     </div>

      <div class="mapbox" v-if="terminal == 'PC'">
        
        <div id="mapBox" class="map"></div>
        <div class="map-div" v-if="dialog">
          <div class="map-box">
            <div class="map-content-inner-top">
              <img class="map-close" @click="dialog = false" src="../../assets/img/close.png" alt="">
              <div class="map-text">
                <img class="map-text-img" :src="imgBaseUrl + detailInfo.ImgUrl" alt="">
                <div class="map-box-right">
                  <p class="map-p1">{{detailInfo.CemeteryName}}</p>
                  <p class="map-p2">{{$t("home.home-16")}}:{{detailInfo.Telephone}}</p>
                  <p class="map-p2">{{$t("home.home-17")}}:{{detailInfo.DetailedAddress}}</p>
                </div>
              </div>
            </div>
            <div class="map-content-inner-bottom">
              <div class="map-btn" @click="toDetail()" v-if="detailInfo.VRAddress">
                <p>{{$t("home.see-detail")}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>  

      <div class="mobile-mapbox" v-if="terminal == 'Mobile'">
        <div id="mobile-mapBox" class="mobile-map"></div>
        <div class="mobile-map-div" v-if="dialog">
          <div class="mobile-map-box">
            <div class="mobile-map-content-inner-top">
              <img class="mobile-map-close" @click="dialog = false" src="../../assets/img/close.png" alt="">
              <div class="mobile-map-text" style="display: flex">
                <img class="mobile-map-text-img" style="width: 80px;height: 60px;" :src="imgBaseUrl + detailInfo.ImgUrl" alt="">
                <div class="mobile-map-box-right">
                  <p class="mobile-map-p1">{{detailInfo.CemeteryName}}</p>
                  <p class="mobile-map-p2">{{$t("home.home-16")}}:{{detailInfo.Telephone}}</p>
                  <p class="mobile-map-p2">{{$t("home.home-17")}}:{{detailInfo.DetailedAddress}}</p>
                </div>
              </div>
            </div>
            <div class="mobile-map-content-inner-bottom">
              <div class="mobile-map-btn" @click="toDetail()" v-if="detailInfo.VRAddress">
                <p>{{$t("home.see-detail")}}</p>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
    
</template>
<script>
 import { InitializeMap } from "./baiduMap";
export default {
  components: {
  },
  data(){
    return{
       ProvinceList: [],
       CityList: [],
       FushouLandmarkList: [],
       baidumap:"",
       maptype:1,//1省份2城市3陵园
       dialog: false,
       detailInfo: {},
       imgBaseUrl: '',
       terminal: 'PC',
       typeList: [
     
      ],
      selecttype:""
    }
  },
  mounted(){
      this.terminal = localStorage.getItem('terminal')
      this.imgBaseUrl = localStorage.getItem('imgBaseUrl')
      let that=this;
      this.baidumap = InitializeMap(); 
      //省数据
      this.GetProvinceList();
      //市数据
      this.GetCityList();
      //陵园数据
      this.GetFushouLandmarkList();

      this.baidumap.addEventListener('zoomend', function (e) {
        let zoom=that.baidumap.getZoom();
        if(zoom<=6&&that.maptype!=1)
        {
             that.maptype=1;
             that.baidumap.clearOverlays();
             that.ShowProvinceList();
             //alert("省")
        }else if(zoom>6&&zoom<10&&that.maptype!=2){
              that.maptype=2;
              that.baidumap.clearOverlays();
              that.ShowCityList();   
              //alert("市")

        }else if(zoom>=10&&that.maptype!=3){
              that.maptype=3;
              that.baidumap.clearOverlays();
              that.ShowFushouLandmarkList();
              //alert("陵园");

        }

      })

  },
  methods:{

    changeSelect(e)
    {
        this.FushouLandmarkList.forEach(item => 
        {
          if(item.ID==e){
             this.baidumap.centerAndZoom(new BMap.Point(item.Longitude,item.Latitude),13);
          }
          
        })
        
    },
    toDetail(){
      if(this.detailInfo.VRAddress){
        // location.href = this.detailInfo.VRAddress
        window.open(this.detailInfo.VRAddress,'_blank');
      }else{
        localStorage.setItem('detailInfo',JSON.stringify(this.detailInfo))
        this.$router.push({name: 'mapDetail'})
      }
    },
     GetProvinceList(){
       let that = this
       let params = {
        Language: localStorage.getItem('Language') || 1,
         para: ''
       }
       that.$axios.post(this.baseURL + "api/Web_FuneralServices/GetPageDataV3",params).then(
         (res) => {
           that.ProvinceList = res.data.rows;
           that.ShowProvinceList();
         },
         (error) => {
           console.log("请求失败了", error.message);
       });
     },
     ShowProvinceList()
     {
         let that = this
         that.ProvinceList.forEach(item => 
        {
              // let myIcon = new BMap.Icon(
              //     require("../../assets/img/map-bg.png"),
              //      new BMap.Size(90, 30)
              // );
              let label = new BMap.Label(item.Name+" "+item.count, {
                  //offset: new BMap.Size(28, 5),
                  position:new BMap.Point(item.lng,item.lat)
               });
                label.setStyle({
                fontSize: "12px",
                 padding: "5px",
                 paddingLeft: "15px",
                 paddingRight: "15px",
                 background: "black",
                 border:"none",
                 color:"white",
                 borderRadius:"10px",
                 opacity: 0.5

               })
               //let marker= new BMap.Marker(new BMap.Point(item.lng,item.lat),{
               //     icon: myIcon
               //});
               //marker.setLabel(label);
              //  that.baidumap.addOverlay(marker);
              //  marker.addEventListener("click", function (event) {
              //       that.baidumap.centerAndZoom(new BMap.Point(item.lng,item.lat),9);
              //  });

              that.baidumap.addOverlay(label);
              label.addEventListener("click", function (event) {
                    that.baidumap.centerAndZoom(new BMap.Point(item.lng,item.lat),7);
              });
        });
     },
     GetCityList(){
       let that = this
       let params = {
        Language: localStorage.getItem('Language') || 1,
         para: ''
       }
       that.$axios.post(this.baseURL + "api/Web_FuneralServices/GetPageDataV4",params).then(
         (res) => {
            that.CityList = res.data.rows;
         },
         (error) => {
           console.log("请求失败了", error.message);
       });
     },
     ShowCityList()
     {
         let that = this
         that.CityList.forEach(item => 
         {
            // let myIcon = new BMap.Icon(
            //       require("../../assets/img/map-bg.png"),
            //        new BMap.Size(90, 30)
            //   );
              let label = new BMap.Label(item.Name+" "+item.count, {
                  //offset: new BMap.Size(28, 5)
                  position:new BMap.Point(item.lng,item.lat)
               });
               label.setStyle({
                fontSize: "12px",
                 padding: "5px",
                 paddingLeft: "15px",
                 paddingRight: "15px",
                 background: "black",
                 border:"none",
                 color:"white",
                 borderRadius:"10px",
                 opacity: 0.5

               })
        //        let marker= new BMap.Marker(new BMap.Point(item.lng,item.lat),{
        //             icon: myIcon
        //        });
        //        marker.setLabel(label);
        //        that.baidumap.addOverlay(marker);
        //         marker.addEventListener("click", function (event) {
        //                  that.baidumap.centerAndZoom(new BMap.Point(item.lng,item.lat),12);
        //        });

               that.baidumap.addOverlay(label);
               label.addEventListener("click", function (event) {
                    that.baidumap.centerAndZoom(new BMap.Point(item.lng,item.lat),10);
              });
         }); 
     },
     GetFushouLandmarkList(){
       let that = this
       let params = {
        Language: localStorage.getItem('Language') || 1,
         para: ''
       }
       that.$axios.post(this.baseURL + "api/Web_FuneralServices/GetPageDataV1",params).then(
         (res) => {
           that.FushouLandmarkList = res.data.rows; 
          that.FushouLandmarkList.forEach(item => 
          {
          that.typeList.push({value:item.ID,label:item.CemeteryName})
          })
         },
         (error) => {
           console.log("请求失败了", error.message);
       });
     },
     ShowFushouLandmarkList()
     {
         let that = this
         that.FushouLandmarkList.forEach(item => 
         {
            // let myIcon = new BMap.Icon(
            //       require("../../assets/img/map-icon.png"),
            //        new BMap.Size(30, 36)
            //   );
              let label = new BMap.Label(item.CemeteryName, {
                  //offset: new BMap.Size(-20, 36)
                  position:new BMap.Point(item.Longitude,item.Latitude)
               });
               label.setStyle({
                fontSize: "12px",
                 padding: "8px",
                 paddingLeft: "20px",
                 paddingRight: "20px",
                 background: "#E59511",
                 border:"none",
                 color:"white",
                 borderRadius:"5px",

               })
               //let marker= new BMap.Marker(new BMap.Point(item.Longitude,item.Latitude),{
                //    icon: myIcon
             //});
              // marker.setLabel(label);
              // that.baidumap.addOverlay(marker);
              // marker.addEventListener("click", function (event) 
              // {
              //    that.dialog = true
              //     that.detailInfo = item
              // }); 

               that.baidumap.addOverlay(label);
               label.addEventListener("click", function (event) 
               {
                  that.dialog = true
                   that.detailInfo = item
               });  

               
           

         }); 
     },
    
  }
}
</script>
<style scoped lang="scss">
.mapbox {
  width: 100%;
  height: 700px;
}
.map {
  width: 100%;
  height: 700px;
}
.map-select{
   position:absolute;
   top: 72px;
   left: 30px;
   z-index: 9999;
   widows: 150px;
}
</style>



